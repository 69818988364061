<div class="container my-5">
	<ob-breadcrumb pageName="DOTAZNÍK SPOKOJENOSTI"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase mb-3">Zhodnoťte prosím průběh přijímacího řízení do {{company}}</h1>
	<p class="h6-responsive mb-5">(5 *– maximální spokojenost, 1 * - minimální spokojenost)</p>
	<h1 *ngIf="isFilled" class="h3-responsive montserat-black text-success mt-5 mb-10">Děkujeme za vyplnění!</h1>
	<form *ngIf="!isFilled"  (ngSubmit)="onSubmit(questionaryForm)" #questionaryForm="ngForm" >
		<div class="bg-white box-shadow p-5">
			<h3 class="h5-responsive mb-3"><b>1) Rychlost a kvalita komunikace během výběrového řízení</b></h3>
			<div class="form-group">
				<rating [(ngModel)]="model.speedOfTender"
						[max]="max"
						[readonly]="isReadonly"
    				(onHover)="hoveringOver($event)"
    				(onLeave)="resetStar()"
    				name="speedOfTender"
    				class="bs-rating-stars-cont"></rating>
			</div>
			<h3 class="h5-responsive mb-3"><b>2) Profesionalita lidí, kteří vedli výběrové řízení</b></h3>
			<div class="form-group">
				<rating [(ngModel)]="model.attitude"
						[max]="max"
						[readonly]="isReadonly"
					(onHover)="hoveringOver($event)"
					(onLeave)="resetStar()"
					name="attitude"
					class="bs-rating-stars-cont"></rating>
			</div>
			<h3 class="h5-responsive mb-3"><b>3) Dostatek informací získaných při přijímacím pohovoru</b></h3>
			<div class="form-group">
				<rating [(ngModel)]="model.enoughInformation"
						[max]="max"
						[readonly]="isReadonly"
					(onHover)="hoveringOver($event)"
					(onLeave)="resetStar()"
					name="enoughInformation"
					class="bs-rating-stars-cont"></rating>
			</div>
			<h3 class="h5-responsive mb-3"><b>4) Celkový průběh přijímacího řízení</b></h3>
			<div class="form-group">
				<rating [(ngModel)]="model.overall"
						[max]="max"
						[readonly]="isReadonly"
					(onHover)="hoveringOver($event)"
					(onLeave)="resetStar()"
					name="overall"
					class="bs-rating-stars-cont"></rating>
			</div>
			<h3 class="h5-responsive mb-3"><b>Chcete nám na závěr něco doporučit nebo vzkázat?</b></h3>
			<div class="form-group">
				<textarea name="notes" id="notes" cols="30" rows="4" class="form-control" [(ngModel)]="model.notes"></textarea>
			</div>
			<h3 class="h5-responsive mb-3">Děkujeme za vaše odpovědi</h3>
		</div>
		<div class="my-5">
			<button type="submit" class="btn btn-primary">Odeslat</button>
		</div>
	</form>
</div>
