import { Component } from '@angular/core';

import { UserService } from '../../user/user.service';
import { QuestionaryService } from '../../shared/questionary.service';
import { QuestionaireType } from '../../shared/questionary.model';

@Component({
  selector: 'ob-your-documents',
  templateUrl: 'your-documents.component.html',
})
export class YourDocumentsComponent {

  isQuestionaryFilled: boolean = false;
  pageModel: number[] = [];

  constructor(
    protected userService: UserService,
    protected questionaryService: QuestionaryService
  ) {};

  ngOnInit() {
    let point = 1;
    this.pageModel['questionaire'] = point++;
    if(this.userService.isShowSalaryOptiomalization()) {
      this.pageModel['cafeterie'] = point++;
    }
    if(this.isRequired('personalPhoto')) {
      this.pageModel['personalPhoto'] = point++;
    }
    if(this.isRequired('diploma')) {
      this.pageModel['diploma'] = point++;
    }
    if(this.isRequired('medicalExamination') || this.isRequired('medicalExaminationPayment')) {
      this.pageModel['medical'] = point++;
    }
    if(this.isRequired('criminalRecord')) {
      this.pageModel['criminalRecord'] = point++;
    }
    if(this.isRequired('certificate')) {
      this.pageModel['certificate'] = point++;
    }
    if(this.isRequired('pension')) {
      this.pageModel['pension'] = point++;
    }
    if(this.isRequired('disadvantage')) {
      this.pageModel['disadvantage'] = point++;
    }
    if(this.isRequired('oDoc4')) {
      this.pageModel['oDoc4'] = point++;
    }
    if(this.isRequired('oDoc5')) {
      this.pageModel['oDoc5'] = point++;
    }
    if(this.isRequired('permitRecord')) {
      this.pageModel['permitRecord'] = point++;
    }
    if(this.isRequired('partTime')) {
      this.pageModel['partTime'] = point++;
    }

    this.isQuestionaryFilled = this.isPersonalQuestionaireFilled();
    const uid = this.userService.getUid();
  }

  isShown(docType: string): boolean {
    return docType in this.pageModel;
  }

  getPoint(docType: string): number {
    return this.pageModel[docType];
  }


  isRequired(docType: string): boolean {
    return this.userService.isRequired(docType);
  }

  isUploaded(docType: string): boolean {
    return this.userService.isUploaded(docType);
  }

  isPersonalQuestionaireFilled(): boolean | undefined {
    return this.userService.isFilled(QuestionaireType.PERSONAL);
  }

  isSetCafeterie(): boolean {
    let amount = this.userService.getUserStorage().userDetail.amount;
    return amount != undefined && amount != null;
  }

  getDescription(docType: string):string{
    return this.userService.getFileDescription(docType);
  }
}
