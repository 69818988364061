import { Component } from '@angular/core';
import { Content } from '../../shared/content.model';
import { ContentService } from '../../shared/content.service';

@Component({
  selector: 'ob-benefits',
  templateUrl: 'benefits.component.html',
})
export class BenefitsComponent {
  activeTab: string;
  
  content_begin: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_end: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_onStart: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_after3: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_afterYear: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};

  content_begin_uuid: string = "3f8bcbcb-239a-48a6-bef0-47deff37a0ae";
  content_end_uuid: string = "c332c315-9314-4979-afb3-aab7d71c022d";
  content_onStart_uuid: string = "c8849d3b-3445-4143-ae1d-6f8e24f0893c";
  content_after3_uuid: string = "2a472fb7-f2d3-4595-81bc-e44224332a48";
  content_afterYear_uuid: string = "80985b04-87e8-49ef-86b0-14688c514ad2";

  constructor(private contentService: ContentService){}
  ngOnInit() {
    this.contentService.getContent(this.content_begin_uuid).subscribe(res => {
      this.content_begin = res;    
    });
    this.contentService.getContent(this.content_onStart_uuid).subscribe(res => {
      this.content_onStart = res;    
    });
    this.contentService.getContent(this.content_after3_uuid).subscribe(res => {
      this.content_after3 = res;    
    });
    this.contentService.getContent(this.content_afterYear_uuid).subscribe(res => {
      this.content_afterYear = res;    
    });
    this.contentService.getContent(this.content_end_uuid).subscribe(res => {
      this.content_end = res;    
    });
  }

  changeActiveTab(tab: string) {
  	if(this.activeTab == tab) {
  		this.activeTab = '';
  	} else {
  		this.activeTab = tab;
  	}
  }
}
