import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ob-navbar-dropdown-header',
  templateUrl: 'navbar-dropdown-header.component.html',
})
export class NavbarDropdownHeaderComponent {
	@Output() closeDropdown = new EventEmitter;

	emitClose(event?:any) {
		event.preventDefault();
		this.closeDropdown.emit(event);
	}
}
