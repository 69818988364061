<nav class="navbar bg-black navbar-dark navbar-text-to-icon">
	<a class="navbar-brand" routerLink="/">
		<img class="icon icon--logo" id="logo" src="../../assets/images/KB-Group - white.png" alt=""/>
	</a>
	<div class="logo-motto logo-motto--navbar">Portál pro budoucí zaměstnance</div>
	<ul class="navbar-nav mr-auto">
		<li class="nav-item" [class.active]="isMenuSelected(menuItemKeys.menu)">
			<a class="nav-link" href="." [class.active]="isMenuSelected(menuItemKeys.menu)" (click)="selectMenu(menuItemKeys.menu, $event)">
				<!-- exchange-row -->
				<svg class="icon icon--white icon--xl" xmlns="http://www.w3.org/2000/svg" width="20.66" height="13.41" viewBox="0 0 20.656 13.406"><path d="M20.65 13.4V9.78H5.17v1.2h14.18v1.24H5.17v1.2h15.48zM5.17 8.54h15.48V4.86H5.17V6.1h14.18v1.2H5.17v1.23zm0-4.87h15.48V0H5.17v1.2h14.18V2.4H5.17v1.24zM3.3 12.9a1.7 1.7 0 0 0 .57-1.3 1.73 1.73 0 0 0-.56-1.3 2 2 0 0 0-2.73 0A1.73 1.73 0 0 0 0 11.6a1.7 1.7 0 0 0 .57 1.3 1.96 1.96 0 0 0 1.38.5 1.93 1.93 0 0 0 1.36-.5zm-.9-1.74a.6.6 0 0 1 .18.44.57.57 0 0 1-.2.43.67.67 0 0 1-.9 0 .57.57 0 0 1-.18-.43.6.6 0 0 1 .2-.44.63.63 0 0 1 .45-.2.6.6 0 0 1 .44.2zM3.3 8a1.76 1.76 0 0 0 0-2.6 2 2 0 0 0-2.73 0 1.76 1.76 0 0 0 0 2.6 1.93 1.93 0 0 0 1.38.53A1.9 1.9 0 0 0 3.3 8zm-.9-1.72a.6.6 0 0 1 0 .85.67.67 0 0 1-.9 0 .6.6 0 0 1 0-.85.65.65 0 0 1 .45-.18.62.62 0 0 1 .44.17zm.9-3.16a1.76 1.76 0 0 0 0-2.6 2 2 0 0 0-2.73 0 1.76 1.76 0 0 0 0 2.6 1.93 1.93 0 0 0 1.38.54 1.9 1.9 0 0 0 1.36-.54zM2.4 1.4a.6.6 0 0 1 .18.43.57.57 0 0 1-.2.42.67.67 0 0 1-.9 0 .57.57 0 0 1-.18-.42.6.6 0 0 1 .2-.44.63.63 0 0 1 .45-.2.6.6 0 0 1 .44.18z"/></svg>
				<span class="nav-link__caption">Menu</span>
			</a>
			<ob-navbar-dropdown *ngIf="isMenuSelected(menuItemKeys.menu)" (clickedOutside)="closeMenu()">
				<ob-navbar-dropdown-header (closeDropdown)="closeMenu()">Menu</ob-navbar-dropdown-header>
				<div *ngFor="let menu of menuBlock">
					<span class="navbar-dropdown__title montserat-black u-text--red uppercase">{{menu.name}}</span>	
					<ul *ngIf="menu.submenu" class="navbar-dropdown__links">
						<li *ngFor="let item of menu.submenu" class="navbar-dropdown__item"><a (click)="setActiveMenuItem(item)" class="navbar-dropdown__link" routerLink="{{item.url}}" routerLinkActive="active">{{item.name}}</a></li>	
					</ul>
				</div>
			</ob-navbar-dropdown>
		</li>
	</ul>
	<ul class="navbar-nav ml-auto">
		<li class="nav-item" [class.active]="isMenuSelected(menuItemKeys.communication)">
			<a class="nav-link" href="#" [class.active]="isMenuSelected(menuItemKeys.communication)" (click)="selectMenu(menuItemKeys.communication, $event)">
				<svg class="icon icon--white icon--xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M93.2 31.6H44.3a7 7 0 0 0-6.8 7.3v19.3l-14.8 9.5v-9.5h-15a.8.8 0 0 1-.3-.7V9.6a1 1 0 0 1 .3-.7H69a.8.8 0 0 1 .3.7v15h7.4v-15a7.9 7.9 0 0 0-7.7-8H7.6a7.9 7.9 0 0 0-7.6 8v48a7.9 7.9 0 0 0 7.6 8h7.7v15.6L37.5 67v11a7 7 0 0 0 6.8 7.2h22.9l20.6 13.3V85.2h5.4A7 7 0 0 0 100 78V39a7 7 0 0 0-6.8-7.3zm-.6 46.2H80.4V85l-11-7H44.8V39h47.7z"/></svg>
				<span class="nav-link__caption">Komunikace</span>
			</a>
			<ob-navbar-dropdown position="right" size="lg" *ngIf="isMenuSelected(menuItemKeys.communication)" (clickedOutside)="closeMenu()">
				<ob-navbar-dropdown-header (closeDropdown)="closeMenu()">Zavřít</ob-navbar-dropdown-header>
				<ob-communication (onAfterSend)="closeMenu()"></ob-communication>
			</ob-navbar-dropdown>
		</li>
		<li class="nav-item" [class.active]="isMenuSelected(menuItemKeys.user)">
			<a href="#" class="nav-link" [class.active]="isMenuSelected(menuItemKeys.user)" (click)="selectMenu(menuItemKeys.user, $event)">
				<!-- user -->
				<svg class="icon icon--white icon--xl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M89.7 70.1l-20-18a29.7 29.7 0 1 0-6.8 4.4l19.8 17.8 4.9 17.9H12.5l4.8-16.3 10.2-9.5-5.3-5.7-11.8 11L2.1 100h95.8l-8.2-29.9zM28.1 29.8a22 22 0 1 1 44 0 22 22 0 0 1-44 0z"/></svg>
				<span class="nav-link__caption">Profil ({{ userName }})</span>
			</a>
			<ob-navbar-dropdown position="right" *ngIf="isMenuSelected(menuItemKeys.user)" (clickedOutside)="closeMenu()">
				<ul class="navbar-dropdown__links">
					<li class="navbar-dropdown__item" ><a href="." (click)="logout($event)" class="navbar-dropdown__link">Odhlásit</a></li>	
				</ul>
			</ob-navbar-dropdown>
		</li>
	</ul>
</nav>