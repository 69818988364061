<div class="navbar-dropdown__header">
	<div class="row">
		<div class="col-6 order-2 text-right">
			<a href="." class="navbar-dropdown__close" (click)="emitClose($event)">
				<span class="navbar-dropdown__close__text">Zavřít</span>
				<!-- multiply -->
				<svg class="icon icon--grey-dark icon--baseline icon--lg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.526 32.526"><path d="M32.53 2.83L29.7 0 16.26 13.44 2.83 0 0 2.83l13.44 13.43L0 29.7l2.83 2.83L16.26 19.1 29.7 32.52l2.83-2.83L19.1 16.26z"/></svg>
			</a>
		</div>
		<div class="col-6 order-1">
			<span class="u-text--red bold">Menu</span>
		</div>
	</div>
</div>