<div class="login">
	<div class="login__form p-3 login__form--error">
		<div class="mb-4 d-flex align-items-center border-bottom pb-3">
			<svg class="icon icon--logo" id="logo-kb" viewBox="0 0 130.065 48" width="100%" height="100%"><path fill="#fff" d="M0 0h48v48H0z"></path><path d="M71.76 22.4l14.256-8.516c1.264-.767.546-1.558-.318-1.558v-.335H95.87v.336a13.217 13.217 0 0 0-4.76 1.973l-12.83 7.544L93.22 33.78c1.128.862 2.108 1.746 3.38 1.89v.34H86.388L71.76 23.867v9.35a2.18 2.18 0 0 0 2.352 2.45v.34H63.558v-.338c1.5 0 1.883-.885 1.883-2.45V14.777c0-1.565-.38-2.452-1.882-2.452v-.335H74.11v.336a2.18 2.18 0 0 0-2.35 2.452V22.4zm58.305 6.756c0 4.147-3.74 6.8-12.727 6.85L99.98 36v-.33a2.18 2.18 0 0 0 2.354-2.45V14.777a2.182 2.182 0 0 0-2.354-2.452v-.335h18.395c7.6 0 10.282 2.565 10.282 5.544 0 4.022-4.11 5.39-7.924 5.76 4.017 0 9.332 1.37 9.332 5.863zm-8.24-10.856c0-3.3-3.722-3.73-7.536-3.7a48.86 48.86 0 0 0-5.64.314V22s2.257.1 3.764.1c7.586-.053 9.41-1.058 9.41-3.8zm-5.962 14.973c3.25 0 7.268-.528 7.268-4.188 0-5.032-8.27-4.5-14.48-4.118v6.413a2.263 2.263 0 0 0 2.29 1.823c1.453.065 4.923.072 4.923.072z" fill-rule="evenodd"></path><path fill="#c03" d="M1 1h46v23H1z"></path><path fill="#001" d="M1 24h46v23H1z"></path><path fill="#fff" d="M11 24h26v2H11z"></path></svg>
			<div class="logo-motto ml-3">Portál pro budoucí zaměstnance</div>
		</div>
		<h1 class="h6-responsive montserat-black">První přihlášení</h1>
		<form (keydown)="keyDownFunction($event)">
			<div class="form-group">
				<label for="">Váš E-mail</label>
				<input type="text" class="form-control" [formControl]="email">
			</div>
		</form>
		<div *ngIf="!requestSuccess" class="my-3">
			<p class="text-danger">Váš požadavek o přihlášení se nezdařilo zpracovat, zkuste to znovu nebo kontaktujte svého recruitera z HR oddělení.</p>
		</div>
		<button (click)="onSubmit()" [disabled]="!email.valid" class="btn btn-primary">Přihlásit</button>
	</div>
</div>
