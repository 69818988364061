import { NgModule } from '@angular/core';
import { BreadcrumbComponent } from './breadcrumb.component';

import { HistoryService } from '../../core/history/history.service';

import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
  	RouterModule,
  ],
  declarations: [
    BreadcrumbComponent,
  ],
  exports: [
    BreadcrumbComponent,
  ],
  providers: [
  	HistoryService
  ]
})
export class BreadcrumbModule { }
