<div class="container my-5">
	<ob-breadcrumb pageName="Dokumenty k nástupu"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase">CO BUDETE PODEPISOVAT A ČÍM SE ŘÍDÍME</h1>
	<div class="row">
		<div class="col-md-6">
			<p class="h6-responsive my-5"><b>Následující vzorové dokumenty si můžete v klidu přečíst dopředu.</b> <br />
				Některé z nich budete podepisovat na schůzce s manažerem, některé Váš podpis nevyžadují.<br />
				Uvedené dokumenty jsou standardní pro všechny zaměstnance, nenajdete v nich specifická ujednání, pokud
				máte taková dohodnutá. <br />
				<b> Pokud Vám něco nebude jasné, neváhejte se zeptat.</b>
			</p>
			<div class="row mt-3 d-none d-lg-flex">
				<!-- NOTICE: there is duplicate buttons lower on this page because of responsive behaviour -->
				<div class="col-lg-6">
					<button class="btn btn-block btn-outline-secondary" (click)="downloadAll()">
						<!-- download -->
						<svg class="icon icon--grey-dark icon--xl mr-2" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 100 100">
							<path
								d="M89.7 78.6v14.2H10.3V78.6H3V100h94V78.6h-7.3zm-8.8-.1h-63v7.3h63v-7.3zm-14-32.3l-6-4.2-7.7 11V0h-7.3v53.1l-7.2-11.5-6.2 3.9 16.3 26.2 18.1-25.5z" />
						</svg>
						<span class="btn__text">Stáhnout vše</span>
					</button>
				</div>
				<div class="col-lg-6 mt-3 mt-lg-0">
					<button class="btn btn-block btn-outline-secondary" (click)="openModal(sendMessageModal)">
						<!-- tlumocnik -->
						<svg class="icon icon--grey-dark icon--xl mr-2" xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 100 100">
							<path
								d="M93.2 31.6H44.3a7 7 0 0 0-6.8 7.3v19.3l-14.8 9.5v-9.5h-15a.8.8 0 0 1-.3-.7V9.6a1 1 0 0 1 .3-.7H69a.8.8 0 0 1 .3.7v15h7.4v-15a7.9 7.9 0 0 0-7.7-8H7.6a7.9 7.9 0 0 0-7.6 8v48a7.9 7.9 0 0 0 7.6 8h7.7v15.6L37.5 67v11a7 7 0 0 0 6.8 7.2h22.9l20.6 13.3V85.2h5.4A7 7 0 0 0 100 78V39a7 7 0 0 0-6.8-7.3zm-.6 46.2H80.4V85l-11-7H44.8V39h47.7z" />
						</svg>
						<span class="btn__text">Poslat dotaz</span>
					</button>

					<ng-template #sendMessageModal>
						<div class="navbar-dropdown__content box-shadow--lg navbar-dropdown__content--lg">
							<div class="navbar-dropdown__header text-right">
								<button type="button" class="border-0 bg-transparent navbar-dropdown__close"
									(click)="closeModal()">
									<span class="navbar-dropdown__close__text bold">Zavřít</span>
									<!-- multiply -->
									<svg class="icon icon--grey-dark icon--baseline icon--lg"
										xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.526 32.526">
										<path
											d="M32.53 2.83L29.7 0 16.26 13.44 2.83 0 0 2.83l13.44 13.43L0 29.7l2.83 2.83L16.26 19.1 29.7 32.52l2.83-2.83L19.1 16.26z" />
									</svg>
								</button>
							</div>
							<div class="modal-body">
								<ob-communication-message [wrapperClasses]="" [selectedPerson]="recruiter"
									(sent)="closeModal()" (cancel)="closeModal()"></ob-communication-message>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</div>
		<div class="col-md-6">
			<div class="mt-md-5">
				<app-contact-center></app-contact-center>
			</div>
		</div>
	</div>
	<div class="row mt-5 d-lg-none mb-5 mb-lg-0">
		<div class="col-md-6">
			<button class="btn btn-block btn-outline-secondary" (click)="downloadAll()" *ngIf="company == 'KB'">
				<!-- download -->
				<svg class="icon icon--grey-dark icon--xl mr-2" xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100">
					<path
						d="M89.7 78.6v14.2H10.3V78.6H3V100h94V78.6h-7.3zm-8.8-.1h-63v7.3h63v-7.3zm-14-32.3l-6-4.2-7.7 11V0h-7.3v53.1l-7.2-11.5-6.2 3.9 16.3 26.2 18.1-25.5z" />
				</svg>
				<span class="btn__text">Stáhnout vše</span>
			</button>
		</div>
		<div class="col-md-6 mt-3 mt-md-0">
			<button class="btn btn-block btn-outline-secondary" (click)="openModal(sendMessageModal)">
				<!-- tlumocnik -->
				<svg class="icon icon--grey-dark icon--xl mr-2" xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 100 100">
					<path
						d="M93.2 31.6H44.3a7 7 0 0 0-6.8 7.3v19.3l-14.8 9.5v-9.5h-15a.8.8 0 0 1-.3-.7V9.6a1 1 0 0 1 .3-.7H69a.8.8 0 0 1 .3.7v15h7.4v-15a7.9 7.9 0 0 0-7.7-8H7.6a7.9 7.9 0 0 0-7.6 8v48a7.9 7.9 0 0 0 7.6 8h7.7v15.6L37.5 67v11a7 7 0 0 0 6.8 7.2h22.9l20.6 13.3V85.2h5.4A7 7 0 0 0 100 78V39a7 7 0 0 0-6.8-7.3zm-.6 46.2H80.4V85l-11-7H44.8V39h47.7z" />
				</svg>
				<span class="btn__text">Poslat dotaz</span>
			</button>
		</div>
	</div>
	<ng-template [ngIf]="company == 'KB'  ||  company == null">
		<div class="row mt-5">
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('covid')">
				<app-file-download-link [caption]="'DŮLEŽITÉ INFORMACE PRO NOVÁČKY'" [docType]="'covid'"
					[linkClass]="'link-box link-box--white-red2'">
					<svg class="icon icon--xxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
						<path
							d="M57.15 66.13C57.15 65.12 56.36 64.28 55.36 64.28C54.29 64.28 45.72 64.28 44.64 64.28C43.64 64.28 42.86 65.12 42.86 66.13C42.86 67.19 42.86 75.67 42.86 76.73C42.86 77.73 43.64 78.57 44.64 78.57C45.72 78.57 54.29 78.57 55.36 78.57C56.36 78.57 57.15 77.74 57.15 76.73C57.15 74.61 57.15 67.19 57.15 66.13ZM58.04 30.25C58.04 29.91 57.87 29.47 57.48 29.19C57.15 28.91 56.65 28.57 56.14 28.57C54.91 28.57 45.09 28.57 43.87 28.57C43.36 28.57 42.86 28.91 42.53 29.19C42.14 29.47 41.97 30.02 41.97 30.36C42.06 32.91 42.82 53.31 42.92 55.86C42.92 56.59 43.75 57.14 44.82 57.14C45.85 57.14 54.11 57.14 55.14 57.14C56.15 57.14 56.98 56.59 57.04 55.86C57.04 55.86 57.94 32.81 58.04 30.25ZM99.11 82.31C100.34 84.49 100.29 87.17 99 89.35C97.72 91.52 95.37 92.86 92.86 92.86C84.29 92.86 15.71 92.86 7.14 92.86C4.63 92.86 2.29 91.52 1 89.35C-0.29 87.17 -0.34 84.49 0.89 82.31C5.18 74.46 39.47 11.6 43.75 3.74C44.98 1.45 47.38 0 50 0C52.62 0 55.02 1.45 56.25 3.74C56.25 3.74 94.83 74.46 99.11 82.31Z">
						</path>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contracts')">
				<app-file-download-link [caption]="'PRACOVNÍ SMLOUVA'" [docType]="'contracts'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('wage')">
				<app-file-download-link [caption]="'MZDOVÝ VÝMĚR'" [docType]="'wage'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('codex')">
				<app-file-download-link [caption]="'ETICKÝ KODEX'" [docType]="'codex'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('informationSecurity')">
				<app-file-download-link [caption]="'INFORMAČNÍ BEZPEČNOST'" [docType]="'informationSecurity'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M87.4 65.9V13.5H9.7v52.4H0l.2 3.4c.8 13.2 12.7 17 19 17.2.5 0 48.7-.3 63 0h.5c5 0 9-1.5 12-4.5 5.2-5 5.3-12.6 5.3-12.9V66zM16.2 20h64.7v45.9H26.7V31.3H70v26.5h6.6v-33H20.2v41.1h-4zm74 57.4c-1.9 1.8-4.5 2.6-7.9 2.6l-22.7-.1H19.3c-7.6-.2-10.6-4.4-11.8-7.5H93a11 11 0 0 1-2.8 5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('affidavit')">
				<app-file-download-link [caption]="'OSOBA BLÍZKÁ'" [docType]="'affidavit'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('credibility')">
				<app-file-download-link [caption]="'DŮVĚRYHODNOST'" [docType]="'credibility'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('liability')">
				<app-file-download-link [caption]="'HMOTNÁ ODPOVĚDNOST'" [docType]="'liability'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M67.4 17.7H21.3v6.8h46.2v-6.8zM21.3 43.9h23.4V37H21.3v6.9zM95.1 100l-.2-40.6-62.7-.2v26.3H11.7V6.8h65.7v46.3h6.8V0H4.9v92.3h27.3v7.7h62.9zM39 66l49.1.2.1 27H39V66zm25.6 4.4c-5.2 0-9.4 4.2-9.4 9.4s4.2 9.4 9.4 9.4S74 85 74 79.8c-.1-5.2-4.3-9.3-9.4-9.4zm0 14.2c-2.7 0-4.8-2.1-4.8-4.8 0-2.7 2.1-4.8 4.8-4.8 2.7 0 4.8 2.1 4.8 4.8 0 2.6-2.1 4.8-4.8 4.8zM45.3 70.1c-1.1 0-1.9.9-1.9 1.9s.9 1.9 1.9 1.9c1.1 0 1.9-.9 1.9-1.9 0-1.1-.9-1.9-1.9-1.9zm0 15.5c-1.1 0-1.9.9-1.9 1.9s.9 1.9 1.9 1.9c1.1 0 1.9-.9 1.9-1.9 0-1.1-.9-1.9-1.9-1.9zM83 70.1c-1.1 0-1.9.8-1.9 1.9 0 1.1.8 1.9 1.9 1.9 1.1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9zm0 15.5c-1.1 0-1.9.9-1.9 1.9 0 1.1.9 1.9 1.9 1.9 1.1 0 1.9-.9 1.9-1.9 0-1.1-.8-1.9-1.9-1.9z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contractsDPP')">
				<app-file-download-link [caption]="'DOHODA O PROVEDENÍ PRÁCE'" [docType]="'contractsDPP'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contractsDPC')">
				<app-file-download-link [caption]="'DOHODA O PRACOVNÍ ČINNOSTI'" [docType]="'contractsDPC'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('memorandum')">
				<app-file-download-link [caption]="'INFORMAČNÍ MEMORANDUM'" [docType]="'memorandum'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc1')">
				<app-file-download-link [caption]="getTitle('oDoc1')" [docType]="'oDoc1'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc2')">
				<app-file-download-link [caption]="getTitle('oDoc2')" [docType]="'oDoc2'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc3')">
				<app-file-download-link [caption]="getTitle('oDoc3')" [docType]="'oDoc3'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc4')">
				<app-file-download-link [caption]="getTitle('oDoc4')" [docType]="'oDoc4'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc5')">
				<app-file-download-link [caption]="getTitle('oDoc5')" [docType]="'oDoc5'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
		</div>
	</ng-template>
	<ng-template [ngIf]="company == 'MPSS'">
		<div class="row mt-5">
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('covid')">
				<app-file-download-link [caption]="'DŮLEŽITÉ INFORMACE PRO NOVÁČKY'" [docType]="'covid'"
					[linkClass]="'link-box link-box--white-red2'">
					<svg class="icon icon--xxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
						<path
							d="M57.15 66.13C57.15 65.12 56.36 64.28 55.36 64.28C54.29 64.28 45.72 64.28 44.64 64.28C43.64 64.28 42.86 65.12 42.86 66.13C42.86 67.19 42.86 75.67 42.86 76.73C42.86 77.73 43.64 78.57 44.64 78.57C45.72 78.57 54.29 78.57 55.36 78.57C56.36 78.57 57.15 77.74 57.15 76.73C57.15 74.61 57.15 67.19 57.15 66.13ZM58.04 30.25C58.04 29.91 57.87 29.47 57.48 29.19C57.15 28.91 56.65 28.57 56.14 28.57C54.91 28.57 45.09 28.57 43.87 28.57C43.36 28.57 42.86 28.91 42.53 29.19C42.14 29.47 41.97 30.02 41.97 30.36C42.06 32.91 42.82 53.31 42.92 55.86C42.92 56.59 43.75 57.14 44.82 57.14C45.85 57.14 54.11 57.14 55.14 57.14C56.15 57.14 56.98 56.59 57.04 55.86C57.04 55.86 57.94 32.81 58.04 30.25ZM99.11 82.31C100.34 84.49 100.29 87.17 99 89.35C97.72 91.52 95.37 92.86 92.86 92.86C84.29 92.86 15.71 92.86 7.14 92.86C4.63 92.86 2.29 91.52 1 89.35C-0.29 87.17 -0.34 84.49 0.89 82.31C5.18 74.46 39.47 11.6 43.75 3.74C44.98 1.45 47.38 0 50 0C52.62 0 55.02 1.45 56.25 3.74C56.25 3.74 94.83 74.46 99.11 82.31Z">
						</path>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contracts_MPSS')">
				<app-file-download-link [caption]="'PRACOVNÍ SMLOUVA'" [docType]="'contracts_MPSS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('wage_MPSS')">
				<app-file-download-link [caption]="'MZDOVÝ VÝMĚR'" [docType]="'wage_MPSS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('codex_MPSS')">
				<app-file-download-link [caption]="'ETICKÝ KODEX'" [docType]="'codex_mpss'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('informationSecurity_MPSS')">
				<app-file-download-link [caption]="'INFORMAČNÍ BEZPEČNOST'" [docType]="'informationSecurity_MPSS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M87.4 65.9V13.5H9.7v52.4H0l.2 3.4c.8 13.2 12.7 17 19 17.2.5 0 48.7-.3 63 0h.5c5 0 9-1.5 12-4.5 5.2-5 5.3-12.6 5.3-12.9V66zM16.2 20h64.7v45.9H26.7V31.3H70v26.5h6.6v-33H20.2v41.1h-4zm74 57.4c-1.9 1.8-4.5 2.6-7.9 2.6l-22.7-.1H19.3c-7.6-.2-10.6-4.4-11.8-7.5H93a11 11 0 0 1-2.8 5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('affidavit_MPSS')">
				<app-file-download-link [caption]="'OSOBA BLÍZKÁ'" [docType]="'affidavit_MPSS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('credibility_MPSS')">
				<app-file-download-link [caption]="'DŮVĚRYHODNOST'" [docType]="'credibility_MPSS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('memorandum_MPSS')">
				<app-file-download-link [caption]="'INFORMAČNÍ MEMORANDUM'" [docType]="'memorandum_MPSS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc1')">
				<app-file-download-link [caption]="getTitle('oDoc1')" [docType]="'oDoc1'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc2')">
				<app-file-download-link [caption]="getTitle('oDoc2')" [docType]="'oDoc2'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc3')">
				<app-file-download-link [caption]="getTitle('oDoc3')" [docType]="'oDoc3'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc4')">
				<app-file-download-link [caption]="getTitle('oDoc4')" [docType]="'oDoc4'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc5')">
				<app-file-download-link [caption]="getTitle('oDoc5')" [docType]="'oDoc5'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
		</div>
	</ng-template>
	<ng-template [ngIf]="company == 'ALD'">
		<div class="row mt-5">
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('covid')">
				<app-file-download-link [caption]="'DŮLEŽITÉ INFORMACE PRO NOVÁČKY'" [docType]="'covid'"
					[linkClass]="'link-box link-box--white-red2'">
					<svg class="icon icon--xxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
						<path
							d="M57.15 66.13C57.15 65.12 56.36 64.28 55.36 64.28C54.29 64.28 45.72 64.28 44.64 64.28C43.64 64.28 42.86 65.12 42.86 66.13C42.86 67.19 42.86 75.67 42.86 76.73C42.86 77.73 43.64 78.57 44.64 78.57C45.72 78.57 54.29 78.57 55.36 78.57C56.36 78.57 57.15 77.74 57.15 76.73C57.15 74.61 57.15 67.19 57.15 66.13ZM58.04 30.25C58.04 29.91 57.87 29.47 57.48 29.19C57.15 28.91 56.65 28.57 56.14 28.57C54.91 28.57 45.09 28.57 43.87 28.57C43.36 28.57 42.86 28.91 42.53 29.19C42.14 29.47 41.97 30.02 41.97 30.36C42.06 32.91 42.82 53.31 42.92 55.86C42.92 56.59 43.75 57.14 44.82 57.14C45.85 57.14 54.11 57.14 55.14 57.14C56.15 57.14 56.98 56.59 57.04 55.86C57.04 55.86 57.94 32.81 58.04 30.25ZM99.11 82.31C100.34 84.49 100.29 87.17 99 89.35C97.72 91.52 95.37 92.86 92.86 92.86C84.29 92.86 15.71 92.86 7.14 92.86C4.63 92.86 2.29 91.52 1 89.35C-0.29 87.17 -0.34 84.49 0.89 82.31C5.18 74.46 39.47 11.6 43.75 3.74C44.98 1.45 47.38 0 50 0C52.62 0 55.02 1.45 56.25 3.74C56.25 3.74 94.83 74.46 99.11 82.31Z">
						</path>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contracts_ALD')">
				<app-file-download-link [caption]="'PRACOVNÍ SMLOUVA'" [docType]="'contracts_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('wage_ALD')">
				<app-file-download-link [caption]="'MZDOVÝ VÝMĚR'" [docType]="'wage_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('codex_ALD')">
				<app-file-download-link [caption]="'ETICKÝ KODEX'" [docType]="'codex_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('informationSecurity_ALD')">
				<app-file-download-link [caption]="'INFORMAČNÍ BEZPEČNOST'" [docType]="'informationSecurity_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M87.4 65.9V13.5H9.7v52.4H0l.2 3.4c.8 13.2 12.7 17 19 17.2.5 0 48.7-.3 63 0h.5c5 0 9-1.5 12-4.5 5.2-5 5.3-12.6 5.3-12.9V66zM16.2 20h64.7v45.9H26.7V31.3H70v26.5h6.6v-33H20.2v41.1h-4zm74 57.4c-1.9 1.8-4.5 2.6-7.9 2.6l-22.7-.1H19.3c-7.6-.2-10.6-4.4-11.8-7.5H93a11 11 0 0 1-2.8 5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('affidavit_ALD')">
				<app-file-download-link [caption]="'OSOBA BLÍZKÁ'" [docType]="'affidavit_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('credibility_ALD')">
				<app-file-download-link [caption]="'DŮVĚRYHODNOST'" [docType]="'credibility_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('memorandum_ALD')">
				<app-file-download-link [caption]="'INFORMAČNÍ MEMORANDUM'" [docType]="'memorandum_ALD'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc1')">
				<app-file-download-link [caption]="getTitle('oDoc1')" [docType]="'oDoc1'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc2')">
				<app-file-download-link [caption]="getTitle('oDoc2')" [docType]="'oDoc2'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc3')">
				<app-file-download-link [caption]="getTitle('oDoc3')" [docType]="'oDoc3'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc4')">
				<app-file-download-link [caption]="getTitle('oDoc4')" [docType]="'oDoc4'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc5')">
				<app-file-download-link [caption]="getTitle('oDoc5')" [docType]="'oDoc5'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
		</div>
	</ng-template>
	<ng-template [ngIf]="company == 'SGEF'">
		<div class="row mt-5">
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('covid')">
				<app-file-download-link [caption]="'DŮLEŽITÉ INFORMACE PRO NOVÁČKY'" [docType]="'covid'"
					[linkClass]="'link-box link-box--white-red2'">
					<svg class="icon icon--xxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
						<path
							d="M57.15 66.13C57.15 65.12 56.36 64.28 55.36 64.28C54.29 64.28 45.72 64.28 44.64 64.28C43.64 64.28 42.86 65.12 42.86 66.13C42.86 67.19 42.86 75.67 42.86 76.73C42.86 77.73 43.64 78.57 44.64 78.57C45.72 78.57 54.29 78.57 55.36 78.57C56.36 78.57 57.15 77.74 57.15 76.73C57.15 74.61 57.15 67.19 57.15 66.13ZM58.04 30.25C58.04 29.91 57.87 29.47 57.48 29.19C57.15 28.91 56.65 28.57 56.14 28.57C54.91 28.57 45.09 28.57 43.87 28.57C43.36 28.57 42.86 28.91 42.53 29.19C42.14 29.47 41.97 30.02 41.97 30.36C42.06 32.91 42.82 53.31 42.92 55.86C42.92 56.59 43.75 57.14 44.82 57.14C45.85 57.14 54.11 57.14 55.14 57.14C56.15 57.14 56.98 56.59 57.04 55.86C57.04 55.86 57.94 32.81 58.04 30.25ZM99.11 82.31C100.34 84.49 100.29 87.17 99 89.35C97.72 91.52 95.37 92.86 92.86 92.86C84.29 92.86 15.71 92.86 7.14 92.86C4.63 92.86 2.29 91.52 1 89.35C-0.29 87.17 -0.34 84.49 0.89 82.31C5.18 74.46 39.47 11.6 43.75 3.74C44.98 1.45 47.38 0 50 0C52.62 0 55.02 1.45 56.25 3.74C56.25 3.74 94.83 74.46 99.11 82.31Z">
						</path>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contracts_SGEF')">
				<app-file-download-link [caption]="'PRACOVNÍ SMLOUVA'" [docType]="'contracts_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('wage_SGEF')">
				<app-file-download-link [caption]="'MZDOVÝ VÝMĚR'" [docType]="'wage_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('codex_SGEF')">
				<app-file-download-link [caption]="'ETICKÝ KODEX'" [docType]="'codex_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('informationSecurity_SGEF')">
				<app-file-download-link [caption]="'INFORMAČNÍ BEZPEČNOST'" [docType]="'informationSecurity_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M87.4 65.9V13.5H9.7v52.4H0l.2 3.4c.8 13.2 12.7 17 19 17.2.5 0 48.7-.3 63 0h.5c5 0 9-1.5 12-4.5 5.2-5 5.3-12.6 5.3-12.9V66zM16.2 20h64.7v45.9H26.7V31.3H70v26.5h6.6v-33H20.2v41.1h-4zm74 57.4c-1.9 1.8-4.5 2.6-7.9 2.6l-22.7-.1H19.3c-7.6-.2-10.6-4.4-11.8-7.5H93a11 11 0 0 1-2.8 5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('affidavit_SGEF')">
				<app-file-download-link [caption]="'OSOBA BLÍZKÁ'" [docType]="'affidavit_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('credibility_SGEF')">
				<app-file-download-link [caption]="'DŮVĚRYHODNOST'" [docType]="'credibility_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('memorandum_SGEF')">
				<app-file-download-link [caption]="'INFORMAČNÍ MEMORANDUM'" [docType]="'memorandum_SGEF'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc1')">
				<app-file-download-link [caption]="getTitle('oDoc1')" [docType]="'oDoc1'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc2')">
				<app-file-download-link [caption]="getTitle('oDoc2')" [docType]="'oDoc2'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc3')">
				<app-file-download-link [caption]="getTitle('oDoc3')" [docType]="'oDoc3'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc4')">
				<app-file-download-link [caption]="getTitle('oDoc4')" [docType]="'oDoc4'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc5')">
				<app-file-download-link [caption]="getTitle('oDoc5')" [docType]="'oDoc5'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
		</div>
	</ng-template>
	<ng-template [ngIf]="company == 'KBPS'">
		<div class="row mt-5">
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('covid')">
				<app-file-download-link [caption]="'DŮLEŽITÉ INFORMACE PRO NOVÁČKY'" [docType]="'covid'"
					[linkClass]="'link-box link-box--white-red2'">
					<svg class="icon icon--xxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
						<path
							d="M57.15 66.13C57.15 65.12 56.36 64.28 55.36 64.28C54.29 64.28 45.72 64.28 44.64 64.28C43.64 64.28 42.86 65.12 42.86 66.13C42.86 67.19 42.86 75.67 42.86 76.73C42.86 77.73 43.64 78.57 44.64 78.57C45.72 78.57 54.29 78.57 55.36 78.57C56.36 78.57 57.15 77.74 57.15 76.73C57.15 74.61 57.15 67.19 57.15 66.13ZM58.04 30.25C58.04 29.91 57.87 29.47 57.48 29.19C57.15 28.91 56.65 28.57 56.14 28.57C54.91 28.57 45.09 28.57 43.87 28.57C43.36 28.57 42.86 28.91 42.53 29.19C42.14 29.47 41.97 30.02 41.97 30.36C42.06 32.91 42.82 53.31 42.92 55.86C42.92 56.59 43.75 57.14 44.82 57.14C45.85 57.14 54.11 57.14 55.14 57.14C56.15 57.14 56.98 56.59 57.04 55.86C57.04 55.86 57.94 32.81 58.04 30.25ZM99.11 82.31C100.34 84.49 100.29 87.17 99 89.35C97.72 91.52 95.37 92.86 92.86 92.86C84.29 92.86 15.71 92.86 7.14 92.86C4.63 92.86 2.29 91.52 1 89.35C-0.29 87.17 -0.34 84.49 0.89 82.31C5.18 74.46 39.47 11.6 43.75 3.74C44.98 1.45 47.38 0 50 0C52.62 0 55.02 1.45 56.25 3.74C56.25 3.74 94.83 74.46 99.11 82.31Z">
						</path>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('contracts_KBPS')">
				<app-file-download-link [caption]="'PRACOVNÍ SMLOUVA'" [docType]="'contracts_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('wage_KBPS')">
				<app-file-download-link [caption]="'MZDOVÝ VÝMĚR'" [docType]="'wage_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('codex_KBPS')">
				<app-file-download-link [caption]="'ETICKÝ KODEX'" [docType]="'codex_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('informationSecurity_KBPS')">
				<app-file-download-link [caption]="'INFORMAČNÍ BEZPEČNOST'" [docType]="'informationSecurity_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M87.4 65.9V13.5H9.7v52.4H0l.2 3.4c.8 13.2 12.7 17 19 17.2.5 0 48.7-.3 63 0h.5c5 0 9-1.5 12-4.5 5.2-5 5.3-12.6 5.3-12.9V66zM16.2 20h64.7v45.9H26.7V31.3H70v26.5h6.6v-33H20.2v41.1h-4zm74 57.4c-1.9 1.8-4.5 2.6-7.9 2.6l-22.7-.1H19.3c-7.6-.2-10.6-4.4-11.8-7.5H93a11 11 0 0 1-2.8 5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('affidavit_KBPS')">
				<app-file-download-link [caption]="'OSOBA BLÍZKÁ'" [docType]="'affidavit_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('credibility_KBPS')">
				<app-file-download-link [caption]="'DŮVĚRYHODNOST'" [docType]="'credibility_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M89.7 33.6V40h3.9v37.8H24.2V67.1h56.2l-.2-51L0 15.8v51.3h17.8v17.1H100V33.6H89.7zm-83.3 27V22.3l67.4.2.2 38.2-67.6-.1zm35.1-30.3c-6.3 0-11.5 5.1-11.5 11.5 0 6.3 5.1 11.5 11.5 11.5 6.3 0 11.5-5.1 11.5-11.5-.1-6.4-5.2-11.5-11.5-11.5zm0 18.6c-4 0-7.2-3.2-7.2-7.2s3.2-7.2 7.2-7.2 7.2 3.2 7.2 7.2-3.3 7.2-7.2 7.2zM16 28.9c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm49.8-20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm0 20.4c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('memorandum_KBPS')">
				<app-file-download-link [caption]="'INFORMAČNÍ MEMORANDUM'" [docType]="'memorandum_KBPS'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<path
							d="M74.5 64.1L68 66.7 74 81l-5.3-1.6-2.7 4.7-7-18A34 34 0 1 0 25.3 59L14.1 92.1l13.3-5 7.8 12.3 10.6-31.5 2.5.1a34.1 34.1 0 0 0 4-.2L65 100l7-12.4L85.8 92zM33.3 83.6l-3-4.8-5 1.9L31 63.3a33.8 33.8 0 0 0 7.9 3.4zm15-22.4A27.2 27.2 0 1 1 75.5 34a27.2 27.2 0 0 1-27.2 27.2zm-4.1-23.7l-5.5-6.1-5 4.6 10.5 11.7 20.1-22.5-5-4.6z" />
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc1')">
				<app-file-download-link [caption]="getTitle('oDoc1')" [docType]="'oDoc1'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc2')">
				<app-file-download-link [caption]="getTitle('oDoc2')" [docType]="'oDoc2'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc3')">
				<app-file-download-link [caption]="getTitle('oDoc3')" [docType]="'oDoc3'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc4')">
				<app-file-download-link [caption]="getTitle('oDoc4')" [docType]="'oDoc4'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
			<div class="col-6 col-lg-3 mb-5" *ngIf="isRequired('oDoc5')">
				<app-file-download-link [caption]="getTitle('oDoc5')" [docType]="'oDoc5'">
					<svg class="icon icon--xxxl icon--grey-dark" xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 100 100">
						<g>
							<title>Layer 1</title>
							<g stroke="null" id="svg_1">
								<g stroke="null" id="svg_2">
									<path stroke="null" id="svg_3"
										d="m85.91677,25.00012l-20.8333,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-20.8333c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341l-41.6668,0c-3.44591,0 -6.25003,2.80412 -6.25003,6.25003l0,87.50042c0,3.44591 2.80412,6.25003 6.25003,6.25003l62.5003,0c3.44591,0 6.25003,-2.80412 6.25003,-6.25003l0,-66.66692c0,-1.15001 -0.9334,-2.08341 -2.08341,-2.08341zm-2.08321,68.75033c0,1.1459 -0.9334,2.08341 -2.08341,2.08341l-62.5003,0c-1.15001,0 -2.08341,-0.9375 -2.08341,-2.08341l0,-87.50042c0,-1.1459 0.9334,-2.08341 2.08341,-2.08341l39.58359,0l0,18.75009c0,3.44591 2.80412,6.25003 6.25003,6.25003l18.75009,0l0,64.58371z" />
								</g>
							</g>
							<g stroke="null" id="svg_4">
								<g stroke="null" id="svg_5">
									<path stroke="null" id="svg_6"
										d="m86.35162,24.14259l-25.99302,-25.99302c-0.84477,-0.84477 -2.21814,-0.84477 -3.06291,0c-0.84477,0.84477 -0.84477,2.21814 0,3.06291l25.99302,25.99302c0.42462,0.42015 0.979,0.63256 1.53359,0.63256c0.55459,0 1.10897,-0.21221 1.52932,-0.63256c0.84477,-0.84477 0.84477,-2.21814 0,-3.06291z" />
								</g>
							</g>
						</g>
					</svg>
				</app-file-download-link>
			</div>
		</div>
	</ng-template>


</div>