import { Component, OnInit, Input, SimpleChange } from '@angular/core';
import { Subscription } from 'rxjs';
import { MenuService, IMenuItem } from '../shared/menu.service';
import { UserService } from '../user/user.service';
import { LoggedUserService } from '../shared/logged-user.service';
import { LoginService } from '../login/login.service';


@Component({
  selector: 'ob-navbar',
  templateUrl: 'navbar.component.html',
})
export class NavbarComponent {
  userName:string = '';
  userNameSubscription: Subscription;

	selectedMenu: string;
	isLoggedIn: boolean;
	company: string;
	menuBlock: any;	

	constructor(
		public menu: MenuService,
		private userService: UserService,
    private loggedUserService: LoggedUserService,
		private loginService: LoginService
  ) {
    this.userNameSubscription = this.loggedUserService.userName$.subscribe(userName => {
      // debugger;
      this.userName = userName;
    });
  }

	menuItemKeys = {
		menu: 'menu',
		messages: 'messages',
		communication: 'communication',
		user: 'user'
	};

  //TODO: ZAVRIT PRI KLIKU MIMO CONTENT
  ngOnInit() {
    /*
    Closes dropdown menus
    */
    this.isLoggedIn = this.loginService.loggegIn();
    this.userName = this.userService.getUserName();
	this.company = this.userService.getUserStorage().userDetail.company;

	if('KB'===this.company){
		this.menuBlock = this.menu.getMenuFor("main",true);
	  }else{
		this.menuBlock = this.menu.getMenuFor("main",false);
	  }
  }

	isMenuSelected(item: string) {
		return this.selectedMenu === item;
	}
	closeMenu() {
		this.selectedMenu = null;
	}
	setActiveMenuItem(item: IMenuItem) {
		this.menu.setActive(item);
		this.closeMenu();
	}
	selectMenu(item: string, event?: any) {
		if (event) event.preventDefault();
		this.selectedMenu = item === this.selectedMenu ? null : item;
	}

	logout(e): void {
		e.preventDefault();
		this.loginService.logout(true);
	}


  ngOnDestroy() {
    // prevent memory leak when component destroyed
    this.userNameSubscription.unsubscribe();
  }
}
