import { Component, OnInit, Input } from '@angular/core';

import { FileUploader, FileSelectDirective } from 'ng2-file-upload';

import { YourDocumentService } from '../your-documents.service';
import { MessageService } from '../../core/message/message.service';
import { UserService, StoredFile, UserDocument } from '../../user/user.service';

import { LoginService } from '../../login/login.service'
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})
export class FileUploadComponent implements OnInit {
  @Input() documentType: string;
  @Input() allowMultiple: boolean;
  @Input() allowExtensions: string;

  public uploader: FileUploader = new FileUploader({});
  public maxFileSize: number = 100000000; //FIXME: set real value
  public fileAlias: string = 'uploadedFile';

  uploadedDocuments: UserDocument[];
  errormessage: string;

  constructor(
    private documentService: YourDocumentService,
    private messageService: MessageService,
    private loginService: LoginService,
    private userService: UserService
  ) {};

  ngOnInit() {
    // console.log(this.documentType);
    this.setUploader();
    this.getUploadedDocuments();
  }

  setUploader() {
    let allowExt = [];
    if (this.allowExtensions && this.allowExtensions.length > 0) {
      allowExt = this.allowExtensions.split(',');
    }
    this.uploader.setOptions({
      maxFileSize: this.documentService.maxFileSize,
      filters: [
        {name: 'fileType', 'fn': (item, options) => this.documentService.validFileType(item, options, allowExt)}
      ]
    })
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.errormessage = undefined;
      this.messageService.success({
        title: 'Dokument byl úspěšně nahrán',
        timeout: 15000
      });
      item.remove();
      this.loginService.updateLocalStorage().subscribe(_ => {
        this.getUploadedDocuments();
      });
    }
    this.uploader.onErrorItem = (item, response, status, headers) => {
      this.errormessage = 'Dokument se nepodařilo nahrát';
      this.messageService.error({
        title: this.errormessage,
        timeout: 15000
      });
    }
    this.uploader.onWhenAddingFileFailed = (item, filter, options) => {
      if (filter.name === 'fileType') {
        this.errormessage = 'Tento typ souboru není podporován.';
        this.messageService.error({
          title: this.errormessage,
          timeout: 15000
        });
      }
      if (filter.name === 'fileSize') {
        this.errormessage = `Soubor je příliš velký (Maximální povolená velikost je ${this.documentService.maxFileSizeMB} MB)`;
        this.messageService.error({
          title: this.errormessage,
          timeout: 15000
        });
      }
      // this.fileInput.nativeElement.value = '';
    }
    this.uploader.onAfterAddingFile = (fileItem) => {
      this.errormessage = undefined;
    }
   }

  upload() {
    // debugger;
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => {
      form.append('type', this.documentType);
     };
    this.documentService.uploadDocuments(this.uploader);
  }

  getUploadedDocuments() {
    // debugger;
    this.uploadedDocuments = this.userService.getUserDocuments(this.documentType);
  }

  computeUrl(uid?: string): string {
    return (uid) ? (Configuration.get('d60.apiUrl', true) + "/files/" + uid) : '';
  }

}
