import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { VersionComponent } from './version.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        VersionComponent
    ],
    exports: [
        VersionComponent,
    ]
})
export class VersionModule { }
