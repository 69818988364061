import { Component } from '@angular/core';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { UserService } from '../user/user.service';

@Component({
  selector: 'sa-hp',
  templateUrl: 'home.component.html'
})
export class HomeComponent {
  company:string = '';
  showKBOnly: boolean = true;
  showElement: boolean = true;
  constructor(
		private userService: UserService
  ){

  }

  ngOnInit() {
    /*
    Closes dropdown menus
    */
    this.company = this.userService.getUserStorage().userDetail.company;
    if('KB'===this.company){
      this.showKBOnly = true;
    }else{
      this.showKBOnly = false;
    }

    if('SGEF'== this.company){
      this.showElement = false;
    }
  }

  getJournalUrl() {
    return Configuration.get('d60.apiUrl', true) + "/journal";
  }
}
