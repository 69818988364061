import { NgModule } from '@angular/core';
import { ContactCenterComponent } from './contact-center.component';

@NgModule({
  imports: [ ],
  declarations: [ ContactCenterComponent ],
  exports: [
    ContactCenterComponent ]
})
export class ContactCenterModule {
}
