import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { FileDownloadLinkModule } from '../../shared/file-download-link/file-download-link.module';
import { YourDocumentsComponent } from './your-documents.component';
import { ProcessPointModule } from '../../shared/process-point';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    ProcessPointModule
  ],
  declarations: [
    YourDocumentsComponent
  ],
  exports: [
    YourDocumentsComponent,
  ]
})
export class YourDocumentsModule { }
