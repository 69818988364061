import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../../shared';
import { CafeterieComponent } from './cafeterie.component';

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        CafeterieComponent
    ],
    exports: [
        CafeterieComponent,
    ]
})
export class CafeterieModule { }
