<div *ngIf="userName">
    <ob-navbar></ob-navbar>
</div>
    <kb-message id="angularError"></kb-message>
    <router-outlet></router-outlet>
<div *ngIf="userName">
    <ob-footer></ob-footer>
</div>
<!-- <ng-template #login>
  <ob-login (userName)="setUserName($event)"></ob-login>
</ng-template> -->