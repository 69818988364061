<ng-template #ihnedPoNastupu>
	<div class="row">
        <div class="col-md-5 text-block__title montserat-black text-uppercase">BENEFITY, KTERÉ VÁM NABÍDNEME IHNED PO NÁSTUPU</div>
    	<div class="col-md-7 text-block__content ignore-wysiwyg" [innerHTML]="content_onStart.content">
			<ul>
				<li><b>Plně hrazené elektronické stravenky</b> v hodnotě 100 Kč/den..</li>
				<li><b>Týden dovolené navíc</b>, celkem 5 týdnů.</li>
				<li><b>Narozeninový den</b> - den placeného volna navíc.</li>
				<li><b>Produkty a služby KB s výhodnějšími podmínkami</b>:<br>zdarma vedení  běžného a spořicího účtu, zlatá debetní karta s cestovním pojištěním a dalšími výhodami a mobilní banka. Dále výhodnější úročení na běžném a spořicím účtu.</li>
				<li><b>Širokou nabídku slev u externích dodavatelů</b> v benefitním systému Cafeterie.</li>
			</ul>
		</div>
    </div>
</ng-template>
<ng-template #poZkusebniDobe>
	<div class="row">
        <div class="col-md-5 text-block__title montserat-black text-uppercase">BENEFITY, KTERÉ ZÍSKÁTE PO ODPRACOVÁNÍ TŘECH MĚSÍCŮ</div>
        <div class="col-md-7 text-block__content ignore-wysiwyg" [innerHTML]="content_after3.content">
			<ul>
				<li><b>Poměrnou část z ročního příspěvku 6 720 Kč</b> ve formě bodů do Cafeterie, tedy 560 Kč měsíčně na zdraví, vzdělávání a volnočasové aktivity.</li>
				<li><b>Body do Cafeterie navíc</b> pro zaměstnance s uznaným statusem osoby se zdravotním znevýhodněním či postižením, celkem 5 000 bodů/rok.</li>
				<li><b>Produkty a služby KB či společností Skupiny KB s výhodnějšími podmínkami:</b><br>nižší úroková sazba na hypoteční a jiné úvěry, Lady karta bez poplatku, anebo výhodnější leasing na auto.</li>
				<li><b>Příspěvek na doplňkové penzijní spoření</b> (dříve penzijní připojištění) ve výši 3 % z přiznané základní mzdy dle úvazku, minimální výše příspěvku zaměstnavatele činí 1 100 Kč/měsíc, povinný příspěvek zaměstnance je 100 Kč/měsíc.</li>
				<li><b>Rizikové životní pojištění</b> s pojistným plněním ve výši 24násobku základní měsíční mzdy zesnulého pojištěného zaměstnance.</li>
				<li><b>2 dny pracovního volna</b> s náhradou mzdy</li>
				<li><b>Pracovní volno s náhradou mzdy na firemní dobrovolnictví</b> - 1 den/rok na aktivity podporované KB a Nadací Jistota.</li>
			</ul>
		</div>
    </div>
</ng-template>
<ng-template #benefityPoRoce>
	<div class="row">
        <div class="col-md-5 text-block__title montserat-black text-uppercase">BENEFITY, KTERÉ ZÍSKÁTE ZAČÁTKEM NÁSLEDUJÍCÍHO KALENDÁŘNÍHO ROKU</div>
        <div class="col-md-7 text-block__content ignore-wysiwyg" [innerHTML]="content_afterYear.content">
			<ul>
				<li><b>Body do Cafeterie navíc</b> pro zaměstnance, kteří k 1.1. kalendářního roku dosáhli věku 55 let, celkem 600 bodů/rok.</li>
			</ul>
		</div>
    </div>
</ng-template>
<div class="container my-5">
	<ob-breadcrumb pageName="Benefity"></ob-breadcrumb>
	<div [innerHTML]="content_begin.content"></div>


	<div class="row row--equal-height justify-content-center">
	    <ob-responsive-tile-accordion-trigger (clickEvent)="changeActiveTab('ihnedPoNastupu')" [class]="'col-md-6 col-lg-3'" [isActive]="activeTab == 'ihnedPoNastupu'">
	    	<!-- zakladam-rodinu -->
			<svg class="icon icon--xxxxl icon--red tile-accordion__tile-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M93.7 43.7l-.9-2.6-2.8.2c-.7 0-17.5 1.2-24.7 12.6-3.8 6-4.2 13.4-1.2 22.1L54 89.3l-3.3-7.4-12.1-32.2 8.8-17.8c.8 1.4 1.6 2.7 2.3 4.2a43.4 43.4 0 0 1-.2 35l6.8 2.6a50.5 50.5 0 0 0 0-40.7C45.8 10.4 19.6 1.3 18.5 1l-2.8-1-1.5 2.6c-.7 1.1-16.5 28.7-6.6 52.9 5.5 13.3 17.5 22.8 35.9 28.2l7.2 16.3h5.7l13.7-19.8c10.3-1.1 17.6-4.8 21.7-11.3 7.1-10.8 2.1-24.6 1.9-25.2zm-81.2 2.5c-.9-4.4-.9-8.8-.5-13.1a417 417 0 0 0 15.3 7.2L32.5 54l-20-7.8zm30-20.9l-7.4 15-10.9-28.8a68 68 0 0 1 18.3 13.8zM17.1 13.4l6.5 17.2-10.4-5c1.1-4.7 2.6-8.9 3.9-12.2zm-1.5 41.9A2136 2136 0 0 1 36 63.4l4.4 11.5a42.5 42.5 0 0 1-24.8-19.6zm70.2 9.6c-2.7 4.2-7.8 6.8-15 7.8-1.9-6.1-1.7-11.1.7-14.9 3.7-5.9 11.8-8.1 16.1-8.8.8 3.8 1.6 10.7-1.8 15.9z"/></svg>
			<div class="mt-2 mt-md-3 link-box__caption">Ihned po nástupu</div>
	    </ob-responsive-tile-accordion-trigger>
	    <ob-responsive-tile-accordion [isActive]="activeTab == 'ihnedPoNastupu'" [template]="ihnedPoNastupu" class="col-12 d-md-none"></ob-responsive-tile-accordion>

	    <ob-responsive-tile-accordion-trigger (clickEvent)="changeActiveTab('poZkusebniDobe')" [class]="'col-md-6 col-lg-3'" [isActive]="activeTab == 'poZkusebniDobe'">
	    	<!-- ls-rezerva -->
			<svg class="icon icon--xxxxl icon--red tile-accordion__tile-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M100 38.6l-1.1-4.1c-2.3.5-4.5 1.6-6.3 3.1-.6-.3-1.2-.5-1.8-.6-1.9-.4-3.8-.6-5.7-.6C74.9 14.6 51 16.3 42.5 17.7c-3-6.4-11.5-8.6-12-8.7l-4-1 .3 14.2C21 24.7 16.1 30 14 32.5H.4L0 44l6.4.2.2-5.3h10.7l1-1.3c1.7-2.3 7.4-8.7 12.6-10l2.5-.6-.2-10.1c1.9 1.1 3.7 2.7 4 4.8l.4 3.6 3.5-.9c1.3-.3 31.2-7.8 39.9 18.8 3 9.1.3 19.1-7 25.5l-4 2.8v12.7c-4 .8-10.4 1.5-14.7.2v-8.6h-16v8.4c-2.6 1-8.1 2.6-13.5.1V73.8l-1.6-.9c-2.3-1.3-8.5-5.9-9.2-10.7l-.4-2.7H6.4v-9.1H0v15.5h9.3c2 5.6 7.3 9.8 10.1 11.6V88l1.5.9c3.3 2 7.2 3.1 11.1 3.1 4.2 0 8.3-1.1 12-3l1.6-1v-5.8H49v6.2l1.7.9c8.1 4.3 21.8 1 23.4.6l2.4-.6V74.8l1.4-1 .3-.2c9.3-8 12.8-20.9 8.9-32.5-.1-.2-.1-.3-.2-.5 1.1.1 2.2.2 3.3.5-.4 1.2-.5 2.4-.3 3.6.5 2.6 2.3 4.5 4.3 4.5h.3c.7-.1 3.1-.6 3.1-4.7 0-1.6-.4-3.1-1.3-4.3 1-.7 2.3-1.3 3.7-1.6zm-69.8 4.6c1.6-1.6 1.5-4.1 0-5.7-1.6-1.6-4.1-1.5-5.7 0-1.5 1.6-1.5 4.1 0 5.6 1.6 1.7 4.1 1.7 5.7.1 0 .1 0 .1 0 0zm44.2 6.2l4-1.3c-8.3-24.9-30.8-19.8-31-19.7l1 4.1c.8-.2 19-4.2 26 16.9z"/></svg>
			<div class="mt-2 mt-md-3 link-box__caption">Po 3 měsících</div>
	    </ob-responsive-tile-accordion-trigger>
	    <ob-responsive-tile-accordion [isActive]="activeTab == 'poZkusebniDobe'" [template]="poZkusebniDobe" class="col-12 d-lg-none"></ob-responsive-tile-accordion>
	    <ob-responsive-tile-accordion [isActive]="activeTab == 'ihnedPoNastupu'" [template]="ihnedPoNastupu" class="col-12 d-none d-md-block d-lg-none"></ob-responsive-tile-accordion>

	    <ob-responsive-tile-accordion-trigger (clickEvent)="changeActiveTab('benefityPoRoce')" [class]="'col-md-6 col-lg-3'" [isActive]="activeTab == 'benefityPoRoce'">
	    	<!-- dovolena -->
			<svg class="icon icon--xxxxl icon--red tile-accordion__tile-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M71.5 7.1C46.9-5.6 24.6-1.3 5.2 19.9l-1.1 1.2L6.5 38 28 58.3l7.7 2.8-7.2 18.3C9.9 81.2 5.2 94.8 5 95.4L3.4 100h39.2v-7h-28c3-3.2 8.7-7 18.8-6.8h.1c21.2.3 22 13.3 22 13.8h7a20 20 0 0 0-4-11c-3.2-4.1-9.6-8.9-22.4-9.7l6.1-15.9 17.4 6.2 22.7 2.3 12.5-5 .4-1.8c.2-.4 9.4-40.9-23.7-58zm7.1 57.4l-13.5-1.3c1.7-7.8 5.2-25.6 6.4-39.9a94.2 94.2 0 0 1 7.1 41.2zM59.5 11.2a94 94 0 0 0-30.9 38.1L14.8 36.2a60.2 60.2 0 0 1 44.7-25zM65 16c-.3 13.9-4.8 36.7-6.7 45.7l-23.8-8.5c9.9-21.9 24-33 30.5-37.2zM44.1 7a65.8 65.8 0 0 0-31.9 21.1l-.7-4.7A46.4 46.4 0 0 1 44.1 7zm44.8 54.8l-3.2 1.3c.2-9.1-.5-27.7-7.8-42.8 6 5.9 9.8 13.7 11.2 23.3.8 6 .7 12.1-.2 18.2z"/></svg>
			<div class="mt-2 mt-md-3 link-box__caption">Benefity po roce</div>
	    </ob-responsive-tile-accordion-trigger>
	    <ob-responsive-tile-accordion [isActive]="activeTab == 'benefityPoRoce'" [template]="benefityPoRoce" class="col-12"></ob-responsive-tile-accordion>

	    <ob-responsive-tile-accordion [isActive]="activeTab == 'poZkusebniDobe'" [template]="poZkusebniDobe" class="col-12 d-none d-lg-block"></ob-responsive-tile-accordion>
	    <ob-responsive-tile-accordion [isActive]="activeTab == 'ihnedPoNastupu'" [template]="ihnedPoNastupu" class="col-12 d-none d-lg-block"></ob-responsive-tile-accordion>
	</div>


	<div class="my-8" *ngIf="activeTab == undefined || activeTab == ''" [innerHTML]="content_end.content">
		
	</div>
	<!-- <div class="my-8">
		<h2 class="h1-responsive montserat-black text-uppercase">SPOČÍTEJTE SI CELKOVOU ODMĚNU</h2>
		<div class="text-center my-3">
			<img class="image" src="assets/images/vypocet-odmeny.png" alt="">
		</div>
	</div> -->
</div>
