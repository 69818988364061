import { Injectable } from '@angular/core';

import 'rxjs/add/operator/map';
import { Message } from './message.interface';
import { Subject } from 'rxjs/Subject';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { httpOptions } from '../../login/login.service';
import { HttpClient } from '@angular/common/http';

/**
 * Service for delivering user notification messages.
 */
@Injectable()
export class MessageService {
  messages: Message[] = [];
  private url: string;
  private httpOptions;
  private version: string;

  protected errorSource = new Subject<Message>();
  error$ = this.errorSource.asObservable();

  protected successSource = new Subject<Message>();
  success$ = this.successSource.asObservable();

  protected allSource = new Subject<Message>();
  all$ = this.allSource.asObservable();

  constructor (
    public http: HttpClient
  ) { 
    this.url = Configuration.get('d60.apiUrl', true);
    this.httpOptions = httpOptions;

    this.http.get<any>('/assets/configs/version.json').toPromise()
    .then(data  => {
        this.version = data["version"];
    });
  }

  /**
   * @param {Message} newMessage message
   * @param {string} msgType message type (values: info, success, warning, error)
   */
  private addMessage(newMessage: Message, msgType: string = 'info') {
    newMessage.type = msgType;
    this.messages.push(newMessage);
    this[msgType + 'Source'].next(newMessage);
    this.allSource.next(newMessage);
  }

  public getMessages(): Message[] {
    return this.messages
  }

  public error(newMessage: Message) {
    if (newMessage.error) {
      let log = new LogRequest();
      log.application = 'D60App_' + this.version;
      log.error = newMessage.error;
      this.http.put<any>(this.url + "/log/logerror", log, this.httpOptions ).subscribe();
    }

    this.addMessage(newMessage, 'error');
  }

  public success(newMessage: Message) {
    this.addMessage(newMessage, 'success');
  }

  public removeMessage(newMessage: Message, msgArray: Message[]) {
    msgArray = msgArray || this.messages;
    let index: number = msgArray.indexOf(newMessage);
    if (index != -1) msgArray.splice(index, 1);
  }

}

export class LogRequest {
  application: string;
  error: Error;
  message: string;
}
