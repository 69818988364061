import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { CommunicationService } from '../../shared/communication-message/communication.service';
import { UserService } from '../../user/user.service';
import { LoginService } from '../../login/login.service';

@Component({
    selector: 'ob-cafeterie',
    templateUrl: 'cafeterie.component.html',
})
export class CafeterieComponent {
    isSubmitting: boolean = false;
    amountToConvert = new FormControl(undefined ,[Validators.required, Validators.min(0), Validators.max(2000)]);
    amount = this.userService.getUserStorage().userDetail.amount;

    private subject: string = 'Využívání bodů v Cafeterii';

    constructor(
        protected userService: UserService,
        private loginService: LoginService,
        private communicationService: CommunicationService,
        protected router: Router
    ){}

    ngOnInit() {
        // if(!this.userService.isShowSalaryOptiomalization()) {
        //     this.router.navigate(['/']);
        // }
    }

    scrollToElement($element, event?): void {
      if(event != null)
          event.preventDefault();
      $element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    sendInterest(interested: boolean = true) {
        this.isSubmitting = true;
        let message: string;
        let user = this.userService.getUserStorage();
        let amount = interested && this.amountToConvert ? this.amountToConvert.value : 0;
        if(amount > 0) {
            message = "Uchazeč " + user.name + " má zájem o převádění " + amount + " Kč měsíčně na body v Cafeterii.";
        } else {
            message = "Uchazeč " + user.name + " nemá zájem o využívání bodů v Cafeterii.";
        }
        let recruiter = this.userService.getRecruiter();
        this.communicationService.sendMessage(recruiter.email, this.subject, message)
            .subscribe(res => {
                this.userService.setAmount(amount).subscribe(
                    res => {
                        this.loginService.updateLocalStorage().subscribe(_ => {
                            this.amountToConvert.reset();
                            this.amount = amount;
                            this.isSubmitting = false;
                          });
                    },
                    error => {
                        this.isSubmitting = false;
                    }
                );
            });
    }

}
