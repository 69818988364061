import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CommunicationMessageComponent } from './communication-message.component';
import { CommunicationService } from './communication.service'

@NgModule({
  imports: [
  	RouterModule,
  	CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    CommunicationMessageComponent
  ],
  exports: [
    CommunicationMessageComponent
  ],
  providers: [ CommunicationService ]
})
export class CommunicationMessageModule { }
