import { Directive } from "@angular/core";
import { NG_VALIDATORS, Validator, AbstractControl } from "@angular/forms";

@Directive({
    selector: '[readDocumentValidator]',
    providers: [{provide: NG_VALIDATORS, useExisting: readDocumentValidatorDirective, multi: true}]
  })
  export class readDocumentValidatorDirective implements Validator {
    validate(control: AbstractControl): {[key: string]: any} | null {
      return control.value == true ? null : { 'readDocumentValidator': {value: 'Dokument je nutné přečíst'} };
    }
  }