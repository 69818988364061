import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { ProcessPointModule } from '../../shared/process-point';

import { OnboardingInfoComponent } from './onboarding-info.component';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    ProcessPointModule
  ],
  declarations: [
    OnboardingInfoComponent,
  ],
  exports: [
    OnboardingInfoComponent,
  ]
})
export class OnboardingInfoModule { }
