import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';

import { SatisfactionQuestionaryComponent } from './satisfaction-questionary.component';

import { RatingModule } from 'ngx-bootstrap/rating';

@NgModule({
  imports: [
    SharedModule,
    RatingModule.forRoot(),
  ],
  declarations: [
    SatisfactionQuestionaryComponent,
  ],
  exports: [
    SatisfactionQuestionaryComponent,
  ]
})
export class SatisfactionQuestionaryModule { }
