import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { BenefitsComponent } from './benefits.component';

import { ResponsiveTileAccordionModule } from '../../shared/responsive-tile-accordion';
import { ResponsiveTileAccordionTriggerModule } from '../../shared/responsive-tile-accordion/responsive-tile-accordion-trigger/responsive-tile-accordion-trigger.module';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    ResponsiveTileAccordionModule,
    ResponsiveTileAccordionTriggerModule
  ],
  declarations: [
    BenefitsComponent,
  ],
  exports: [
    BenefitsComponent,
  ]
})
export class BenefitsModule { }
