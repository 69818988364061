import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared';

import { CharityComponent } from './charity.component';

import { ShowAnswerComponent } from './show-answer';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
  ],
  declarations: [
    CharityComponent,
    ShowAnswerComponent
  ],
  exports: [
    CharityComponent,
  ]
})
export class CharityModule { }
