<div class="row">
  <div class="col-auto d-none d-xl-block">
    <img src="assets/images/operatorka.png" alt="">
  </div>
  <div class="col-auto">
    <table class="table-opening-hours" style="display: none;">
      <tr>
        <td>
          <!-- telefon -->
          <svg class="icon icon--grey-dark icon--xxl" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M65.1 100c-1.5 0-3-.1-4.6-.4l1.3-7.7A22.2 22.2 0 0 0 79 87c-.1-3.8-.8-13.6-7-19.4L54.9 76 53 73.5l-2.4-3.2C45 62.5 35.3 47.8 33.9 36.7l-.3-2.4 13.6-8.9c0-2.9-1.2-10-11.1-16.9-4.4 1.7-15.7 7.2-15 18.3C22.2 46 33.7 73.8 51 86.9l-4.7 6.2c-19.8-15-31.8-45.1-33-65.8C12.1 7.7 34.5.8 35.4.5l1.7-.5 1.5.9c19.3 12.2 16.2 27 16 27.6l-.3 1.5L42 38a95 95 0 0 0 15.2 28.2L73 58.7l1.9 1.3c13.1 9.2 12 28.4 11.9 29.2l-.1 1.5-1 1c-.4.3-8.6 8.3-20.6 8.3z"/></svg>
        </td>
        <td>
          <div class="text-uppercase text-muted">Chcete se na něco zeptat</div>
          <div class="montserat-black text--lg">tel. 729 012 300</div>
        </td>
      </tr>
      <tr>
        <td></td>
        <td class="pt-3 text-muted">Kdy jsme na telefonu:</td>
      </tr>
      <tr>
        <td class="montserat-black text--md text-muted">PO</td>
        <td class="montserat-black text--md">9:00 — 12:00 14:00 — 16:00</td>
      </tr>
      <tr>
        <td class="montserat-black text--md text-muted">ÚT</td>
        <td class="montserat-black text--md">9:00 — 12:00</td>
      </tr>
      <tr>
        <td class="montserat-black text--md text-muted">ST</td>
        <td class="montserat-black text--md">9:00 — 12:00 14:00 — 16:00</td>
      </tr>
      <tr>
        <td class="montserat-black text--md text-muted">ČT</td>
        <td class="montserat-black text--md">9:00 — 12:00</td>
      </tr>
      <tr>
        <td class="montserat-black text--md text-muted">PÁ</td>
        <td class="montserat-black text--md">9:00 — 12:00</td>
      </tr>
    </table>
  </div>
</div>
