import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { CommunicationService, SelectedPerson } from './communication.service';
import { MessageService } from '../../core/message/message.service';


@Component({
  selector: 'ob-communication-message',
  templateUrl: 'communication-message.component.html',
})
export class CommunicationMessageComponent {
  @Input() selectedPerson: SelectedPerson;
  @Input() wrapperClasses: string;
  @Output() cancel = new EventEmitter;
  @Output() sent = new EventEmitter;

  message =  new FormControl(undefined ,[Validators.required]);
  isSubmitting: boolean = false;

  private subject: string = 'Zpráva od budoucího zaměstnance';

  constructor(
    private communicationService: CommunicationService,
    private messageService: MessageService
  ){}

  unselectPerson() {
    this.cancel.emit(true);
  }

  sendMessage() {
    this.isSubmitting = true;
  	// this.unselectPerson();
    this.communicationService.sendMessage(this.selectedPerson.email, this.subject, this.message.value)
        .subscribe(res => {
          this.sent.emit(true);
          this.message.reset();
          this.isSubmitting = false;
          this.unselectPerson();
        });
  }
}
