import { Component, OnInit } from '@angular/core';
import { MenuService } from '../shared/menu.service';
import { LoginService } from '../login/login.service';
import { UserService } from '../user/user.service';


@Component({
  selector: 'ob-footer',
  templateUrl: 'footer.component.html',
})
export class FooterComponent {
    isLoggedIn: boolean;
    company:string = '';
    showKBOnly: boolean = true;
    menuType : string;
    menuBlock : any;

	constructor(
        private loginService: LoginService,
        public menu: MenuService,
        protected userService: UserService) {}

    ngOnInit(){
        this.company = this.userService.getUserStorage().userDetail.company;
        this.isLoggedIn = this.loginService.loggegIn();
        if('KB'===this.company){
            this.showKBOnly = true;
            this.menuType = 'footer';
            this.menuBlock = this.menu.getMenuFor("footer",true);
          }else{
            this.showKBOnly = false;
            this.menuType = 'footer2';
            this.menuBlock = this.menu.getMenuFor("footer",false);
          }

    }

}
