<div class="container my-5">
    <ob-breadcrumb pageName="SPOLEČENSKÁ ODPOVĚDNOST"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase">MÁME SRDCE NA DLANI</h1>
	<p class="h6-responsive my-3 mb-5">Ohleduplnost k životnímu prostředí a odpovědný přístup k naší společnosti jsou naše priority. Děláme toho mnoho, proto jsme naše priority <b>zohlednili v 6 pilířích odpovědného chování</b>.</p>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_darujeme-krev.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 align-self-center">
				<div class="p-4 p-lg-6 pl-sm-0">
					<h2 class="h4-responsive montserat-black">Darujeme krev</h2>
					<p class="text--lg">Rádi, pravidelně a z pohodlí kanceláře.</p>
					<p class="mb-0">Kolik jsme darovali naposledy? <ob-show-answer><span class="montserat-black">26 litrů</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4 order-sm-last">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_uklizime-cesko.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 order-sm-first align-self-center">
				<div class="p-4 pr-sm-0 p-lg-6">
					<h2 class="h4-responsive montserat-black">Uklízíme Česko</h2>
					<p class="text--lg">Rádi, pravidelně a po celé České republice.</p>
					<p class="mb-0">Kolik se nás vloni zapojilo? <ob-show-answer><span class="montserat-black">274 dobrovolníků</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_kola-pro-afriku.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 align-self-center">
				<div class="p-4 pl-sm-0 p-lg-6">
					<h2 class="h4-responsive montserat-black">Sbíráme kola pro Afriku</h2>
					<p class="text--lg">Rádi, pravidelně a s mezinárodním přesahem.</p>
					<p class="mb-0">Kolik kol jsme poslali dětem do Afriky? <ob-show-answer><span class="montserat-black">74 kol</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4 order-sm-last">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_borime-bariery.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 order-sm-first align-self-center">
				<div class="p-4 pr-sm-0 p-lg-6">
					<h2 class="h4-responsive montserat-black">Boříme bariéry</h2>
					<p class="text--lg">Rádi, pravidelně a nebojíme se dívat na věci jinak.</p>
					<p class="mb-0">Víte, kolik našich handicapovaných kolegů u nás pracuje? <ob-show-answer><span class="montserat-black">Téměř 150</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_na-kole.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 align-self-center">
				<div class="p-4 pl-sm-0 p-lg-6">
					<h2 class="h4-responsive montserat-black">Jezdíme do práce na kole</h2>
					<p class="text--lg">Rádi, pravidelně a jedině s helmou.</p>
					<p class="mb-0">Kolik km jsme společně najeli na kolech anebo nachodili pěšky za 6 ročníků? <ob-show-answer><span class="montserat-black">199 480 km</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4 order-sm-last">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_charita.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 order-sm-first align-self-center">
				<div class="p-4 pr-sm-0 p-lg-6">
					<h2 class="h4-responsive montserat-black">Přispíváme na charitu</h2>
					<p class="text--lg">Rádi, pravidelně a hodně.</p>
					<p class="mb-0">Kolik peněz ročně přibližně rozdělí naše nadace Jistota? <ob-show-answer><span class="montserat-black">Přes 10 mil. Kč</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="card white-version border">
		<div class="row">
			<div class="col-sm-6 col-lg-4">
				<div class="bg-image-responsive" style="background-image: url('../../assets/images/odpovednost_prostredi.png');"></div>
			</div>
			<div class="col-sm-6 col-lg-8 align-self-center">
				<div class="p-4 pl-sm-0 p-lg-6">
					<h2 class="h4-responsive montserat-black">Snižujeme stopu na životním prostředí</h2>
					<p class="text--lg">Rádi, pravidelně a důsledně zeleně.</p>
					<p class="mb-0">Kolik ročně ušetříme díky využívání LED osvětlení v našich budovách? <ob-show-answer><span class="montserat-black">800.000 Kč</span></ob-show-answer></p>
				</div>
			</div>
		</div>
	</div>
	<div class="mt-8">
	    <h2 class="h2-responsive montserat-black text-uppercase">SPONZORUJEME</h2>
	    <ul class="montserat-black text--lg m-auto">
	    	<li>ZOO Praha</li>
	    	<li>Rock for People</li>
	    	<li>Národní Galerie v Praze</li>
	    	<li>PKF - Prague Philharmonia</li>
			<li>Festival francouzského filmu</li>
			<li>... a mnoho dalších</li>
	    </ul>
    </div>
	<h2 class="h2-responsive montserat-black text-uppercase mt-8">SPOLEČENSKÁ ODPOVĚDNOST</h2>
	<div class="bg-white p-3 px-md-10 py-md-6 lifted-shadow-wrap mb-8 mt-3">
		<div class="row flex-md-row text-center text-md-left align-items-center justify-content-center">
			<div class="col-12 col-lg-4 text-lg-right">
				<span class="h5-responsive montserat-black u-text--red">Podnikáme s ohledem na zájmy nás všech</span>
			</div>
			<div class="col-12 col-lg-auto d-none d-lg-block">
				<!-- arrow-right -->
				<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" width="55" height="130" viewBox="0 0 55 130"><path d="M5.14 130l-1.55-1.23 48.85-62.7L.94 1.24 2.47 0l52.48 66.06z"/></svg>
			</div>
			<div class="col-12 col-lg mt-3 mt-lg-0">
				<div class="h4-responsive montserat-black">Přispíváme na charitu, pomáháme životnímu prostředí i znevýhodněným občanům</div>
			</div>
		</div>
		<div class="lifted-shadow"></div>
	</div>
	<div class="text-center my-5 text--lg">
		<p>Další informace jsou na <a target="_blank" href="https://www.kb.cz">veřejném webu Komerční banky</a>.</p>
	</div>
</div>