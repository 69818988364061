import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { KbInNumbersComponent } from './kb-in-numbers.component';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule
  ],
  declarations: [
    KbInNumbersComponent,
  ],
  exports: [
    KbInNumbersComponent,
  ]
})
export class KbInNumbersModule { }
