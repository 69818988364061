import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';

import { LanguageService, createTranslateProviders } from '@cz.kb.fe.lib/lib-i18n-ng2';
import { TranslateService } from '@ngx-translate/core';

import { HistoryService } from './core/history/history.service';

import { UserService } from './user/user.service';
import { LoggedUserService } from './shared/logged-user.service';
import { DialogData, InformationdialogComponent, DialogType } from './dialogs/informationdialog/informationdialog.component';
import { AppConstants } from './app.constants';
import { MatDialog } from '@angular/material';
import { LoginService } from './login/login.service';
import { LocalDateTime, Duration, ChronoUnit, ZoneOffset } from 'js-joda';
import { MessageService } from './core/message';

enum AutoLogoutDialogState {
  NONE = "NONE",
  DISPLAING = "DISPLAING",
  DISPLAYED10 = "DISPLAYED10",
  DISPLAYED5 = "DISPLAYED5",
  DISPLAYED1 = "DISPLAYED1"
}

@Component({
    selector: 'app-hr-onboarding-app',
    templateUrl: 'app.component.html',
    providers:[ createTranslateProviders('app')]
})
export class AppComponent implements OnInit {
    isCollapsed = true;
    userName : string = '';
    userNameSubscription: Subscription;
    interval: any;

    constructor(languageService: LanguageService,
                translateService: TranslateService,
                private router: Router,
                public historyService: HistoryService,
                private userService: UserService,
                private loggedUserService: LoggedUserService,
                private loginService: LoginService,
                private dialog: MatDialog,
                protected messageService: MessageService) {
        languageService.langChanged$.subscribe(lang => {
                // translateService.resetLang(lang); uncomment if you want to call API everytime
                translateService.use(lang);
            }
        );

        historyService.initHistory();

        // listen to logged user changes
        this.userNameSubscription = this.loggedUserService.userName$.subscribe(userName => {
          // debugger;
          this.userName = userName;
          this.rerouteToLoginIfNeeded();
        });
    }

    ngOnInit() {
      this.userName = this.userService.getUserName();
      // Scroll to top after navigation ends because of UX
      this.router.events
        .subscribe((event) => {
          if (event instanceof NavigationEnd) {
            this.rerouteToLoginIfNeeded();
            window.scrollTo(0,0);
          }
        });

      this.startTimer();
    }

    rerouteToLoginIfNeeded() {
      if(!this.userName && (!this.router.url || !this.router.url.startsWith('/login'))) { // prevent showing content when navigate a page before login
        this.router.navigate(['/login']);
      }
    }

    ngOnDestroy() {
      // prevent memory leak when component destroyed
      this.userNameSubscription.unsubscribe();
    }

    startTimer() {
      this.interval = setInterval(() => {
        let validTo = JSON.parse(localStorage.getItem(AppConstants.HTTP_VALIDTO));
        if (validTo) {
          validTo = LocalDateTime.parse(validTo);
          let now = LocalDateTime.now(ZoneOffset.UTC);
          let diffMinutes = now.until(validTo, ChronoUnit.MINUTES);
          let diffSeconds = now.until(validTo, ChronoUnit.SECONDS);
          if (diffSeconds < 0) {
            this.dialog.closeAll();
            this.loginService.logout(true);
          }
          if (this.canDisplay(diffMinutes)) {
            let data: DialogData;
            data = new DialogData();
            data.title = "Blíží se automatické odhlášení";
            data.message = "Do automatického odhlášení zbývá " + diffMinutes + (diffMinutes == 1 ? " minuta" : " minut") + ". Uložte svá data, mohli byste o ně přijít. Pokud chcete zůstat přihlášeni, stiskněte níže tlačítko ANO.";
            data.question = "Chcete zůstat přihlášeni v aplikaci?";
            data.dialogType = DialogType.WARNING;
            localStorage.setItem(AppConstants.INFODIALOG_AUTOLOGOUT, AutoLogoutDialogState.DISPLAING);
            const dialogRef = this.dialog.open(InformationdialogComponent, { data: data });
        
            dialogRef.afterClosed().subscribe(result => {
              if (result == true) {
                this.loginService.increaseExpiration().subscribe(data => {
  
                },
                error => {
                  this.messageService.success({
                    title: 'Nepodařilo se prodloužit dobu přihlášení.',
                    timeout: 15000
                  });
                })
              }
              else {
                localStorage.setItem(AppConstants.INFODIALOG_AUTOLOGOUT, diffMinutes == 10 ? AutoLogoutDialogState.DISPLAYED10 : (diffMinutes == 5 ? AutoLogoutDialogState.DISPLAYED5 : AutoLogoutDialogState.DISPLAYED1));
              }
            });
          }
        }
      },10000)
    }
  
    canDisplay(diffMinutes: number): boolean {
      let state = localStorage.getItem(AppConstants.INFODIALOG_AUTOLOGOUT);
      if (state == AutoLogoutDialogState.DISPLAING) {
        return false;
      }
  
      if (diffMinutes != 10 && diffMinutes != 5 && diffMinutes != 1) {
        return false;
      }
      if (diffMinutes == 10 && (state == AutoLogoutDialogState.DISPLAYED10 || state == AutoLogoutDialogState.DISPLAYED5 || state == AutoLogoutDialogState.DISPLAYED1)) {
        return false;
      }
      if (diffMinutes == 5 && (state == AutoLogoutDialogState.DISPLAYED5 || state == AutoLogoutDialogState.DISPLAYED1)) {
        return false;
      }
      if (diffMinutes == 1 && state == AutoLogoutDialogState.DISPLAYED1) {
        return false;
      }
  
      return true;
    }
}
