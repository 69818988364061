import { Component } from '@angular/core';

import { UserService } from '../../user/user.service';
import { QuestionaryService } from '../../shared/questionary.service';
import { MessageService } from '../../core/message/message.service';

import { Rating, QuestionaireType, QuestionaryResponse } from '../../shared/questionary.model';

@Component({
  selector: 'ob-satisfaction-questionary',
  templateUrl: 'satisfaction-questionary.component.html',
})
export class SatisfactionQuestionaryComponent {
	max = 5;
	overStar: number;
	isReadonly = false;
	questionaire: QuestionaryResponse = undefined;
  	model: Rating = new Rating();
	isFilled: boolean | undefined;

  	userUid: string;
	company: string;

	constructor(
		protected userService: UserService,
		protected questionaryService: QuestionaryService,
		protected messageService: MessageService
    ) {}

	ngOnInit() {
		this.userUid = this.userService.getUid();
		this.questionaryService.getQuestionary(this.userUid).subscribe((res: QuestionaryResponse[]) => {
			let quest = res.find(i => i.type == QuestionaireType.SATISFACTION);
			if (quest != undefined) {
				this.questionaire = quest;
				this.model = Object.assign(new Rating(), quest.document);
			}
		});
		this.isFilled = this.userService.isFilled(QuestionaireType.SATISFACTION);
		this.company = this.userService.getUserStorage().userDetail.company;
	}

	onSubmit(f) {
    if(f.invalid) return;

		let quest = {
			document: this.model,
			version: 0,
			uid: '',
			type: QuestionaireType.SATISFACTION,
			completed: true
		};
		if (this.questionaire) {
			quest.uid = this.questionaire.uid;
		}
		else {
			delete quest.uid;
		}
		this.questionaryService.postQuestionary(this.userUid, JSON.stringify(quest)).subscribe(res => {
			this.messageService.success({
				title: 'Dotazník byl úspěšně odeslán',
				timeout: 15000
			});
			this.isFilled = true;
		}, err => {
			this.messageService.error({
				title: 'Dotazník se nepodařilo odeslat',
				timeout: 15000,
				error: err
			});
		});
	}

  hoveringOver(value: number): void {
    this.overStar = value;
  }

  resetStar(): void {
    this.overStar = void 0;
  }
}
