import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import {Configuration, ConfigurationLoader} from "@cz.kb.fe.lib/lib-configuration";

require('./app/styles/main.scss');

//presunuto z app.module z duvodu unit testu
if ('prod' === process.env.ENV) {
  // Production
  enableProdMode(); // enable angular production mode in production

} else {
  // Development
  require('raw-loader!./index.ejs');  // watch for changes in index.ejs in development mode
}

var configLoader = new ConfigurationLoader('assets/configs/config.json');
configLoader.load(function(){
  console.info('Configuration loaded -' + Configuration.get('appName', true));
  platformBrowserDynamic().bootstrapModule(AppModule)
      .catch(err => console.error(err));

});
