import { Component } from '@angular/core';
import { Content } from 'src/app/shared/content.model';
import { ContentService } from '../../shared/content.service';

@Component({
  selector: 'ob-kb-in-numbers',
  templateUrl: 'kb-in-numbers.component.html',
})
export class KbInNumbersComponent {
  activeTab: number = 0;

  content_clients: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_main: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_div: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_avg: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_flexi: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};

  content_clients_uuid: string = "723946ba-e021-467b-b63a-e85a046b1bc8";
  content_main_uuid: string = "60329fa7-1f13-441b-95f4-217a949ddea2";
  content_div_uuid: string = "fb82fb60-8936-49cb-a6ea-7f0e7db08d86";
  content_avg_uuid: string = "99bfb53d-ca1d-4c02-bb54-a3656143cd92";
  content_flexi_uuid: string = "9bc87335-c6c3-461a-8df5-069c869f6b91";

  constructor(private contentService: ContentService){}
  ngOnInit() {
    this.contentService.getContent(this.content_clients_uuid).subscribe(res => {
      this.content_clients = res;    
    });
    this.contentService.getContent(this.content_main_uuid).subscribe(res => {
      this.content_main = res;    
    });
    this.contentService.getContent(this.content_div_uuid).subscribe(res => {
      this.content_div = res;    
    });
    this.contentService.getContent(this.content_avg_uuid).subscribe(res => {
      this.content_avg = res;    
    });
    this.contentService.getContent(this.content_flexi_uuid).subscribe(res => {
      this.content_flexi = res;    
    });
  }

  changeTab(tab: number, event?: any) {
  	event.preventDefault();
  	if(tab != this.activeTab) this.activeTab = tab;
  }
}
