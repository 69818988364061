import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { CarouselModule } from 'ngx-bootstrap/carousel';

import { WhatAwaitsComponent } from './what-awaits.component';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    CarouselModule.forRoot(),
  ],
  declarations: [
    WhatAwaitsComponent,
  ],
  exports: [
    WhatAwaitsComponent,
  ]
})
export class WhatAwaitsModule { }
