import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { UserService } from '../../user/user.service'
import { LoginService, httpOptions } from '../../login/login.service'
import { MessageService } from '../../core/message/message.service';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';

export interface SelectedPerson {
      id: number;
      name: string;
      email: string;
      position: string;
}

export class Email {
    public to: string;
    public subject: string;
    public html: string;
}


@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
    protected url: string;
    // sprotected user: User;
    private httpOptions;

    constructor (protected http: HttpClient,
                 protected userService: UserService,
                 protected loginService: LoginService,
                 protected messageService: MessageService
                ) {
        this.url = Configuration.get('d60.apiUrl', true);
    }

    sendMessage(email: string, subject: string, html: string = ''): Observable<Email> {
      this.httpOptions = httpOptions;
      let user = this.userService.getUserStorage();

      return this.http
        .post<Email>(this.url+"/notifications", {from: user.email, to: email, subject: subject, html: html},  this.httpOptions )
          .pipe(
            tap(_ => {
              this.messageService.success({
                title: 'Zpráva byla úspěšně odeslána',
                timeout: 15000
              });
            }),
            catchError((err) => {
              console.error('An error occurred:', err.error);
              this.messageService.error({
                title: 'Zprávu se nepodařilo odeslat',
                timeout: 15000,
                error: err
              });
              return of(err);
            })
          );
    }

}
