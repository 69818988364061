import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { FormsModule }   from '@angular/forms';

import { birthNumberValidatorDirective } from './birth-number.directive';

import { QuestionaryComponent } from './questionary.component';
import { readDocumentValidatorDirective } from './read-document.directive';

@NgModule({
  imports: [
    SharedModule,
    FormsModule
  ],
  declarations: [
    QuestionaryComponent,
    birthNumberValidatorDirective,
    readDocumentValidatorDirective
  ],
  exports: [
    QuestionaryComponent,
  ]
})
export class QuestionaryModule { }
