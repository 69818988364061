import { NgModule } from '@angular/core';
import { NavbarDropdownComponent } from './navbar-dropdown.component';

@NgModule({
  imports: [],
  declarations: [
    NavbarDropdownComponent,
  ],
  exports: [
    NavbarDropdownComponent,
  ]
})
export class NavbarDropdownModule { }
