import { NgModule } from '@angular/core';
import { ResponsiveTileAccordionTriggerComponent } from './responsive-tile-accordion-trigger.component';


@NgModule({
  imports: [],
  declarations: [
    ResponsiveTileAccordionTriggerComponent,
  ],
  exports: [
    ResponsiveTileAccordionTriggerComponent,
  ],
  providers: []
})
export class ResponsiveTileAccordionTriggerModule { }
