<div class="process-point" [ngClass]="wrapperClasses">
	<div class="process-point__icon-wrap">
		<div class="process-point__icon" [ngClass]="{'process-point__icon--done': processed}">
			<div class="process-point__number">{{number}}</div>
			<div class="process-point__icon__check">
				<svg class="icon icon--green" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M33.5 87.4L0 55.3l8.1-8.5L33.3 71l58.4-58.4 8.3 8.3z"/></svg>
			</div>
		</div>
	</div>
	<div class="process-point__content">
		<ng-content></ng-content>
	</div>
</div>
