import { Component, TemplateRef } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { UserService, User, Vignette } from '../../../user/user.service';

import { PeopleService } from './people.service';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { Content } from '../../../shared/content.model';
import { ContentService } from '../../../shared/content.service';
import { SafeHtmlPipe } from 'src/app/core/saveHtmlPipe';
import { StyleService } from '../../../shared/style.service';


export interface ModalData {
  name: string;
  question1: string;
  question2: string;
  question3: string;
}

@Component({
  selector: 'ob-people',
  templateUrl: 'people.component.html'
})
export class PeopleComponent {
  modalRef: BsModalRef;
  modalData: ModalData;
  user: User;
  vignette: Vignette;
  managerPhoto;
  vignettes: Vignette[];
  photoUrl: string = Configuration.get('d60.apiUrl', true) + "/files/";
  content: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  contentUid: string = "2a960b9a-372c-4095-886b-03dbba1ef113";

  company:string = '';
  showKBOnly: boolean = true;

  constructor(private modalService: BsModalService,
              protected userService: UserService,
              private peopleService: PeopleService,
              private contentService: ContentService,
              private styleService : StyleService) {}

  ngOnInit() {
    this.user = this.userService.getUserStorage();
    
    if(!this.user) console.log('WARN: user not loaded');

    this.vignette = this.user.userDetail.vignette;

    this.peopleService.getVignettes().subscribe(res => {
      this.vignettes = res;
    });
    this.contentService.getContent(this.contentUid).subscribe(res => {
      this.content = res;    
    });

    this.company = this.user.userDetail.company;
    if('KB'===this.company){
      this.showKBOnly = true;
    }else{
      this.showKBOnly = false;
    }
    switch (this.company) {
      case 'KB':
        this.styleService.setStyle(".showKB","display","unset");
        this.styleService.setStyle(".showMPSS","display","none");
        this.styleService.setStyle(".showSGEF","display","none");
        break;
      case 'MPSS':
        this.styleService.setStyle(".showKB","display","none");
        this.styleService.setStyle(".showMPSS","display","unset");
        this.styleService.setStyle(".showSGEF","display","none");
        break;
      case 'SGEF':
        this.styleService.setStyle(".showKB","display","none");
        this.styleService.setStyle(".showMPSS","display","none");
        this.styleService.setStyle(".showSGEF","display","unset");
        break;  
      default:
        break;
    }
  }
 
  openModal(template: TemplateRef<any>, person: ModalData, e) {
    e.preventDefault();
    this.modalData = person;
    this.modalRef = this.modalService.show(
    	template,
    	Object.assign({}, { class: 'modal-xl modal-dialog-centered' })
    );
  }
}
