import { Injectable, OnInit } from '@angular/core';

export interface IMenuItem {
    name: string;
    url: string;
    showIn?: string;
    submenu?: any;
};

@Injectable({
	providedIn: 'root',
})
export class MenuService {
	// TODO: dodelat funkci get/set active item
	activeItem: IMenuItem;
	
	getActive() {
		return this.activeItem;
	}
	setActive(item: IMenuItem) {
		this.activeItem = item;
	}
	getParentUrl(item: IMenuItem) {
		// TODO: usekne urlku za lomitkem
		if(item) {
			let url = item.url;
			return url.replace(new RegExp("(.*/)[^/]+$"),"$1");
		} else {
			return '/';
		}
	}
	getMenuFor(block: string,kb: boolean) {
		if(kb){
			return this.menuItems.filter(menu => menu.showIn.includes(block));
		}else{
			return this.menuItemsRest.filter(menu => menu.showIn.includes(block));
		}		
	}

	// TODO: otypovat jednotlive polozky
	menuItems = [
		{
			name: 'Hlavní',
			showIn: ['main', 'footer'],
			submenu: [
				{
					name: 'Informace k nástupu',
					url: '/onboarding-info',
					submenu: [
						{
							name: 'Osobní dotazník',
							url: '/onboarding-info/questionary'
						}
					]
				},
				{
					name: 'Vaše dokumenty',
					url: '/your-documents',
				},
				{
					name: 'Co Vás čeká a nemine',
					url: '/what-awaits',
				},
				{
					name: 'KB Group v číslech',
					url: '/kb-in-numbers',
				},
			]
		},
		{
			name: 'Život v KB Group',
			url: '/life-in-kb',
			showIn: ['main', 'footer'],
			submenu: [
				{
					name: 'Lidé',
					url: '/life-in-kb/people',
				},
				{
					name: 'Zábava',
					url: '/life-in-kb/fun',
				},
				{
					name: 'Charita',
					url: '/life-in-kb/charity',
				},
				{
					name: 'Benefity',
					url: '/benefits',
				},
			]
		},
		{
			name: 'Ostatní',
			showIn: [],
			submenu: [
				{
					name: 'Dotazník spokojenosti',
					url: '/satisfaction-questionary'
				}
			]
		}
	];

	menuItemsRest = [
		{
			name: 'Hlavní',
			showIn: ['main', 'footer'],
			submenu: [
				{
					name: 'Informace k nástupu',
					url: '/onboarding-info',
					submenu: [
						{
							name: 'Osobní dotazník',
							url: '/onboarding-info/questionary'
						}
					]
				},
				{
					name: 'Vaše dokumenty',
					url: '/your-documents',
				},
				{
					name: 'Co Vás čeká a nemine',
					url: '/what-awaits',
				},
				{
					name: 'KB Group v číslech',
					url: '/kb-in-numbers',
				},
			]
		},
		{
			name: 'Život v KB Group',
			url: '/life-in-kb',
			showIn: ['main', 'footer'],
			submenu: [
				{
					name: 'Lidé',
					url: '/life-in-kb/people',
				},
				{
					name: 'Zábava',
					url: '/life-in-kb/fun',
				},
				{
					name: 'Charita',
					url: '/life-in-kb/charity',
				},
			]
		},
		{
			name: 'Ostatní',
			showIn: [],
			submenu: [
				{
					name: 'Dotazník spokojenosti',
					url: '/satisfaction-questionary'
				}
			]
		}
	];
}
