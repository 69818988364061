import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommonModule } from '@angular/common';

import { tap, catchError } from 'rxjs/operators';

import { anyRequiredValidator } from '../shared/validators';
import { LoginService, UserLogInfo } from './login.service';
import { MessageService } from '../core/message/message.service';

@Component({
  selector: 'ob-login-new-pass',
  templateUrl: 'login-new-pass.component.html',
})
export class LoginNewPassComponent {

    uid: string;
    pass1 =  new FormControl(undefined ,[Validators.required]);
    pass2 =  new FormControl(undefined ,[Validators.required]);
    passEqual = true;
    passValid = true;
    passInvalidChange = false;
    requestSuccess = true;

    constructor (
      protected loginService: LoginService,
      protected messageService: MessageService,
      private route: ActivatedRoute,
      private router: Router
    ) {}

    ngOnInit() {
      this.loginService.logout();
      this.uid = this.route.snapshot.paramMap.get('uid')
      this.pass1.setValue('');
      this.pass2.setValue('');
    };

    keyDownFunction(event) {
      if(event.keyCode == 13) {
         this.onSubmit()
      }
    }

    onSubmit() {
      this.passInvalidChange = false;
      this.requestSuccess = true;
      // debugger
      this.passEqual = (this.pass1.valid && this.pass1.value === this.pass2.value);
      if(this.passEqual) {
        this.loginService.validatePassword(this.uid, this.pass1.value).subscribe(
          res => {
            this.passValid = res.state == true;
            if (this.passValid) {
              this.loginService.resetPassword(this.uid, this.pass1.value)
                .subscribe(
                  res => {
                    if(res == null || 204 == res.status) {
                      this.messageService.success({
                        title: 'Vaše heslo bylo úspěšně změněno',
                        timeout: 15000
                      });
                      this.router.navigate(['/login']);
                    } else {
                      this.requestSuccess = false;
                    }
                  },
                  err => {
                    this.requestSuccess = false;
                  });
              }
          },
          error => {
            this.passInvalidChange = error.status == 400;
            this.requestSuccess = this.passInvalidChange;
          });
        }
    }

}
