import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { LoginService, httpOptions } from '../../../login/login.service'
import { Manager, Vignette } from '../../../user/user.service'
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';

@Injectable({
  providedIn: 'root'
})
export class PeopleService {
    protected url: string;
    private httpOptions;

    constructor (protected http: HttpClient,
                 protected loginService: LoginService
                ) {
        this.url = Configuration.get('d60.apiUrl', true);
        this.httpOptions = httpOptions;
    }
 
    getVignettes(): Observable<any> {
      return this.http
        .get<Vignette[]>(this.url+"/enumerations/vignettes",  this.httpOptions );
    }

}