<div class="container my-5">
	<ob-breadcrumb pageName="Život v naší společnosti"></ob-breadcrumb>
	<div class="row">
		<div class="col-sm-6 col-lg-4 mb-5">
			<a routerLink="people" class="link-box link-box--white-red link-box--3col">
				<div class="link-box__content">
					<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path d="M80.1 62.07a8.5 8.5 0 0 1-1.1-5.5 7.65 7.65 0 0 1 1.5-4 17 17 0 0 0 2.1-6.5 7.75 7.75 0 0 1 .76-2.4 18.14 18.14 0 0 0 1.25-3.74 4.5 4.5 0 0 0 .1-2.5 4.5 4.5 0 0 1-.34-1.5 19.14 19.14 0 0 1 .25-2.15 18.4 18.4 0 0 0 .2-2.65 11.2 11.2 0 0 0-3-7.5 11.85 11.85 0 0 0-10-4 11.7 11.7 0 0 0-9.74 4 11.15 11.15 0 0 0-2.84 7.5 19.3 19.3 0 0 0 .25 2.65 16.5 16.5 0 0 1 .2 2.2 8.9 8.9 0 0 1-.35 1.6 4 4 0 0 0 .15 2.5 22.84 22.84 0 0 0 1.2 3.7 7.8 7.8 0 0 1 .8 2.5 17 17 0 0 0 2 6.25 7.6 7.6 0 0 1 1.34 4.14v2.5a5 5 0 0 1-.14 1 1.4 1.4 0 0 1-.5 1l-1.15.8a13.5 13.5 0 0 1-1.6.85l2.1.8 2.1.75a31.5 31.5 0 0 1 8.5 4.65 11 11 0 0 1 3.26 3.6 21.7 21.7 0 0 1 .5 2.9c.2.85.25 6.5.25 17H100l-.5-19.9q-.4-3.1-11.8-7.3l-7.6-3.1zm-7.5 23.7a76.27 76.27 0 0 0-.5-10.5c-.6-2.6-5.74-5.75-15.38-9.5l-4.25-1.5c-2-.7-3.4-1.25-4.25-1.65a3.8 3.8 0 0 1-2-2.1 6.5 6.5 0 0 1-.7-2.4v-4.5a6.6 6.6 0 0 1 1.85-4.24 16.8 16.8 0 0 0 2.65-7.65 7 7 0 0 1 1.15-3.1 15.5 15.5 0 0 0 1.5-4.85 6.2 6.2 0 0 0 0-3.35 7.15 7.15 0 0 1-.4-1.7c.3-2.1.5-4.2.65-6.5a13.5 13.5 0 0 0-3.8-9.5 18.4 18.4 0 0 0-25.74 0 13.6 13.6 0 0 0-3.65 9.6c.1 2.2.35 4.36.65 6.4a9 9 0 0 1-.35 1.9 7 7 0 0 0 .1 3.2 17.6 17.6 0 0 0 1.5 4.85 7.6 7.6 0 0 1 1 3.1 15.7 15.7 0 0 0 2.65 7.65 6.6 6.6 0 0 1 1.8 4.3 14.5 14.5 0 0 1-1.1 7.64 39.2 39.2 0 0 1-10 4.5c-9.75 3.7-14.84 6.85-15.44 9.5a168.94 168.94 0 0 0-.5 17H72.5v-6.5z"/></svg>
					<div class="mt-4 link-box__caption">Lidé</div>
				</div>
			</a>
		</div>
		<div class="col-sm-6 col-lg-4 mb-5">
			<a routerLink="charity" class="link-box link-box--white-red link-box--3col">
				<div class="link-box__content">
					<!-- ls-vydelavam-dost-3 -->
					<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M41.2 49.4l1.7.9 1.7-.9c1.2-.7 30.5-17 25.6-36.8-.8-3.5-2.8-6.6-5.8-8.7-3.4-2.3-7.6-3-12.6-2-3.5.7-6.4 3-8.4 6.1-2.2-3.4-5.8-5.7-9.9-6.2C22.2.1 16.9 8 15.7 12.5c-5 19.9 24.2 36.2 25.5 36.9zm-18.8-35c1.1-3.6 4.4-6 8.1-5.9.6 0 1.3 0 1.9.1 6.8 1 7 9 7 9.9l7 .1v-.3h.1c.8-4.9 3.8-9.2 6.6-9.8 8.5-1.6 10.1 4.6 10.2 5.3v.2c2.8 11.3-12.5 23.3-20.5 28.1-7.9-4.6-23.2-16.5-20.4-27.7zm71.7 34.1c-1.1-.2-10.5-1.9-17.8 1-3.2-1.6-10.1-4-16 .1-3 2.2-5.9 4.6-8.5 7.2H0v32.5l1.6 1c9.7 5.5 20.7 8.4 31.8 8.4C44.1 98.6 54.6 96 64 91l.6-.3 35.4-41-5.9-1.2zM60.2 85.1c-16.6 8.7-36.5 8.8-53.2.2V63.8h38.1c-1.4 1.6-3 3.1-4.6 4.5-2.3.9-4.8 1.3-7.3 1.3h-9.1l.1 7h9c3.7 0 7.3-.7 10.7-2.1l.7-.4c3.1-2.6 5.9-5.5 8.5-8.7 3.5-3.7 7.3-7 11.3-10.1 1.8-1.1 4-1.3 6-.6l-13 14.2c-1.5 1.9-6.7 7.1-11.3 8.2l1.6 6.8c7.7-1.9 14.4-9.7 15-10.4l15.7-17.2c2.5-1 5.3-1.5 8-1.4L60.2 85.1z"/></svg>
					<div class="mt-4 link-box__caption">Společenská odpovědnost</div>
				</div>
			</a>
		</div>
		<div class="col-sm-6 col-lg-4 mb-5">
			<a routerLink="fun" class="link-box link-box--white-red link-box--3col">
				<div class="link-box__content">
					<!-- sport -->
					<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><title>ico_</title><path d="M50 0a49.6 49.6 0 0 0-18 3.4l-1.3.5A50 50 0 1 0 50 0zm42.1 45.9l-8.4-.5-12-12.5 3.1-17a42.3 42.3 0 0 1 17.3 30zM16.8 34.8l13.1-4.4 13.6 11.9-5.4 18H22l-8.7-9.8zM23.9 68h15.2l10.3 12.2-2.5 12a42 42 0 0 1-24.2-10zm33 14.2L71 77.6l3.2 7A42 42 0 0 1 54.8 92zm13.3-12.4l-15.4 5-9.6-11.6L51.1 44l15.4-5.4 11 11.4zm-2.4-58.1l-3.6 19.5-15.4 5.4-13.9-12 2.6-15a42 42 0 0 1 30.3 2.1zm-38.7 1.6L27.4 23l-13.2 4.4a42.6 42.6 0 0 1 14.9-14.2zm-20.9 43l8.2 9.1-.7 9.2a42 42 0 0 1-7.5-18.3zm72.2 23l-3-6.6 7.2-19.6 7.5.5a42.1 42.1 0 0 1-11.7 25.8z"/></svg>
					<div class="mt-4 link-box__caption">Jak se bavíme</div>
				</div>
			</a>
		</div>
	</div>
</div>