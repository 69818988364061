import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { FileUploadModule } from 'ng2-file-upload';

import { FileUploadComponent } from './file-upload.component';

@NgModule({
  imports: [ BrowserModule, CommonModule, FileUploadModule ],
  declarations: [ FileUploadComponent ],
  exports: [
    FileUploadComponent ]
})
export class HrFileUploadModule {
}
