import { Component } from '@angular/core';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'ob-version',
    templateUrl: 'version.component.html',
})
export class VersionComponent {
    version: string = '';

    constructor(private http: HttpClient) { }

    ngOnInit() {
        this.http.get('/assets/configs/version.json').toPromise()
        .then(data  => {
            this.version = data["version"];
        });
    }
}
