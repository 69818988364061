import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';

/**
  * The aim of the service is to notify subscribed components aboud changes of logged user.
  */
@Injectable()
export class LoggedUserService {

  // Observable string source
  private userNameSource = new Subject<string>();

  // Observable string stream
  userName$ = this.userNameSource.asObservable();

  // Service message commands

  /**
    * Notifies subscribers that user was logged in. Provides user name.
    */
  loggedIn(userName: string) {
    this.userNameSource.next(userName);
  }

  /**
    * Notifies subscribers that user was logged out. Broadcasts ''.
    */
  loggedOut() {
    this.userNameSource.next('');
  }
}
