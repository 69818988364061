import { Component } from '@angular/core';

import { LoginService } from './login.service';

@Component({
  selector: 'ob-login-new-pass-info',
  templateUrl: 'login-new-pass-info.component.html',
})
export class LoginNewPassInfoComponent {

  constructor (
    protected loginService: LoginService
  ) {}

  ngOnInit(){
    this.loginService.logout();
  }
}
