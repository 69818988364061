<div class="ml-3">
  <div *ngFor="let doc of uploadedDocuments">
      <a *ngIf="doc.file.deleted != true" href="{{computeUrl(doc.file.uid)}}">{{doc.file.name}}</a>
      <span *ngIf="doc.file.deleted == true">{{doc.file.name}} <small>(obsah souboru byl zpracován)</small> </span>
  </div>
</div>

<input id="file{{documentType}}" *ngIf="!allowMultiple; else multipleFileUploader" type="file" ng2FileSelect [uploader]="uploader" hidden /><br/>
<ng-template #multipleFileUploader>
  <input id="file{{documentType}}" type="file" ng2FileSelect [uploader]="uploader" hidden multiple />
</ng-template>

<div class="row align-items-center" *ngFor="let item of uploader.queue">
  <div class="col mb-2">{{ item?.file?.name }}</div>
  <!-- <div class="col" *ngIf="uploader.isHTML5">
    <div class="progress" style="margin-bottom: 0;">
        <div class="progress-bar" role="progressbar" [ngStyle]="{ 'width': item.progress + '%' }"></div>
    </div>
  </div>
  <div class="col">
    <span *ngIf="item.isSuccess"><i class="glyphicon glyphicon-ok"></i></span>
    <span *ngIf="item.isCancel"><i class="glyphicon glyphicon-ban-circle"></i></span>
    <span *ngIf="item.isError"><i class="glyphicon glyphicon-remove"></i></span>
  </div> -->
  <div class="col">
    <button *ngIf="!item.isUploading" class="btn bg-transparent" type="button" title="Smazat" (click)="item.remove()">
      <svg class="icon icon--black icon--sm" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.526 32.526">
        <path d="M32.53 2.83L29.7 0 16.26 13.44 2.83 0 0 2.83l13.44 13.43L0 29.7l2.83 2.83L16.26 19.1 29.7 32.52l2.83-2.83L19.1 16.26z"/>
      </svg>
    </button>
  </div>
</div>
<div>
  <label *ngIf="!uploader.queue.length"  class="btn btn-outline-secondary mb-0" for="file{{documentType}}">VLOŽIT SOUBOR</label>
  <label *ngIf="allowMultiple && uploader.queue.length"  class="btn btn-outline-secondary mb-0 mr-1" for="file{{documentType}}">VLOŽIT DALŠÍ</label>

  <button *ngIf="!allowMultiple && uploader.queue.length && !uploader.isUploading" class="btn btn-primary" type="button" (click)="upload()">ODESLAT SOUBOR</button>
  <button *ngIf="allowMultiple && uploader.queue.length && !uploader.isUploading" class="btn btn-primary" type="button" (click)="upload()">ODESLAT SOUBORY</button>
  <div *ngIf="uploader.isUploading" class="btn btn-primary">Nahrávám ...</div>
</div>
<div *ngIf="errormessage" class="show-invalid-feedback">
  <div class="invalid-feedback">{{errormessage}}</div>
</div>
