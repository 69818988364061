import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';
import { FileDownloadLinkModule } from '../../shared/file-download-link/file-download-link.module';

import { OnboardingDocumentsComponent } from './onboarding-documents.component';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    FileDownloadLinkModule
  ],
  declarations: [
    OnboardingDocumentsComponent,
  ],
  exports: [
    OnboardingDocumentsComponent,
  ]
})
export class OnboardingDocumentsModule { }
