<ng-template #modal>
	<div class="modal-header pb-0">
		<button type="button" class="close" (click)="modalRef.hide()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
	</div>
	<div class="modal-body">
		<div class="row" *ngIf="modalData">
			<h2 class="h5 montserat-black col-12 col-md-4">{{modalData.name}}</h2>
			<div class="col-md-8">
				<div class="red-border--left">
					<ol>
						<li>
							<strong>Jaké tři rady mě posunuly nebo posouvají vpřed?</strong>
							<br>
							{{modalData.question1}}
						</li>
						<li>
							<strong>Jakou chybu už nikdy neudělám?</strong>
							<br>
							{{modalData.question2}}
						</li>
						<li>
							<strong>Co oceňuji na lidech, se kterými pracuji?</strong>
							<br>
							{{modalData.question3}}
						</li>
					</ol>
				</div>
			</div>
		</div>
		<div class="py-8" *ngIf="!modalData">
			<p>Informace o Vašem nadřízeném se nepodařilo načíst.</p>
		</div>
	</div>
</ng-template>
<div class="container my-5">
	<ob-breadcrumb pageName="Lidé v {{company}}"></ob-breadcrumb>
	<div class="mt-6 mb-10" *ngIf="vignette">
		<div class="row">
			<div class="col-md-5 text-md-right d-flex flex-column justify-content-center">
				<h1 class="h1-responsive montserat-black text-uppercase">VÁŠ MANAŽER</h1>
				<p>Na tři osobní otázky zodpověděl Váš manažer.</p>
			</div>
			<div class="col-md-1 d-none d-md-flex align-items-center">
				<!-- arrow-right-bold -->
				<svg class="icon icon--red icon--gigantic" xmlns="http://www.w3.org/2000/svg" width="25" height="40" viewBox="0 0 25 40"><path d="M1.35 35.45c-.48.47-.48.95 0 1.42l2.69 2.76c.47.48.95.48 1.43 0l18.27-18.96a.96.96 0 0 0 .27-.7c0-.3-.09-.54-.27-.72L5.47.3c-.42-.41-.9-.38-1.43.1L1.35 3.04c-.48.48-.48.95 0 1.43l14.96 15.48z"/></svg>

			</div>
			<div class="col-md-6 d-md-block d-lg-flex align-items-center text-center">
				<div class="coleague-photo coleague-photo--main">
					<svg *ngIf="!vignette.photo.uid" class="icon icon--grey p-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M89.7 70.1l-20-18c6.2-5.5 10.1-13.4 10.1-22.3C79.8 13.3 66.5 0 50 0 33.6 0 20.3 13.3 20.3 29.8S33.6 59.5 50 59.5c4.6 0 9-1.1 12.9-3l19.8 17.8 4.9 17.9H12.5l4.8-16.3 10.2-9.5-5.3-5.7-11.8 11L2.1 100h95.8l-8.2-29.9zM28.1 29.8c0-12.1 9.8-22 22-22 12.1 0 22 9.8 22 22s-9.8 22-22 22c-12.2-.1-22-9.9-22-22z"/></svg>
					<img *ngIf="vignette.photo.uid" [src]="photoUrl + vignette.photo.uid" class="coleague-photo__img" alt="">
				</div>
				<div class="ml-lg-3 mt-3 mt-lg-0">
					<p class="text--lg mb-0"><b>{{vignette.name}}</b></p>
					<h2 class="h6 montserat-black mt-2">{{vignette.position}}</h2>
					<a href="." (click)="openModal(modal, vignette, $event)">Více informací</a>
				</div>
			</div>
		</div>
	</div>
	<div class="mb-6">
		<h1 class="h2-responsive montserat-black text-uppercase">SEZNAMTE SE S NAŠIMI MANAŽERY</h1>
		<p class="mt-3" *ngIf="!vignettes || vignettes.length == 0">Právě připravujeme nový seznam manažerů i s jejich profily. Vše se brzy zobrazí na této stránce.</p>
		<div *ngIf="vignettes && vignettes.length > 0">
			<p class="mt-3">Všichni jsou výborní profesionálové, ale také skvělí kolegové.</p>
			<div class="row mt-5">
				<div *ngFor="let vg of vignettes" class="col-sm-6 col-md-4 col-lg-3 text-center mb-5">
					<div class="coleague-photo">
						<svg *ngIf="!vg.photo?.uid" class="icon icon--grey p-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M89.7 70.1l-20-18c6.2-5.5 10.1-13.4 10.1-22.3C79.8 13.3 66.5 0 50 0 33.6 0 20.3 13.3 20.3 29.8S33.6 59.5 50 59.5c4.6 0 9-1.1 12.9-3l19.8 17.8 4.9 17.9H12.5l4.8-16.3 10.2-9.5-5.3-5.7-11.8 11L2.1 100h95.8l-8.2-29.9zM28.1 29.8c0-12.1 9.8-22 22-22 12.1 0 22 9.8 22 22s-9.8 22-22 22c-12.2-.1-22-9.9-22-22z"/></svg>
						<img *ngIf="vg.photo?.uid" [src]="photoUrl + vg.photo.uid" class="coleague-photo__img" alt="">
					</div>
					<h2 class="h6 montserat-black mt-3">{{vg.position}}</h2>
					<p><b>{{vg.name}}</b></p>
					<a href="." (click)="openModal(modal, vg, $event)">Více informací</a>
				</div>
			</div>
		</div>
	</div>
	<div [innerHTML]="content.content"></div>
	<div class="my-5 text-center">
		<button routerLink="/kb-in-numbers" class="btn btn-outline-secondary">Další statistiky</button>
	</div>
</div>