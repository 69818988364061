import { Component, Input, TemplateRef, OnInit } from '@angular/core';


@Component({
  selector: 'ob-responsive-tile-accordion',
  templateUrl: 'responsive-tile-accordion.component.html',
})
export class ResponsiveTileAccordionComponent implements OnInit {
  @Input() template: TemplateRef<any>;
  @Input() displayClasses: string;
  @Input() isActive: boolean = false;

  ngOnInit() {
  }
}
