<div class="container my-5">
    <ob-breadcrumb pageName="CO VÁS ČEKÁ A NEMINE"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase">{{firstDateInWork | date:'d.&nbsp;MMMM'}} NASTUPUJETE</h1>
	<p class="h6-responsive my-3"><b>Podívejte se, jaké budou Vaše první dny a týdny v nové práci.</b></p>
    <div class="timeline-carousel gray-light-version">
        <ul class="timeline-carousel__nav list-unstyled js-timeline-carousel__nav">
            <li class="timeline-carousel__nav-item text-center">
                <a href="." class="timeline-carousel__nav-link uppercase" [class.is-active]="activeSlideIndex == 0" (click)="changeActiveSlide(0, $event)">1.
                    <span class="timeline-carousel__nav-link-label">týden</span>
                </a>
            </li>
            <li class="timeline-carousel__nav-item text-center">
                <a href="." class="timeline-carousel__nav-link uppercase" [class.is-active]="activeSlideIndex == 1" (click)="changeActiveSlide(1, $event)">2.
                    <span class="timeline-carousel__nav-link-label">týden</span>
                </a>
            </li>
            <li class="timeline-carousel__nav-item text-center">
                <a href="." class="timeline-carousel__nav-link uppercase" [class.is-active]="activeSlideIndex == 2" (click)="changeActiveSlide(2, $event)">3.
                    <span class="timeline-carousel__nav-link-label">týden</span>
                </a>
            </li>
        </ul>
        <carousel [interval]="false" [showIndicators]="false" [(activeSlide)]="activeSlideIndex">
            <slide>
                <!-- TODO: predelat na komponentu -->
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="vert-carousel__nav">
                            <li class="vert-carousel__nav__item" [class.is-active]="vertCarousel1Index == 0" (click)="vertCarousel1Index = 0" *ngIf="showKBOnly">Seznam se s KuBou</li>
                            <li class="vert-carousel__nav__item" [class.is-active]="vertCarousel1Index == 1" (click)="vertCarousel1Index = 1">Povinná školení</li>
                            <li class="vert-carousel__nav__item" [class.is-active]="vertCarousel1Index == 2" (click)="vertCarousel1Index = 2" *ngIf="showKBOnly">Uvítací seminář</li>
                            <li class="vert-carousel__nav__item" [class.is-active]="vertCarousel1Index == 3" (click)="vertCarousel1Index = 3">Vstupní karta</li>
                            <li class="vert-carousel__nav__item" [class.is-active]="vertCarousel1Index == 4" (click)="vertCarousel1Index = 4">Počítač</li>
                            <li class="vert-carousel__nav__item" [class.is-active]="vertCarousel1Index == 5" (click)="vertCarousel1Index = 5">Mobilní telefon</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel1Index == 0"
                            (click)="vertCarousel1Index = 0">
                            Seznam se s Kubou
                        </h4>
                        <div *ngIf="vertCarousel1Index == 0" class="vert-carousel__content">
                            <div class="comix mb-3">
                                <div class="robot">
                                    <img src="assets/images/robot.gif" alt="">
                                </div>
                                <div class="bubble" [innerHTML]="content_kuba.content">
                                    <p class="m-0">Dobrý den,</p>
                                    <p>já jsem KuBa. Chatovací robot s umělou inteligencí. Můžete se mě ptát na cokoli. Poradím třeba, kdy dostanete stravenky nebo kde najdete svůj mzdový lístek.</p>
                                </div>
                            </div>
                        </div>
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel1Index == 1"
                            (click)="vertCarousel1Index = 1">
                            Povinná školení
                        </h4>
                        <div *ngIf="vertCarousel1Index == 1" class="vert-carousel__content" [innerHTML]="content_training.content">
                            <h3 class="h4 opensans-bold mb-3">Povinná školení! Víme, že je to nuda, ale musí to být</h3>
                            <p><b>Tato školení musíte absolvovat, abyste u nás mohli pracovat. Povinnost nám ukládá buď vnější regulátor (např. Česká národní banka) nebo vnitřní předpisy či mateřská společnost KB nebo Société Générale.</b></p>
                            <p>Abyste to měli jednodušší, většina povinných kurzů je připravena formou e-learningu a provádějí se v aplikaci Edu portál. Edu portál je naše vzdělávací aplikace. Najdete v ní úkoly, termíny školení, knihovnu inspirativních videí, materiály ze školení a další zajímavé informace.</p>
                            <p>Po nástupu obdržíte email, ve kterém Vás požádáme o absolvování povinných školení. Je potřeba, abyste je splnili co nejdříve po nástupu.</p>
                        </div>
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel1Index == 2"
                            (click)="vertCarousel1Index = 2">
                            Uvítací seminář
                        </h4>
                        <div *ngIf="vertCarousel1Index == 2" class="vert-carousel__content" [innerHTML]="content_workshop.content">
                            <h3 class="h4 opensans-bold mb-3">eStartinG je náš uvítací seminář</h3>
                            <!-- <img class="image my-3" src="assets/images/starting-logo.png" alt=""> -->
                            <p><b>Než Vás nadobro pohltí pracovní režim, představíme Vám důležité části banky a také to, jak v ní fungovat.</b></p>
                            <p>Cílem semináře je přiblížit Vám základní principy práce v bance, představit Vám Skupinu KB a vybrané útvary banky.</p>
                        </div>
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel1Index == 3"
                            (click)="vertCarousel1Index = 3">
                            Zaměstnanecká karta
                        </h4>
                        <div *ngIf="vertCarousel1Index == 3" class="vert-carousel__content" [innerHTML]="content_unipass.content">
                            <h3 class="h4 opensans-bold mb-3">Zaměstnanecká karta  - Zaměstnanecká karta je Vaším klíčem ke všemu</h3>
                            <p><b>Otevře Vám dveře v práci, přihlásí Vás do počítače. Zajistí automatické přihlašování do vybraných aplikací.</b></p>
                            <p>Zaměstnanecká karta je Vaším klíčem ke všemu:</p>
                            <ul>
                                <li><b>otevře Vám dveře v práci</b></li>
                                <li>zajistí Vám <b>vstupy do dalších budov</b> Komerční banky</li>
                                <li>má dodatkové funkce ve vybraných budovách - vstupy do specifických prostor (garáže, kolárna)</li>
                                <li>můžete ji využít pro tisk</li>
                                <li>slouží jako <b>zaměstnanecký průkaz</b> - třeba při uplatňování slev u vybraných provozovatelů</li>
                            </ul>
                        </div>
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel1Index == 4"
                            (click)="vertCarousel1Index = 4">
                            Počítač
                        </h4>
                        <div *ngIf="vertCarousel1Index == 4" class="vert-carousel__content" [innerHTML]="content_pc.content">
                            <h3 class="h4 opensans-bold mb-3">Bez počítače se v Komerční bance neobejdete</h3>
                            <p><b>Typ počítače záleží na pracovní pozici. Pokud Vás předem zajímá, jaký dostanete, zeptejte se svého nadřízeného při podpisu smlouvy.</b></p>
                            <p>Budovy KB jsou pokryty WiFi připojením.</p>
                        </div>
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel1Index == 5"
                            (click)="vertCarousel1Index = 5">
                            Mobilní telefon
                        </h4>
                        <div *ngIf="vertCarousel1Index == 5" class="vert-carousel__content" [innerHTML]="content_phone.content">
                            <h3 class="h4 opensans-bold mb-3">Chcete používat vlastní mobil? Je tu BYOD!</h3>
                            <p><b>Tento koncept se nazývá Bring Your Own Device (BYOD). V tomto případě Vás Komerční banka vybaví pouze firemní SIM kartou. Zároveň Vám budeme vyplácet příspěvek jako náhradu za opotřebení soukromého telefonu.</b></p>
                            <h3 class="h4 opensans-bold mt-5 mb-3">Jak je to se SIM kartou?</h3>
                            <p><b>Dle pozice Vás vybavíme služební SIM kartou, která je ve vlastnictví KB.</b></p>
                            <h3 class="h4 opensans-bold mt-5 mb-3">Dá se převést vlastní SIM karta?</h3>
                            <p><b>Převod SIM karty je možný při splnění následujících podmínek.</b></p>
                            <ol class="mt-3 ol--simple black-version">
                                <li><b>SIM karta musí být s paušálním tarifem</b></li>
                                <li>
                                    <p><b>Musíte mít souhlas svého manažera</b></p>
                                    <p>Pokud máte číslo u operátora O2, bude Vám odeslán formulář pro převod SIM, realizace převodu je do 5 pracovních dnů od dodání formuláře.</p>
                                    <p>Pokud máte jiného operátora, pošlete nám kód ČVOP  (Číslo Výpovědi Opouštěného Poskytovatele) od stávajícího mobilního operátora (platnost kódu 60 dnů), dále jméno a adresu pracoviště pro dodání nové SIM karty. Lhůta pro dodání nové SIM karty a realizaci převodu je 10 pracovních dnů.</p>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </slide>
            <slide>
                <!-- TODO: predelat na komponentu -->
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="vert-carousel__nav">
                            <li class="vert-carousel__nav__item"
                                [class.is-active]="vertCarousel2Index == 0"
                                (click)="vertCarousel2Index = 0">Schůzka se šéfem</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel2Index == 0"
                            (click)="vertCarousel2Index = 0">
                            Schůzka se šéfem
                        </h4>
                        <div *ngIf="vertCarousel2Index == 0" class="vert-carousel__content">
                            <p>Váš manažer se s Vámi brzy po nástupu sejde a vyhodnotí s Vámi Vaše počáteční úspěchy a plány na zkušební dobu.</p>
                        </div>
                    </div>
                </div>
            </slide>
            <slide>
                <!-- TODO: predelat na komponentu -->
                <div class="row">
                    <div class="col-lg-6">
                        <ul class="vert-carousel__nav">
                            <li class="vert-carousel__nav__item"
                                [class.is-active]="vertCarousel3Index == 0"
                                (click)="vertCarousel3Index = 0">Co bude dál?</li>
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <h4 class="vert-carousel__nav__item vert-carousel__nav__item--left d-lg-none"
                            [class.is-active]="vertCarousel3Index == 0"
                            (click)="vertCarousel3Index = 0">
                            Co bude dál?
                        </h4>
                        <div *ngIf="vertCarousel3Index == 0" class="vert-carousel__content">
                            <p><b>Dále už jen samé jistoty a prosperita.</b></p>
                            <p><b>Dovolená v Bulharsku, osobní vůz značky Škoda, rodinný domek v klidné části obce... :) Ale vážně, čeká Vás fajn tým a spousta smysluplné práce, na jejímž konci jsou splněné sny našich klientů.</b></p>
                        </div>
                    </div>
                </div>
            </slide>
        </carousel>
    </div>
</div>
