<div class="container">
  <h1 mat-dialog-title><div [class]="data.dialogType">{{data.title}}</div></h1>
  <div mat-dialog-content>
    <div class="form-group row">
      <div class="col-md-2">
        <fa name="exclamation-triangle" class="fa-3x" *ngIf="data.dialogType == getDialogType.WARNING"></fa>
        <fa name="bug" class="fa-3x" *ngIf="data.dialogType == getDialogType.ERROR"></fa>
        <fa name="question-circle" class="fa-3x" *ngIf="data.dialogType == getDialogType.QUESTION"></fa>
        <fa name="info-circle" class="fa-3x" *ngIf="data.dialogType == getDialogType.INFORMATION"></fa>
      </div>
      <div class="col-md-10">
        <p>{{data.message}}</p>
        <p *ngIf="data.question">{{data.question}}</p>
      </div>
    </div> 
    <div mat-dialog-actions class="form-group row">
      <button *ngIf="hasQuestion()" mat-button (click)="onClick(false)" cdkFocusInitial class="btn btn-primary">Ne</button>
      <button *ngIf="hasQuestion()" mat-button (click)="onClick(true)" class="btn btn-primary">Ano</button>
      <button *ngIf="!hasQuestion()" mat-button (click)="onClick(undefined)" class="btn btn-primary">Ok</button>
    </div>
  </div>
</div>