import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { LocalDateTime, nativeJs, ZoneOffset } from "js-joda";
import { Observable, of } from 'rxjs';;
import 'rxjs/add/observable/empty';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

import { HttpRestJsonService } from '@cz.kb.fe.lib/lib-http-helpers-ng2';

import { UserService, User } from '../user/user.service';
import { LoggedUserService } from '../shared/logged-user.service';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { AppConstants } from '../app.constants';

export class UserLogInfo {
    public name: string;
    public email: string;
    public password: string;
    public rolesInDB?: string;//dynamic att filled for select box widget
}

export const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Accept': 'application/json',
       }),
      withCredentials: true
};

export class userLogInfo{
  email: string
  password: string
  status: number
}

export class validPassword {
  state: boolean
  message: string
}

@Injectable({
  providedIn: 'root'
})
export class LoginService {
    protected url: string;
    protected user: User;
    public xKBSession: string;
    public xKBSessionValidity: string;

    constructor (protected httpSC: HttpRestJsonService<UserLogInfo>,
                 protected http: HttpClient,
                 protected router: Router,
                 public userService: UserService,
                 private loggedUserService: LoggedUserService
                ) {
        this.url = Configuration.get('d60.apiUrl', true);
    }

    postLogInfo(user: UserLogInfo): Observable<userLogInfo> {
      let userAgent = navigator.userAgent;
      return this.http
        .post<userLogInfo>(this.url+"/users/login", {email: user.email, password: user.password, userAgent: userAgent },  {...httpOptions, observe: 'response'} )
          .pipe(
            map((res: any)=> {
              
              if (res.body.rolesInDB != "USER") {
                console.log("no user");
                return {  'email': undefined,  'password': undefined, 'status': 500};
              }
              console.log(res.headers.get(AppConstants.HTTP_VALIDTO));
              localStorage.setItem(AppConstants.HTTP_VALIDTO, JSON.stringify(res.headers.get(AppConstants.HTTP_VALIDTO)));
              this.user = res.body;
              this.userService.setUserStorage(this.user);
              // console.log('getUser', this.userService.getUserStorage());
              this.loggedUserService.loggedIn(this.user.name);

              return {  'email': user.email,  'password': user.password, 'status': res.status};
            })
          );
    }

    updateLocalStorage(): Observable<void> {
      let uid: string = this.userService.getUid();

      return this.http.get<User>(this.url+"/users/"+uid, httpOptions).pipe(
        map(res => {
          // console.log('Local Storage updated with', res);
          this.userService.setUserStorage(res);
          Observable.empty();
        }),
        catchError(err => {
          console.error('An error occurred:', err.error);
          return Observable.empty();
        })
      );
    }

    loggegIn(): boolean {
      let validTo = JSON.parse(localStorage.getItem(AppConstants.HTTP_VALIDTO));
      if (validTo) {
        let from = LocalDateTime.parse(validTo);
        let now = LocalDateTime.now(ZoneOffset.UTC);
        return now.isBefore(from);
      }

      return false;
    }

    increaseExpiration() : Observable<userLogInfo> {
      return this.http.post<userLogInfo>(this.url + '/users/incexpiration', {}, {...httpOptions, observe: 'response'})
        .pipe(
          map((res: any) => {
            localStorage.setItem(AppConstants.HTTP_VALIDTO, JSON.stringify(res.headers.get(AppConstants.HTTP_VALIDTO)));
            this.user = res.body;
            this.userService.setUserStorage(this.user);
            // console.log('getUser', this.userService.getUserStorage());
            this.loggedUserService.loggedIn(this.user.name);

            return { 'email': this.user.email,  'password': '', 'status': res.status };
          })
        );
    } 

    logout(redirect: boolean = false): void {
      this.http.post<HttpResponse<void>>(this.url+"/users/logout", httpOptions)
          .subscribe((res) => {
            this.userService.clearStorages();
            this.loggedUserService.loggedOut();
            if(redirect) {
              this.router.navigate(['/login']);
            }
          })
    }

    resetPasswordRequest(email: string): Observable<HttpResponse<void>> {
      return this.http.put<HttpResponse<void>>(this.url+"/users/passwords/reset", {email: email}, httpOptions);
    }

    resetPassword(uid: string , password: string): Observable<HttpResponse<void>> {
      return this.http.post<HttpResponse<void>>(this.url+"/users/passwords", {uid: uid, password: password }, httpOptions)
            .pipe(
                catchError((err) => {
                  console.error('An error occurred:', err.error);
                  return of(err);
                })
              )
    }

    validatePassword(token: string, password: string): Observable<validPassword> {
      return this.http.post<validPassword>(this.url+"/users/validatepassword", { uid: token, password: password }, httpOptions);
    }
}
