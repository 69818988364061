import { Injectable } from "@angular/core";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Location } from "@angular/common";
import "../../../rxjs-operators";
import { filter } from 'rxjs/operators';
/**
 * HistoryService is a service that collects routing history
 * History has stack structure - i.e. going back means removing
 * last history record and vice versa.
 */
@Injectable()
export class HistoryService {
    private history: string[] = [];
    private isNavigating: boolean = false;

    constructor(private router: Router,
                private location: Location) {};

    public initHistory(): void {
        
        this.router.events
            .pipe(filter(event => event instanceof NavigationStart))
            .subscribe(() => {
                this.isNavigating = true;
            });

        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({urlAfterRedirects, }: NavigationEnd) => {
                this.isNavigating = false;
                this.history.push(urlAfterRedirects);
            });

        this.location.subscribe((evt: PopStateEvent) => {
            this.history.pop();
            this.history.pop();
        });
    }

    get hasHistory() {
        // do not count the first page in
        return (this.history.length > 1);
    }

    get canGoBack() {
        return this.hasHistory || this.isNavigating;
    }
}
