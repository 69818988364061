import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { anyRequiredValidator } from '../shared/validators';
import { LoginService } from './login.service';

@Component({
  selector: 'ob-login-reset',
  templateUrl: 'login-reset.component.html',
})
export class LoginResetComponent {

    email =  new FormControl(undefined ,[Validators.required, Validators.email]);
    requestSuccess: boolean = true;
    submitted: boolean = false;
    isSubmitting: boolean = false;

    constructor (
      protected loginService: LoginService,
      private router: Router
    ) {}

    ngOnInit(){
      this.loginService.logout();
      this.email.setValue('');
    };

    keyDownFunction(event) {
      if(event.keyCode == 13) {
         this.onSubmit()
      }
    }

    onSubmit() {
      this.submitted = true;
      if (this.email.valid) {
        this.isSubmitting = true;
        this.loginService.resetPasswordRequest(this.email.value)
          .subscribe(
            res => {
              this.isSubmitting = false;
              if(res == null || 204 == res.status) {
                this.router.navigate(['/login-new-pass-info']);
              } else {
                this.requestSuccess = false;
              }
            },
            err => {
              this.isSubmitting = false;
              this.requestSuccess = false;
            });
      }
    }
}
