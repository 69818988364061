export interface QuestionaryResponse {
  document: Questionary
  version: string,
  uid: string,
  type: number,
  completed: boolean
}

export class Rating {
  overall: number = 0;
  speedOfTender: number = 0;
  attitude: number = 0;
  enoughInformation: number = 0;
  notes: string;
}

export class Questionary {
	constructor(
		public firstName: string = null,
		public lastName: string = null,
		public birthName: string = null,
		public titleBeforeName: string = null,
		public titleAfterName: string = null,
		public birthNumber: string = null,
		public maritalStatus: string = '',
		public nationylity: string = '',  // preklep v API
		public nationalityOther: string = null,
		public foreignDocument: {
			type: string,
			number: string,
			office: string
		} = {
			type: '',
			number: null,
			office: null
		},
		public firstJob: boolean = null,
		public birthAddress:{
			city: string,
			districts: string,
			country: string
		} = {
			city: null,
			districts: null,
			country: ''
		},
		public healthInsuraqnce: string = '', //preklep
		public healthInsuranceOther: string = null,
		public address:{
			street: string,
			streetNumber: string,
			orientationNumber: string,
			city: string,
			zipcode: string
		} = {
			street: null,
			streetNumber: null,
			orientationNumber: null,
			city: null,
			zipcode: null
		},
		public address2:{
			street: string,
			streetNumber: string,
			orientationNumber: string,
			city: string,
			zipcode: string
		} = {
			street: null,
			streetNumber: null,
			orientationNumber: null,
			city: null,
			zipcode: null
		},
		public criminalProceedings: boolean = null,
		public americanPerson: boolean = null,
		public politician: boolean = null,
		public politicianClosePerson: boolean = null,
		public politicianClosePersonDescription: string = '',
		public gainfulActivity: boolean = null,
		public gainfulActivityDescription: string = null,
		public statutoryBody: boolean = null,
		public statutoryBodyDescription: string = null,
		public closePerson: boolean = null,
		public closePersonType: string = '',
		public closePersonDescription: string = '',
		public codexWasRead: boolean = false,
    	public informationSecurityWasRead: boolean = false,
		public partTime: boolean = null,
		public child10: boolean = null,
		public student: boolean = null,
		public handicap: boolean = null
	) {  }
}

export const  maritalStatusEnum: String[] = [
	'svobodný (á)',
	'ženatý – vdaná',
	'rozvedený (á)',
	'ovdovělý (á)',
	'registrované partnerství',
	'zaniklé partnerství'
]

export const nationalityEnum: String[] = [
	"Česká republika",
	"Slovensko",
	"Albánie",
	"Austrálie",
	"Rakousko",
	"Belgie",
	"Bosna a Hercegovina",
	"Bulharsko",
	"Bělorusko",
	"Kanada",
	"Čína",
	"Tchaj-wan",
	"Chorvatsko",
	"Kypr",
	"Dánsko",
	"Estonsko",
	"Finsko",
	"Francie",
	"Gruzie",
	"Německo",
	"Řecko",
	"Hongkong",
	"Maďarsko",
	"Island",
	"Irsko",
	"Izrael",
	"Itálie",
	"Japonsko",
	"Kazachstán",
	"Korejská republika",
	"Lotyšsko",
	"Lichtenštejnsko",
	"Litva",
	"Lucembursko",
	"Malta",
	"Černá Hora",
	"Nizozemsko",
	"Norsko",
	"Polsko",
	"Portugalsko",
	"Rumunsko",
	"Rusko",
	"Srbsko",
	"Vietnam",
	"Slovinsko",
	"Španělsko",
	"Švédsko",
	"Švýcarsko",
	"Turecko",
	"Ukrajina",
	"Velká Británie",
	"Spojené státy americké",
	"Ostatní"
];
export const stateEnum: String[] = [
	"Česká republika",
	"Slovensko",
	"Afghánistán",
	"Albánie",
	"Alžírsko",
	"Andorra",
	"Angola",
	"Ázerbájdžán",
	"Argentina",
	"Austrálie",
	"Rakousko",
	"Bangladéš",
	"Arménie",
	"Belgie",
	"Bermudy",
	"Bosna a Hercegovina",
	"Brazílie",
	"Belize",
	"Bulharsko",
	"Bělorusko",
	"Kambodža",
	"Kamerun",
	"Kanada",
	"Chile",
	"Čína",
	"Tchaj-wan",
	"Kolumbie",
	"Konžská Republika",
	"Demokratická republika Kongo",
	"Kostarika",
	"Chorvatsko",
	"Kuba",
	"Kypr",
	"Dánsko",
	"Ekvádor",
	"Etiopie",
	"Estonsko",
	"Finsko",
	"Francie",
	"Gabon",
	"Gruzie",
	"Německo",
	"Řecko",
	"Hongkong",
	"Maďarsko",
	"Island",
	"Indie",
	"Indonésie",
	"Írán",
	"Irák",
	"Irsko",
	"Izrael",
	"Itálie",
	"Japonsko",
	"Kazachstán",
	"Jordánsko",
	"Keňa",
	"Korejská republika",
	"Kuvajt",
	"Kyrgyzstán",
	"Laos",
	"Libanon",
	"Lotyšsko",
	"Libérie",
	"Libye",
	"Lichtenštejnsko",
	"Litva",
	"Lucembursko",
	"Malajsie",
	"Malta",
	"Mexiko",
	"Mongolsko",
	"Moldavsko",
	"Černá Hora",
	"Maroko",
	"Nepál",
	"Nizozemsko",
	"Nový Zéland",
	"Nikaragua",
	"Niger",
	"Nigérie",
	"Norsko",
	"Pákistán",
	"Panama",
	"Papua Nová Guinea",
	"Paraguay",
	"Peru",
	"Filipíny",
	"Polsko",
	"Portugalsko",
	"Portoriko",
	"Katar",
	"Rumunsko",
	"Rusko",
	"Saúdská Arábie",
	"Srbsko",
	"Singapur",
	"Vietnam",
	"Slovinsko",
	"Somálsko",
	"Jihoafrická republika",
	"Zimbabwe",
	"Španělsko",
	"Jižní Súdán",
	"Súdán",
	"Súdán",
	"Švédsko",
	"Švýcarsko",
	"Sýrie",
	"Tádžikistán",
	"Thajsko",
	"Spojené arabské emiráty",
	"Tunisko",
	"Turecko",
	"Turkmenistán",
	"Uganda",
	"Ukrajina",
	"Makedonie",
	"Egypt",
	"Velká Británie",
	"Tanzanie",
	"Spojené státy americké",
	"Uruguay",
	"Uzbekistán",
	"Venezuela",
	"Jemen",
	"Jugoslávie",
	"Zambie",
	"Stát Palestina",
	"Pobřeží slonoviny",
	"Mauretánie",
	"Ostatní"
];

export const healthInsuranceEnum: String[] = [
	"111 - Všeobecná zdravotní pojišťovna ČR",
	"201 - Vojenská zdravotní pojišťovna ČR",
	"205 - Česká průmyslová zdravotní pojišťovna",
	"207 - Oborová zdravotní poj. zam. bank, poj. a stav.",
	"209 - Zaměstnanecká pojišťovna Škoda",
	"211 - Zdravotní pojišťovna ministerstva vnitra ČR",
	"213 - Revírní bratrská pokladna, zdrav. pojišťovna",
	"999 - Jiná"
];

export const closePersonEnum: String[] = [
	"příbuzní v řadě přímé - rodiče, prarodiče, děti",
	"příbuzní v řadě pobočné – sourozenci",
	"manžel/manželka",
	"partner/partnerka",
	"jiní blízcí rodinní příslušníci",
	"osoby v opatrovnictví zaměstnance či jeho manžela/manželky",
	"osoba žijící se zaměstnancem ve společné domácnosti",
	"osoba, k níž má zaměstnanec intenzivní vztah/poměr"
];

export enum QuestionaireType {
  PERSONAL = 1,
  SATISFACTION = 2
}

export const typeOfDocument: String[] = [
	"občanský průkaz",
	"cestovní pas",
	"řidičský průkaz"
]