import { NgModule, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { LanguageModule } from '@cz.kb.fe.lib/lib-i18n-ng2';

import { TitleComponent } from './title.component';

import { BreadcrumbModule } from './breadcrumb/breadcrumb.module';
import { ContactCenterModule } from "./contact-center/contact-center.module";
import { FileDownloadLinkModule } from './file-download-link/file-download-link.module';
import { HrFileUploadModule } from './file-upload/hr-file-upload.module';
import { CommunicationMessageModule } from './communication-message/communication-message.module';

import { YourDocumentService } from './your-documents.service';
import { QuestionaryService } from './questionary.service';
import { LoggedUserService } from './logged-user.service';

@NgModule({
  imports: [
    CommonModule,
    LanguageModule,
    BreadcrumbModule,
    ContactCenterModule,
    FileDownloadLinkModule,
    HrFileUploadModule,
    CommunicationMessageModule
  ],
  declarations: [
    TitleComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    LanguageModule,
    TitleComponent,
    BreadcrumbModule,
    ContactCenterModule,
    FileDownloadLinkModule,
    HrFileUploadModule,
    CommunicationMessageModule
  ],
  providers: [
    YourDocumentService,
    QuestionaryService,
    LoggedUserService
  ]
})
export class SharedModule { }
