import { Component, TemplateRef } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { UserService, Employee } from '../../user/user.service';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';

@Component({
  selector: 'ob-onboarding-documents',
  templateUrl: 'onboarding-documents.component.html',
})
export class OnboardingDocumentsComponent {

  recruiter: Employee;

  modalRef: BsModalRef;
  company: string;

  constructor(
    protected userService: UserService,
    private modalService: BsModalService
  ) {};

  ngOnInit() {
    this.getRecruiter();
    this.company = this.userService.getUserStorage().userDetail.company;
  }

  isRequired(docType: string): boolean {
    return this.userService.isRequired(docType);
  }

  typeExist(docType: string): boolean{
    return this.userService.typeExist(docType);
  }

  getTitle(docType: string): string{
    console.log(this.userService.getFileDescription(docType));
    return this.userService.getFileDescription(docType);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  closeModal() {
    this.modalRef.hide();
  }

  getRecruiter() {
    this.recruiter = this.userService.getRecruiter();
  }

  downloadAll() {
    window.open(Configuration.get('d60.apiUrl', true) + `/users/${this.userService.getUid()}/files`);
  }

}
