import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common'
import { NavbarComponent } from './navbar.component';
import { CommunicationModule } from './communication';
import { NavbarDropdownModule } from './navbar-dropdown/navbar-dropdown.module';
import { NavbarDropdownHeaderModule } from './navbar-dropdown/navbar-dropdown-header/navbar-dropdown-header.module';

import { RouterModule } from '@angular/router';

import { MenuService } from '../shared/menu.service';
import { CommunicationMessageModule } from '../shared/communication-message/communication-message.module';

@NgModule({
  imports: [
  	RouterModule,
  	CommunicationModule,
    CommonModule,
    NavbarDropdownModule,
    NavbarDropdownHeaderModule,
    CommunicationMessageModule
  ],
  declarations: [
    NavbarComponent,
  ],
  exports: [
    NavbarComponent,
  ],
  providers: [
    MenuService
  ]
})
export class NavbarModule { }
