import { NgModule } from '@angular/core';
import { ResponsiveTileAccordionComponent } from './responsive-tile-accordion.component';

import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    ResponsiveTileAccordionComponent,
  ],
  exports: [
    ResponsiveTileAccordionComponent,
  ],
  providers: []
})
export class ResponsiveTileAccordionModule { }
