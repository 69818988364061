import { Component, OnInit, Output/*, EventEmitter*/  } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';

import { anyRequiredValidator } from '../shared/validators';
import { LoginService, UserLogInfo } from './login.service';


@Component({
  selector: 'ob-login',
  templateUrl: 'login.component.html',
})
export class LoginComponent {

    // @Output() loggedIn = new EventEmitter<string>();
    // @Output() loggedOut = new EventEmitter<any>();

    email =  new FormControl(undefined ,[Validators.required, Validators.email]);
    password =  new FormControl(undefined ,[Validators.required]);
    loginSuccess = true;
    isSubmitting: boolean = false;
    submitted: boolean = false;

    showEnv: boolean = false;
    envName: string = "none";
    envColor: string = "#ffffff";

    constructor (
      protected loginService: LoginService,
      private router: Router ) {}

    user : UserLogInfo;

    form = new FormGroup({
      email : this.email,
      password : this.password,
    }, Validators.required);

    ngOnInit(){
      this.loginService.logout();
      // this.loggedIn.emit(null);
      this.email.setValue('');
      this.password.setValue('');
      //this.loginService.clearStorages();

      var url = window.location.href;
      if(url.includes("localhost")){
        this.showEnv = true;
        this.envName = "LOCALHOST";
        this.envColor = "#ff00ff";
      }
      if(url.includes(".fat.")){
        this.showEnv = true;
        this.envName = "FAT";
        this.envColor = "#66ff66";
      }
      if(url.includes(".stage.")){
        this.showEnv = true;
        this.envName = "STAGE";
        this.envColor = "#66ccff";
      }
    };

    keyDownFunction(event) {
      if(event.keyCode == 13) {
         this.onSubmit()
      }
    }

    onSubmit() {
     // console.log('this.form.value',  this.form.value);
      this.isSubmitting = true;
      this.submitted = true;
      if (this.form.valid) {
        this.user = {
          name: '',
          email: this.email.value,
          password: this.password.value,
          rolesInDB: ''
        }
        this.loginService.postLogInfo(this.user)
          .subscribe(
            res => {
             // console.log('subscribed res:', res);
              if(res.status==200){
                // console.log('res.status==200');

                this.loginSuccess = true;
                this.router.navigate(['/']);
              }
              else{
                // console.log('res.status!=200', res.status);
                this.loginSuccess = false;
                this.isSubmitting = false;
              }
            },
            err => {
              // console.error('An error occurred:', err.error);
              this.loginSuccess = false;
              this.isSubmitting = false;
            }
          );
      }
      else {
        this.isSubmitting = false;
      }
    }

}
