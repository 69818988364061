import { Component, TemplateRef } from '@angular/core';
import { UserService } from '../../../user/user.service';

@Component({
  selector: 'ob-fun',
  templateUrl: 'fun.component.html',
})
export class FunComponent {
  activeTab: string;
  company:string = '';
  showKBOnly: boolean = true;
  constructor(
    protected userService: UserService
  ){}

  changeActiveTab(tab: string) {
  	if(this.activeTab == tab) {
  		this.activeTab = '';
  	} else {
  		this.activeTab = tab;
  	}
  }

  ngOnInit() {
  this.company = this.userService.getUserStorage().userDetail.company;
  if('KB'===this.company){
    this.showKBOnly = true;
  }else{
    this.showKBOnly = false;
  }
}
}
