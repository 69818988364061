import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared';

import { LifeInKbComponent } from './life-in-kb.component';

@NgModule({
  imports: [
  	RouterModule,
    SharedModule,
  ],
  declarations: [
    LifeInKbComponent,
  ],
  exports: [
    LifeInKbComponent,
  ]
})
export class LifeInKbModule { }
