import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { FileDownloadLinkComponent } from './file-download-link.component';

@NgModule({
  imports: [ BrowserModule, CommonModule ],
  declarations: [ FileDownloadLinkComponent ],
  exports: [
    FileDownloadLinkComponent ]
})
export class FileDownloadLinkModule {
}
