import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'ob-navbar-dropdown',
  templateUrl: 'navbar-dropdown.component.html',
})
export class NavbarDropdownComponent implements OnInit, OnDestroy {
	@Input() size: string;
	@Input() position: string;
	@Output() clickedOutside = new EventEmitter;
	@ViewChild('dropdown') container;

	ngOnInit() {
		//TODO : prostor pro refactor - bez timeoutu zachytne outsideClick jako ten kterym se menu otevira
		setTimeout(() => {
	        document.addEventListener('click', this.onClickedOutside);
	    }, 0);
	}

	ngOnDestroy() {
		document.removeEventListener('click', this.onClickedOutside);
	}

	onClickedOutside = (event?:any) => {
		if (!this.container.nativeElement.contains(event.target)) {
			this.clickedOutside.emit(event);
		}
	}
}
