<footer class="footer">
	<div class="container">
		<div class="row">
				<div *ngFor="let menu of menuBlock"
					class="footer__col col-md-4 col-lg-3 text-center text-md-left">
					<h6 class="footer__title montserat-medium u-text--red uppercase">{{menu.name}}</h6>
					<ul *ngIf="menu.submenu" class="footer-nav list-unstyled mb-0 text-center text-md-left">
						<li *ngFor="let item of menu.submenu" class="footer-nav__item"><a class="underline link--grey"
								routerLink="{{item.url}}" routerLinkActive="active">{{item.name}}</a></li>
					</ul>
				</div>
			<div *ngIf="company == 'KB'" class="footer__col col-md-4 col-lg-3 offset-lg-3 text-center text-md-left">
				<h6 class="footer__title montserat-medium u-text--red uppercase">Sledujte nás na</h6>
				<ul class="footer-social__list list-unstyled text-center text-md-left">
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.facebook.com/komercni.banka/" target="_blank">
							<!-- facebook -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.linkedin.com/company/komercni-banka" target="_blank">
							<!-- linkedin -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M12 12h5.535v2.837h.08c.77-1.38 2.654-2.837 5.463-2.837C28.92 12 30 15.637 30 20.367V30h-5.77v-8.54c0-2.037-.04-4.657-3-4.657-3.005 0-3.463 2.218-3.463 4.51V30H12V12zM2 12h6v18H2V12zM8 7c0 1.657-1.343 3-3 3S2 8.657 2 7s1.343-3 3-3 3 1.343 3 3z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://twitter.com/komercka" target="_blank">
							<!-- twitter -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M32 7.075c-1.175.525-2.444.875-3.77 1.03 1.357-.812 2.395-2.1 2.888-3.63-1.27.75-2.675 1.3-4.17 1.594C25.75 4.793 24.044 4 22.156 4c-3.625 0-6.563 2.938-6.563 6.563 0 .512.056 1.012.17 1.494C10.304 11.782 5.466 9.17 2.23 5.195c-.563.97-.887 2.1-.887 3.3 0 2.275 1.156 4.287 2.92 5.463-1.076-.03-2.088-.33-2.976-.82v.082c0 3.18 2.263 5.838 5.27 6.437-.55.15-1.132.23-1.732.23-.425 0-.83-.043-1.237-.118.838 2.605 3.263 4.505 6.13 4.562-2.25 1.762-5.074 2.813-8.155 2.813-.53 0-1.05-.03-1.57-.094C2.908 28.92 6.357 30 10.064 30c12.075 0 18.68-10.005 18.68-18.68 0-.287-.005-.57-.018-.85 1.28-.92 2.394-2.075 3.275-3.394z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.instagram.com/komercka" target="_blank">
							<!-- instagram -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="169.063" height="169.063" viewBox="0 0 169.063 169.063">
								<path
									d="M122.406 0H46.654C20.93 0 0 20.93 0 46.655v75.752c0 25.726 20.93 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.062 20.93 148.132 0 122.406 0zm31.657 122.407c0 17.455-14.2 31.655-31.656 31.655H46.654C29.2 154.062 15 139.862 15 122.407V46.655C15 29.2 29.2 15 46.654 15h75.752c17.455 0 31.656 14.2 31.656 31.655v75.752z" />
								<path
									d="M84.53 40.97c-24.02 0-43.562 19.542-43.562 43.563 0 24.02 19.542 43.56 43.563 43.56s43.564-19.54 43.564-43.56c0-24.02-19.542-43.563-43.563-43.563zm0 72.123c-15.748 0-28.562-12.812-28.562-28.56 0-15.75 12.813-28.564 28.563-28.564s28.564 12.812 28.564 28.562c0 15.75-12.814 28.56-28.563 28.56zM129.92 28.25c-2.89 0-5.728 1.17-7.77 3.22-2.05 2.04-3.23 4.88-3.23 7.78 0 2.892 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.youtube.com/user/komercnibanka" target="_blank">
							<!-- youtube -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M31.68 9.6s-.312-2.206-1.274-3.175c-1.22-1.275-2.58-1.28-3.206-1.356-4.475-.326-11.194-.326-11.194-.326h-.012s-6.72 0-11.194.325c-.625.074-1.987.08-3.206 1.355C.63 7.395.324 9.6.324 9.6s-.318 2.588-.318 5.18v2.426c0 2.587.32 5.18.32 5.18s.312 2.207 1.268 3.176c1.22 1.275 2.82 1.23 3.53 1.37 2.564.243 10.882.318 10.882.318s6.725-.012 11.2-.33c.625-.076 1.988-.082 3.206-1.357.962-.97 1.275-3.175 1.275-3.175s.32-2.587.32-5.18V14.78c-.007-2.588-.326-5.18-.326-5.18zM12.695 20.15v-8.994l8.644 4.513-8.644 4.48z" />
							</svg>
						</a>
					</li>
				</ul>
				<div class="my-4">
					<a class="link--white" target="_blank" href="https://www.kb.cz">www.kb.cz</a>
				</div>
			</div>

			<div *ngIf="company == 'MPSS'" class="footer__col col-md-4 col-lg-3 offset-lg-3 text-center text-md-left">
				<h6 class="footer__title montserat-medium u-text--red uppercase">Sledujte nás na</h6>
				<ul class="footer-social__list list-unstyled text-center text-md-left">
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://cs-cz.facebook.com/modrapyramida" target="_blank">
							<!-- facebook -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M19 6h5V0h-5c-3.86 0-7 3.14-7 7v3H8v6h4v16h6V16h5l1-6h-6V7c0-.542.458-1 1-1z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.linkedin.com/company/modrapyramida" target="_blank">
							<!-- linkedin -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M12 12h5.535v2.837h.08c.77-1.38 2.654-2.837 5.463-2.837C28.92 12 30 15.637 30 20.367V30h-5.77v-8.54c0-2.037-.04-4.657-3-4.657-3.005 0-3.463 2.218-3.463 4.51V30H12V12zM2 12h6v18H2V12zM8 7c0 1.657-1.343 3-3 3S2 8.657 2 7s1.343-3 3-3 3 1.343 3 3z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.instagram.com/modrapyramida/" target="_blank">
							<!-- instagram -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="169.063" height="169.063" viewBox="0 0 169.063 169.063">
								<path
									d="M122.406 0H46.654C20.93 0 0 20.93 0 46.655v75.752c0 25.726 20.93 46.655 46.654 46.655h75.752c25.727 0 46.656-20.93 46.656-46.655V46.655C169.062 20.93 148.132 0 122.406 0zm31.657 122.407c0 17.455-14.2 31.655-31.656 31.655H46.654C29.2 154.062 15 139.862 15 122.407V46.655C15 29.2 29.2 15 46.654 15h75.752c17.455 0 31.656 14.2 31.656 31.655v75.752z" />
								<path
									d="M84.53 40.97c-24.02 0-43.562 19.542-43.562 43.563 0 24.02 19.542 43.56 43.563 43.56s43.564-19.54 43.564-43.56c0-24.02-19.542-43.563-43.563-43.563zm0 72.123c-15.748 0-28.562-12.812-28.562-28.56 0-15.75 12.813-28.564 28.563-28.564s28.564 12.812 28.564 28.562c0 15.75-12.814 28.56-28.563 28.56zM129.92 28.25c-2.89 0-5.728 1.17-7.77 3.22-2.05 2.04-3.23 4.88-3.23 7.78 0 2.892 1.18 5.73 3.23 7.78 2.04 2.04 4.88 3.22 7.77 3.22 2.9 0 5.73-1.18 7.78-3.22 2.05-2.05 3.22-4.89 3.22-7.78 0-2.9-1.17-5.74-3.22-7.78-2.04-2.05-4.88-3.22-7.78-3.22z" />
							</svg>
						</a>
					</li>
					<li class="footer-social__list-item d-inline-block">
						<a class="footer-social__link d-inline-flex justify-content-center align-items-center u-svg-clickable border-radius--lg"
							href="https://www.youtube.com/user/ModraPyramida1" target="_blank">
							<!-- youtube -->
							<svg class="icon footer-social__icon icon--fill-white" xmlns="http://www.w3.org/2000/svg"
								width="32" height="32" viewBox="0 0 32 32">
								<path
									d="M31.68 9.6s-.312-2.206-1.274-3.175c-1.22-1.275-2.58-1.28-3.206-1.356-4.475-.326-11.194-.326-11.194-.326h-.012s-6.72 0-11.194.325c-.625.074-1.987.08-3.206 1.355C.63 7.395.324 9.6.324 9.6s-.318 2.588-.318 5.18v2.426c0 2.587.32 5.18.32 5.18s.312 2.207 1.268 3.176c1.22 1.275 2.82 1.23 3.53 1.37 2.564.243 10.882.318 10.882.318s6.725-.012 11.2-.33c.625-.076 1.988-.082 3.206-1.357.962-.97 1.275-3.175 1.275-3.175s.32-2.587.32-5.18V14.78c-.007-2.588-.326-5.18-.326-5.18zM12.695 20.15v-8.994l8.644 4.513-8.644 4.48z" />
							</svg>
						</a>
					</li>
				</ul>
				<div class="my-4">
					<a class="link--white" target="_blank" href="https://www.modrapyramida.cz">www.modrapyramida.cz</a>
				</div>
			</div>
		</div>
		<div class="mt-5 mb-3">
			<span class="u-text--white">(c) 2023 KB Group</span>
		</div>
	</div>
</footer>