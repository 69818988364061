import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { Component } from '@angular/compiler/src/core';

@Injectable()
export class PageDeactivateGuard implements CanDeactivate<Component> {
  private static key : string = "PageDeactivateGuard";

  private static getComponents() : string[] {
    let components: string[] = JSON.parse(localStorage.getItem(this.key));
    if (components == undefined || components == null ) {
      components = [];
    }
    return components;
  }

  public static registerComponent(component: string) {
    let components = this.getComponents();
    components.push(component);
    localStorage.setItem(this.key, JSON.stringify(components));
  }

  public static unregisterComponent(component: string) {
    let components = this.getComponents();
    let index = components.indexOf(component);
    components.splice(index, 1);
    localStorage.setItem(this.key, JSON.stringify(components));
  }

  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
      let index = PageDeactivateGuard.getComponents().indexOf(typeof component);
      console.log(index);
      return  index < 0;
    }  
}
