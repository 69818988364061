import { Component, OnInit, HostListener } from '@angular/core';
import { Questionary, maritalStatusEnum, nationalityEnum, stateEnum, healthInsuranceEnum, closePersonEnum, QuestionaireType, typeOfDocument, QuestionaryResponse } from '../../shared/questionary.model';

import { UserService } from '../../user/user.service';
import { QuestionaryService } from '../../shared/questionary.service';

import { MessageService } from '../../core/message/message.service';
import { rodnecislo } from 'rodnecislo';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';


@Component({
	selector: 'ob-questionary',
	templateUrl: 'questionary.component.html',
})
export class QuestionaryComponent {
	userUid: string;
	model = new Questionary();
	questionaire: QuestionaryResponse = undefined;
	birthDate: string;
	isFilled: boolean | undefined;
	isLoaded: boolean = false;
	codexUrl: string;
	informationSecurityUrl: string;
	company: string;


	maritalStatusEnum = maritalStatusEnum;
	nationalityEnum = nationalityEnum;
	nationalityEnumOther = nationalityEnum[nationalityEnum.length - 1]; // RESIT JINAK
	nationalityEnumCZE = nationalityEnum[0]; // RESIT JINAK
	stateEnum = stateEnum;
	stateEnumOther = stateEnum[stateEnum.length - 1];
	healthInsuranceEnum = healthInsuranceEnum;
	healthInsuranceEnumOther = healthInsuranceEnum[healthInsuranceEnum.length - 1];
	closePersonEnum = closePersonEnum;
	typesOfDocument = typeOfDocument;

	@HostListener('window:beforeunload')
	preventLostData() {
		return 'Opravdu chcete opustit dotazník bez uložení dat?';
	}

	constructor(
		protected userService: UserService,
		protected questionaryService: QuestionaryService,
		protected messageService: MessageService
	) { }

	ngOnInit() {
		this.userUid = this.userService.getUid();
		this.company = this.userService.getUserStorage().userDetail.company;
		this.questionaryService.getQuestionary(this.userUid).subscribe((res: QuestionaryResponse[]) => {
			let quest = res.find(i => i.type == QuestionaireType.PERSONAL);
			if (quest != undefined) {
				this.questionaire = quest;
				this.model = Object.assign(new Questionary(), quest.document);
			}
		});

		this.isFilled = this.userService.isFilled(QuestionaireType.PERSONAL);
		// this.questionaryService.getPersonalQuestionary(this.user.uid).subscribe((res:any) => {
		this.isLoaded = true;
		// });

		let docs = this.userService.getUserFiles('codex');
		switch (this.company) {
			case 'KB':
				docs = this.userService.getUserFiles('codex');
				break;
			case 'MPSS':
				docs = this.userService.getUserFiles('codex_MPSS');
				break;
			case 'ALD':
				docs = this.userService.getUserFiles('codex_ALD');
				break;
			case 'SGEF':
				docs = this.userService.getUserFiles('codex_SGEF');
				break;
			case 'KBPS':
				docs = this.userService.getUserFiles('codex_KBPS');
				break;
			default:
				docs = this.userService.getUserFiles('codex');
				break;
		}
		if (docs.length == 1) {
			this.codexUrl = this.getFileUrl(docs[0].uid);
		}
		docs = this.userService.getUserFiles('informationSecurity');
		switch (this.company) {
			case 'KB':
				docs = this.userService.getUserFiles('informationSecurity');
				break;
			case 'MPSS':
				docs = this.userService.getUserFiles('informationSecurity_MPSS');
				break;
			case 'ALD':
				docs = this.userService.getUserFiles('informationSecurity_ALD');
				break;
			case 'SGEF':
				docs = this.userService.getUserFiles('informationSecurity_SGEF');
				break;
			case 'KBPS':
				docs = this.userService.getUserFiles('informationSecurity_KBPS');
				break;
			default:
				docs = this.userService.getUserFiles('informationSecurity');
				break;
		}
		if (docs.length == 1) {
			this.informationSecurityUrl = this.getFileUrl(docs[0].uid);
		}
	}

	calculateBirthDate(birthNumber: string) {
		let rc = rodnecislo(birthNumber);
		if (rc.isValid()) {
			this.birthDate = rc.birthDateAsString();
		}
	}

	getFileUrl(fileUid: string) { return Configuration.get('d60.apiUrl', true) + '/files/' + fileUid }

	onSubmit(f) {

		if (f.invalid) return;

		let quest = {
			document: this.model,
			version: 0,
			uid: '',
			type: QuestionaireType.PERSONAL,
			completed: true
		}
		if (this.questionaire) {
			quest.uid = this.questionaire.uid;
		}
		else {
			delete quest.uid;
		}

		this.questionaryService.postQuestionary(this.userUid, JSON.stringify(quest)).subscribe(res => {
			this.messageService.success({
				title: 'Dotazník byl úspěšně odeslán',
				timeout: 15000
			});
			this.isFilled = true;
		}, err => {
			this.messageService.error({
				title: 'Dotazník se nepodařilo odeslat',
				timeout: 15000,
				error: err
			});
		});
	}
}
