<div class="container my-5">
	<ob-breadcrumb pageName="Lékařská prohlídka"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase mt-3">PRACOVNĚLÉKAŘSKÁ PROHLÍDKA</h1>
	<p class="h6-responsive my-3"><b>Před nástupem od Vás potřebujeme vyplnit, dodat a/nebo naskenovat (ve formě scanu nebo kvalitní/čitelné fotografie z mobilu) několik dokumentů.</b></p>
	<ob-process-point [processed]="isUploaded('medicalExamination')" [number]="getPoint('medicalExamination')" *ngIf="isShown('medicalExamination')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">ZÁVĚR LÉKAŘSKÉ PROHLÍDKY</h2>
		<p><b>Sem prosím nahrajte kopii potvrzeného pracovnělékařského posudku ve formě scanu nebo kvalitní/čitelné fotografie z mobilu.</b></p>
		<app-file-upload documentType="medicalExamination" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('medicalExaminationPayment')" [number]="getPoint('medicalExaminationPayment')" *ngIf="isShown('medicalExaminationPayment')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">DOKLAD O ZAPLACENÍ OD PRAKTICKÉHO LÉKAŘE</h2>
		<p><b>Váš praktický lékař může požadovat poplatek za provedení pracovnělékařské prohlídky (případně poplatek za výpis ze zdravotní dokumentace). Prosíme nahrajte doklad o zaplacení zde, KB Vám výdaj proplatí ve mzdě.</b></p>
		<app-file-upload documentType="medicalExaminationPayment" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<h2 class="h2-responsive montserat-black mt-5 mb-5"><span class="text-uppercase">JAK SE NECHAT VYŠETŘIT</span>/základní kategorie
		<h6 style="font-style:italic;">(netýká se SG Equipment Finance)</h6>
	</h2>
	<ul>
		<li>Na prohlídku se objednejte ke svému praktickému lékaři.</li>
		<li>Na prohlídku si nezapomeňte s sebou vzít vzorek první ranní moči ve zkumavce či menší nádobě (vyplývá z vyhlášky č. 79/2013 (§ 7 obsah pracovnělékařské prohlídky) a případně oční korekční pomůcky (brýle, čočky).</li>
		<li>Vezměte k lékaři 2x vytištěnou žádost (přijde Vám emailem od Vašeho recruitera), do které na základě vyšetření a dalších náležitostí lékař potvrdí posudky.</li>
		<li>Poplatek za provedení prohlídky uhradíte přímo u Vašeho praktického lékaře.</li>
	</ul>

	<h2 class="h2-responsive montserat-black mt-5 mb-5"><span class="text-uppercase">JAK SE NECHAT VYŠETŘIT</span>/poskytovatel pracovnělékařské péče 
		<h6 style="font-style:italic;">(týká se převážně nočních prací, řidičů apod.)</h6>
		<h6 style="font-style:italic;">(týká se SG Equipment Finance)</h6>
	</h2>
	<ul>
		<li>Pro výpis ze zdravotnické dokumentace zavolejte svému praktickému lékaři.</li>
		<li>Na prohlídku se objednejte dle instrukcí od náborářek poskytovateli pracovně lékařské péče.</li>
		<li>Na prohlídku si nezapomeňte s sebou vzít vzorek první ranní moči ve zkumavce či menší nádobě (vyplývá z vyhlášky č. 79/2013 (§ 7 obsah pracovnělékařské prohlídky) a případně oční korekční pomůcky (brýle, čočky).</li>
		<li>Vezměte k lékaři 4x vytištěnou žádost (přijde Vám emailem od Vašeho recruitera), do které na základě vyšetření a dalších náležitostí lékař potvrdí posudky.</li>
		<li>U poskytovatele PLP žádné poplatky za provedení prohlídky neplatíte.</li>
	</ul>

	<h2 class="h2-responsive montserat-black mt-5 mb-5">ZÁVĚR</h2>
	<ul>
		<li>Scan lékařského posudku a doklad o zaplacení od praktického lékaře (příp. kvalitní fotografii pořízenou z mobilního telefonu) prosím nahrajte do onboardingové aplikace KB. Náklady spojené s pořízením lékařského posudku/výpisu Vám budou proplaceny spolu se mzdou.</li>
		<li>Originál potvrzeného lékařského posudku a originál dokladu o zaplacení prohlídky si prosím ponechejte pro případnou kontrolu.</li>
	</ul>
</div>
