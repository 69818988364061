import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicationComponent } from './communication.component';

import { RouterModule } from '@angular/router';

import { CommunicationListModule } from './communication-list/communication-list.module';
import { CommunicationMessageModule } from '../../shared/communication-message/communication-message.module';

@NgModule({
  imports: [
  	RouterModule,
  	CommonModule,
    CommunicationListModule,
    CommunicationMessageModule
  ],
  declarations: [
    CommunicationComponent,
  ],
  exports: [
    CommunicationComponent,
  ]
})
export class CommunicationModule { }
