import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared';
import { ResponsiveTileAccordionModule } from '../../../shared/responsive-tile-accordion';
import { ResponsiveTileAccordionTriggerModule } from '../../../shared/responsive-tile-accordion/responsive-tile-accordion-trigger/responsive-tile-accordion-trigger.module';

import { FunComponent } from './fun.component';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    ResponsiveTileAccordionModule,
    ResponsiveTileAccordionTriggerModule
  ],
  declarations: [
    FunComponent,
  ],
  exports: [
    FunComponent,
  ]
})
export class FunModule { }
