import { Component, OnInit, Input } from '@angular/core';

import { Location } from '@angular/common';
import { HistoryService } from '../../core/history/history.service';

import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ob-breadcrumb',
  templateUrl: 'breadcrumb.component.html',
})
export class BreadcrumbComponent {
  @Input() pageName: string = '';

	constructor(
		private historyService: HistoryService,
		private _location: Location,
    private route: ActivatedRoute,
    private router: Router,
		) {}

  navigateBack(event?: any): void {
    if (event) event.preventDefault();
    if (this.isNavigableBack) {
      this._location.back();
    } else {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
  get isNavigableBack() {
    return this.historyService.canGoBack;
  }
}
