import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'ob-responsive-tile-accordion-trigger',
  templateUrl: 'responsive-tile-accordion-trigger.component.html',
})
export class ResponsiveTileAccordionTriggerComponent{
  @Input() isActive: boolean = false;
  @Input() wrapperClasses: string;
  @Output() clickEvent = new EventEmitter;

  emitClickEvent(event?: any) {
  	event.preventDefault();
    this.clickEvent.emit(event);
  }
}
