import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'ob-process-point',
  templateUrl: 'process-point.component.html',
})
export class ProcessPointComponent {

  @Input() processed: boolean = false;
  @Input() wrapperClasses: string = 'mb-8';
  @Input() number: number;

}
