import { Component, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../user/user.service';

@Component({
  selector: 'ob-communication',
  templateUrl: 'communication.component.html',
})
export class CommunicationComponent {
  selectedPerson: object = null;
  
  @Output() onAfterSend = new EventEmitter;

  constructor (protected userService: UserService){}

  showWriteDialog(person: object) {
    this.selectedPerson = person;
  }

  afterSend() {
    this.onAfterSend.emit(null);
  }
}
