<div class="container my-5">
	<ob-breadcrumb pageName="KB GROUP V ČÍSLECH"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase">KB Group V ČÍSLECH</h1>
	<ul class="navigation-switcher nav nav-tabs" role="tablist">
		<li class="nav-item">
			<a href="." (click)="changeTab(0, $event)" [class.active]="activeTab == 0" class="navigation-switcher__tab uppercase montserat-black text-nowrap" data-toggle="tab"> FAKTA A VÝSLEDKY KB Group</a>
		</li>
		<li class="nav-item">
			<a href="." (click)="changeTab(1, $event)" [class.active]="activeTab == 1" class="navigation-switcher__tab uppercase montserat-black text-nowrap " data-toggle="tab"> ZAMĚSTNANCI V KB Group</a>
		</li>
	</ul>
	<div class="tab-content mt-3">
        <div id="tab-a" [ngClass]="{'active show': activeTab == 0}" role="tabpanel" class="tab-pane fade">
            <div class="bg-white p-3 px-md-10 py-md-6 lifted-shadow-wrap mb-8">
				<div class="row flex-md-row text-center text-md-left align-items-center justify-content-center">
					<div class="col-12 col-md-4 text-md-right">
						<span class="h5-responsive montserat-black u-text--red">VÝSLEDKY, NA KTERÉ JSME HRDÍ</span>
					</div>
					<div class="col-12 col-md-auto d-none d-md-block">
						<!-- arrow-right -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" width="55" height="130" viewBox="0 0 55 130"><path d="M5.14 130l-1.55-1.23 48.85-62.7L.94 1.24 2.47 0l52.48 66.06z"/></svg>
					</div>
					<div class="col-12 col-md mt-3 mt-md-0">
						<div class="h4-responsive montserat-black">KAŽDÝ PÁTÝ ČECH JE NAŠÍM KLIENTEM.</div>
					</div>
				</div>
				<div class="lifted-shadow"></div>
			</div>
			<div class="row text--md">
				<h2 class="h4-responsive montserat-black col-12 col-md-4 text-right text-uppercase">KLIENTI A SLUŽBY</h2>
				<div class="col-12 col-md-8">
					<div class="red-border--left py-1" [innerHTML]="content_clients.content">
						<p>Máme <b class="montserat-black">1,6 miliónu klientů</b> a máme je rádi. Proto jim pomáháme plnit jejich sny například prostřednictvím <b class="montserat-black text--lg">606,1 miliónu úvěrů</b>, které jsme jim poskytli v roce 2018.</p>
					</div>
				</div>
			</div>
			<div class="row mt-5 text--md">
				<h2 class="h4-responsive montserat-black col-12 col-md-4 text-right text-uppercase">UNIVERZÁLNÍ MULTIKANÁLOVÁ BANKA</h2>
				<div class="col-12 col-md-8">
					<div class="red-border--left py-1" [innerHTML]="content_main.content">
						<p>U nás si vybere každý. Široké spektrum produktů nabízíme prostřednictvím bankovních poradců na našich pobočkách. Pokud klienti preferují pohodlí domova, stačí si produkty sjednat online nebo po telefonu.</p>
						<p><b class="montserat-black">1,6 miliónu</b> vydaných platebních karet</p>
						<p><b class="montserat-black">772</b> bankomatů</p>
						<p><b class="montserat-black">339</b> poboček</p>
						<p><b class="montserat-black">43 %</b> klientů má internetové bankovnictví</p>
						<p><b class="montserat-black">30 %</b> klientů má mobilní bankovnictví</p>
					</div>
				</div>
			</div>
			<div class="row mt-5 text--md">
				<h2 class="h4-responsive montserat-black col-12 col-md-4 text-right text-uppercase">Skupina</h2>
				<div class="col-12 col-md-8">
					<div class="red-border--left py-1">
						<p>Nejsme v tom sami! V zádech nám stojí silná <b class="montserat-black">mezinárodní skupina Société Générale</b>. V ČR a na Slovensku máme už desítku dceřiných a přidružených společností.</p>
					</div>
				</div>
			</div>
        </div>
        <div id="tab-b" [ngClass]="{'active show': activeTab == 1}" role="tabpanel" class="tab-pane fade ">
            <div class="bg-white p-3 px-md-10 py-md-6 lifted-shadow-wrap mb-8">
				<div class="row flex-md-row text-center text-md-left align-items-center justify-content-center">
					<div class="col-12 col-md-4 text-md-right">
						<span class="h5-responsive montserat-black u-text--red">KDO JSME, ANEB TROCHU JINÝ POHLED NA NÁS</span>
					</div>
					<div class="col-12 col-md-auto d-none d-md-block">
						<!-- arrow-right -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" width="55" height="130" viewBox="0 0 55 130"><path d="M5.14 130l-1.55-1.23 48.85-62.7L.94 1.24 2.47 0l52.48 66.06z"/></svg>
					</div>
					<div class="col-12 col-md mt-3 mt-md-0">
						<div class="h4-responsive montserat-black">V KOMERČNÍ BANCE PRACUJE VÍCE NEŽ 7,5 TISÍCE SKVĚLÝCH ZAMĚSTNANCŮ.</div>
					</div>
				</div>
				<div class="lifted-shadow"></div>
			</div>
			<div class="row text--md">
				<h2 class="h4-responsive montserat-black col-12 col-md-4 text-right text-uppercase">DIVERZITA</h2>
				<div class="col-12 col-md-8">
					<div class="red-border--left py-1" [innerHTML]="content_div.content">
						<p>Ženy u nás vedou...</p>
						<p>... celkový podíl mužů a žen v KB je <b class="montserat-black">36 %</b> vs. <b class="montserat-black">64 %</b>.</p>
						<p>Ženy nás i vedou...</p>
						<p>... podíl žen na vedoucích pozicích včetně vedoucích týmů je <b class="montserat-black">46 %</b>.</p>
						<br>
						<p>Nejčastěji u nás potkáte <b class="montserat-black">Janu</b> nebo <b class="montserat-black">Petra</b>.</p>
						<p>Pracují u nás <b class="montserat-black">celé rodiny</b>.</p>
					</div>
				</div>
			</div>
			<div class="row text--md mt-5">
				<h2 class="h4-responsive montserat-black col-12 col-md-4 text-right text-uppercase">NENÍ PRŮMĚR JAKO PRŮMĚR</h2>
				<div class="col-12 col-md-8">
					<div class="red-border--left py-1" [innerHTML]="content_avg.content">
						<p>Průměrně je nám <b class="montserat-black">43 let</b>. Nejmladšímu z nás je <b class="montserat-black">19 let</b> a nejstaršímu <b class="montserat-black">69 let</b>.</p>
						<p>Průměrně pracujeme v bance <b class="montserat-black">13 let</b>, ale máme i rekordmany…</p>
						<p>a někteří tu pracují i déle než <b class="montserat-black">40 let</b>.</p>
					</div>
				</div>
			</div>
			<div class="row text--md mt-5">
				<h2 class="h4-responsive montserat-black col-12 col-md-4 text-right text-uppercase">FLEXIBILITA</h2>
				<div class="col-12 col-md-8">
					<div class="red-border--left py-1" [innerHTML]="content_flexi.content">
						<p>Jsme zaměstnavatel na dlouhou trať. Snažíme se Vám vyjít vstříc v různých životních situacích.</p>
						<br/>
						<p><b class="montserat-black">94 %</b> zaměstnanců pracuje na plný pracovní úvazek</p>
						<p><b class="montserat-black">6 %</b> zaměstnanců má zkrácený pracovní úvazek</p>
						<p><b class="montserat-black">95 %</b> zaměstnanců může využívat homeoffice</p>
					</div>
				</div>
			</div>
        </div>
    </div>
</div>