import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MessageService } from './message.service';
import { MessageComponent } from './message.component';
import { MessageTypeAliasPipe } from './message-type-alias.pipe';

@NgModule({
  imports: [ CommonModule ],
  declarations: [ MessageComponent, MessageTypeAliasPipe ],
  exports: [ MessageComponent, MessageTypeAliasPipe ],
  providers: [ MessageService ]
})
export class MessageModule { }
