import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommunicationListComponent } from './communication-list.component';

import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
  	RouterModule,
  	CommonModule
  ],
  declarations: [
    CommunicationListComponent,
  ],
  exports: [
    CommunicationListComponent,
  ]
})
export class CommunicationListModule { }
