import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { FileLikeObject, FileUploader, FileUploaderOptions } from 'ng2-file-upload';

import { LoginService } from '../login/login.service';
import { UserService } from '../user/user.service';

@Injectable()
export class YourDocumentService {
  private url: string;
  protected model = Document;
  protected filterName = 'DocumentFilter';
  public maxFileSize: number = 100000000; //FIXME: set real value
  public maxFileSizeMB: number = this.maxFileSize / 1000000;
  public fileAlias: string = 'uploadedFile';
  public allowedFileType: string[] = [
    'pdf',
    'tiff',
    'tif',
    'png',
    'jpg',
    'jpeg',
    'doc',
    'docx',
    'ppt',
    'pptx',
    'odt',
    'odp',
    'txt',
    'msg',
    'xls',
    'xlsx',
    'ods',
    'gif'
  ];
  documentContentUrlHeaders: string;

  constructor(
    protected http: Http,
    protected loginService: LoginService,
    protected userService: UserService
  ) {
    const uid = userService.getUid();
    // TODO URL from config
    // this.url = Configuration.get('api.documentsUrl', true);
    this.url = Configuration.get('d60.apiUrl', true) + `/users/${uid}/documents`
  }


  public uploadDocuments(uploader: FileUploader) {
    // debugger;
    let uploaderHeaders = [
      // { name: 'Content-Type', value: 'multipart/form-data' },
      { name: 'Accept', value: 'application/json' }
    ]
    uploader.setOptions({
      url: this.url,
      headers: uploaderHeaders,
    });
    // uploader.queue[0].withCredentials = false;
    uploader.uploadAll();
  }

  public validFileType(item: FileLikeObject, options: FileUploaderOptions, allowedExtensions: string[] = []): boolean {
    let chunks = item.name.split('.');
    if (chunks.length < 2) return false;
    let extension = chunks[chunks.length - 1].toLowerCase();
    if (allowedExtensions.length > 0) {
      return allowedExtensions.indexOf(extension) !== -1;
    }

    return this.allowedFileType.indexOf(extension) !== -1;
  }

}
