import { Component, OnInit } from '@angular/core';

import { UserService, User } from '../../user/user.service';
import { QuestionaryService } from '../../shared/questionary.service';
import { QuestionaireType } from '../../shared/questionary.model';

import * as moment from 'moment';

@Component({
  selector: 'ob-onboarding-info',
  templateUrl: 'onboarding-info.component.html',
})
export class OnboardingInfoComponent {
  user: User;
  firstDateInWork: Date;
  daysToStart: number;
  signDate: Date;
  daysToSign: number;
  mapURI: string;
  isPersonalQuestionaryLoaded: boolean = false;
  isDocumentsUploaded: boolean = false;
  isPersonalQuestionaryFilled: boolean | undefined = false;

  constructor(
    protected userService: UserService,
    protected questionaryService: QuestionaryService
   ) {}
  ngOnInit() {
  	this.user = this.userService.getUserStorage();
    // console.log(this.user)
  	if(!this.user) console.log('WARN: user not loaded');

    // this.questionaryService.getPersonalQuestionary(this.user.uid).subscribe((res:any) => {
      this.isPersonalQuestionaryLoaded = true;
    // });

    this.isPersonalQuestionaryFilled = this.isPersonalQuestionaireFilled();

    this.firstDateInWork = moment(this.user.userDetail.firstDateInWork).toDate();
    this.daysToStart = this.differenceToToday(this.user.userDetail.firstDateInWork, 'YYYY-MM-DD');

    if (this.user.userDetail.signDate) {
      this.signDate = moment(this.user.userDetail.signDate).toDate();
      this.daysToSign = this.differenceToToday(this.user.userDetail.signDate, 'YYYY-MM-DD hh:mm:ss');
    }
    else {
      this.signDate = null;
      this.daysToSign = null;
    }
    
  	this.mapURI = this.user.userDetail.signAddress != null ? encodeURI(this.user.userDetail.signAddress) : undefined;


    this.isDocumentsUploaded =
        (!this.userService.isRequired('personalPhoto') || this.userService.isUploaded('personalPhoto')) &&
        (!this.userService.isRequired('diploma') || this.userService.isUploaded('diploma')) &&
        (!this.userService.isRequired('medicalExamination') || this.userService.isUploaded('medicalExamination')) &&
        (!this.userService.isRequired('medicalExaminationPayment') || this.userService.isUploaded('medicalExaminationPayment')) &&
        (!this.userService.isRequired('criminalRecord') || this.userService.isUploaded('criminalRecord')) &&
        (!this.userService.isRequired('certificate') || this.userService.isUploaded('certificate')) &&
        (!this.userService.isRequired('pension') || this.userService.isUploaded('pension')) &&
        (!this.userService.isRequired('disadvantage') || this.userService.isUploaded('disadvantage'));
  }

  differenceToToday(dateFrom: string, format: string) {
    moment.locale('cs');
    let nowWithTime = moment();
    let dateWithTime = moment.utc(dateFrom);
    let now = this.getOnlyDate(nowWithTime);
    let date = this.getOnlyDate(dateWithTime); 
  	let diff = date.diff(now, 'days');
  	return diff;
  }

  getOnlyDate(date: moment.Moment) {
    return moment().set('year', date.year()).set('month', date.month()).set('date', date.date()).startOf('day');
  }

  isPersonalQuestionaireFilled(): boolean | undefined {
    return this.userService.isFilled(QuestionaireType.PERSONAL);
  }

  getSignedName() {
    return this.user.userDetail.personalManager.deleted == true ?
              this.user.userDetail.recruiter.name :
              this.user.userDetail.personalManager.name;
  }
}
