import { NgModule } from '@angular/core';
import { NavbarDropdownHeaderComponent } from './navbar-dropdown-header.component';

@NgModule({
  imports: [
  ],
  declarations: [
    NavbarDropdownHeaderComponent,
  ],
  exports: [
    NavbarDropdownHeaderComponent,
  ]
})
export class NavbarDropdownHeaderModule { }
