import { Component, OnInit, Input, TemplateRef } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

import { UserService, UserDocument, StoredFile } from '../../user/user.service';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';

@Component({
  selector: 'app-file-download-link',
  templateUrl: './file-download-link.component.html'
})
export class FileDownloadLinkComponent implements OnInit {

  @Input() caption: string;
  @Input() docType: string;

  @Input() linkClass: string = 'link-box link-box--white-gray';
  @Input() contentClass: string = 'link-box__content';
  @Input() captionClass: string = 'mt-2 mt-md-3 link-box__caption';

//  documents: UserDocument[];
  documents: StoredFile[];


  modalRef: BsModalRef;

  constructor(
    protected userService: UserService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.getUserDocuments();
  }

  getUserDocuments() {
    // debugger;
    this.documents = this.userService.getUserFiles(this.docType)
  }

  computeUrl(uid?: string): string {
    return (uid) ? (Configuration.get('d60.apiUrl', true) + "/files/" + uid) : '';
  }

  open(template: TemplateRef<any>, event:any) {
    event.preventDefault();
    // debugger;
    if(!this.documents || this.documents.filter(function(d) { return !d.deleted; }).length == 0) {
      alert('Omlouváme se, dokument není k dispozici.');
    } else if(this.documents.length > 1) {
      this.modalRef = this.modalService.show(template);
    } else {
      window.open(Configuration.get('d60.apiUrl', true) + "/files/" + this.documents[0].uid);
    }
  }

  closeModal() {
    this.modalRef.hide();
  }

}
