import { Component } from '@angular/core';

import { UserService } from '../../user/user.service';

@Component({
  selector: 'ob-medical-examination',
  templateUrl: 'medical-examination.component.html',
})
export class MedicalExaminationComponent {

  pageModel: number[] = [];

  constructor(
    protected userService: UserService
  ) {};

  ngOnInit() {
    let point = 1;
    if(this.userService.isRequired('medicalExamination')) {
      this.pageModel['medicalExamination'] = point++;
    }
    if(this.userService.isRequired('medicalExaminationPayment')) {
      this.pageModel['medicalExaminationPayment'] = point;
    }
  }

  isShown(docType: string): boolean {
    return docType in this.pageModel;
  }

  getPoint(docType: string): number {
    return this.pageModel[docType];
  }

  isRequired(docType: string): boolean {
    return this.userService.isRequired(docType);
  }

  isUploaded(docType: string): boolean {
    return this.userService.isUploaded(docType);
  }

}
