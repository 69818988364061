<div class="container my-5">
	<ob-breadcrumb pageName="Osobní dotazník"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase mb-3">Osobní dotazník</h1>
	<p *ngIf="!isFilled && isLoaded" class="h6-responsive my-5"><b>Před nástupem od Vás potřebujeme vyplnit, dodat a nebo naskenovat (ve formě scanu nebo kvalitní/čitelné fotografie z mobilu) několik dokumentů.</b></p>

	<h1 *ngIf="isFilled && isLoaded" class="h3-responsive montserat-black text-success mt-5 mb-10">Děkujeme za vyplnění!</h1>
	<form *ngIf="!isFilled && isLoaded" (ngSubmit)="onSubmit(questionaryForm)" #questionaryForm="ngForm" >
		<div class="bg-white box-shadow p-5">
			<h2 class="h4-responsive text-uppercase montserat-black">Základní údaje</h2>
			<p class="text-danger">Údaje, které zde zadáte budou použity pro vytvoření Vašich pracovněprávních dokumentů.
				Uveďte je prosím přesně dle občanského průkazu (včetně diakritiky).</p>
			<!-- <div class="row mt-5">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-6 col-lg">
							<label class="mb-0" for="titleBeforeName">Titul</label>
							<small class="form-text text-muted">Nepovinný údaj</small>
						</div>
						<div class="col-sm-6">
							<div class="form-row align-items-center">
								<div class="col-sm-6">
									<input [(ngModel)]="model.titleBeforeName" type="text" class="form-control" name="titleBeforeName" id="titleBeforeName">
									<small class="form-text text-muted">před jménem</small>
								</div>
								<div class="col-sm-6">
									<input [(ngModel)]="model.titleAfterName" type="text" class="form-control" name="titleAfterName">
									<small class="form-text text-muted">za jménem</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> -->
			<div class="row mt-5">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center" [class.was-validated]="firstName.dirty || firstName.touched || questionaryForm.submitted">
						<div class="col-sm-6">
							<label for="firstName" class="mb-0">Jméno</label>
						</div>
						<div class="col-sm-6">
							<input [(ngModel)]="model.firstName" name="firstName" #firstName="ngModel" required id="firstName" type="text" class="form-control">
							<div class="invalid-feedback">
								Jméno je nutné vyplnit
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center" [class.was-validated]="lastName.dirty || lastName.touched || questionaryForm.submitted">
						<div class="col-sm-6">
							<label class="mb-0" for="lastName">Příjmení</label>
						</div>
						<div class="col-sm-6">
							<input [(ngModel)]="model.lastName" #lastName="ngModel"  name="lastName" required id="lastName" type="text" class="form-control uppercase">
							<div class="invalid-feedback">
								Příjmení je nutné vyplnit
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center" [class.was-validated]="birthName.dirty || birthName.touched || questionaryForm.submitted">
						<div class="col-sm-6">
							<label class="mb-0" for="birthName">Rodné příjmení</label>
						</div>
						<div class="col-sm-6">
							<input [(ngModel)]="model.birthName" #birthName="ngModel"  name="birthName" id="birthName" required type="text" class="form-control">
							<div class="invalid-feedback">
								Rodné příjmení je nutné vyplnit
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center"
						[class.was-validated]="birthNumber.valid && (birthNumber.dirty || birthNumber.touched || questionaryForm.submitted)">
						<div class="col-sm-6">
							<label class="mb-0" for="birthNumber">Rodné číslo</label>
						</div>
						<div class="col-sm-6">
							<input
								#birthNumber="ngModel"
								[(ngModel)]="model.birthNumber"
								birthNumberValidator
								required
								(ngModelChange)="calculateBirthDate(birthNumber.value)"
								[class.is-invalid]="birthNumber.invalid && (birthNumber.dirty || birthNumber.touched || questionaryForm.submitted)"
								name="birthNumber" id="birthNumber" type="text" maxlength="10" class="form-control">
							<small class="form-text text-muted">Zadejte číslo bez lomítka</small>
							<div class="invalid-feedback">
								<span *ngIf="birthNumber.errors?.required">
									Rodné číslo je nutné vyplnit
								</span>
								<span *ngIf="birthNumber.errors?.birthNumberValidator?.value">
									{{birthNumber.errors.birthNumberValidator.value}}
								</span>
							</div>

						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-6">
							<label class="mb-0">Datum narození</label>
							<small class="form-text text-muted">pro kontrolu</small>
							<small class="form-text text-muted">(vypočte se automaticky)</small>
						</div>
						<div class="col-sm-6">
							<input type="text" class="form-control" [ngModel]="birthDate" name="birthDate" readonly="" value="8. 8. 1990">
						</div>
					</div>
				</div>
			</div>
			<h2 class="h4-responsive mt-5 text-uppercase montserat-black">Základní údaje 2</h2>
			<p class="text-danger">Údaje, které zde zadáte budou použity pro vytvoření Vašich pracovněprávních dokumentů.
				Uveďte je prosím přesně dle občanského průkazu (včetně diakritiky).</p>
			<div class="row mt-5">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-6">
							<label class="mb-0">Rodinný stav</label>
						</div>
						<div class="col-sm-6">
							<div [class.was-validated]="maritalStatus.dirty || maritalStatus.touched || questionaryForm.submitted">
								<div class="custom-select custom-select--white" [class.is-invalid]="(maritalStatus.dirty || maritalStatus.touched || questionaryForm.submitted) && maritalStatus.invalid">
								    <div class="editing-form-control-nested-control">
								        <select [(ngModel)]="model.maritalStatus" #maritalStatus="ngModel" required name="maritalStatus" id="" class="DropDownField form-control ">
								        	<option value="">Vyberte prosím</option>
								        	<option *ngFor="let item of maritalStatusEnum" [value]="item">{{item}}</option>
								        </select>
								    </div>
								</div>
								<div class="invalid-feedback">
									Rodinný stav je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">

				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-6">
							<label class="mb-0">Státní příslušnost</label>
						</div>
						<div class="col-sm-6">
							<div [class.was-validated]="nationylity.dirty || nationylity.touched || questionaryForm.submitted">
								<div class="custom-select custom-select--white" [class.is-invalid]="(nationylity.dirty || nationylity.touched || questionaryForm.submitted) && nationylity.invalid">
								    <div class="editing-form-control-nested-control">
								        <select [(ngModel)]="model.nationylity" required #nationylity="ngModel" name="nationylity" id="nationylity" class="DropDownField form-control">
								        	<option value="">Vyberte prosím</option>
								            <option *ngFor="let item of nationalityEnum" [value]="item">{{item}}</option>
								        </select>
								    </div>
								</div>
								<div class="invalid-feedback">
									Státní příslušnost je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6" *ngIf="nationylity.value == nationalityEnumOther">
					<div class="form-group form-row align-items-center" [class.was-validated]="nationalityOther.dirty || nationalityOther.touched || questionaryForm.submitted">
						<div class="col-sm-6">
							<label class="mb-0" for="nationalityOther">Prosím upřesněte</label>
						</div>
						<div class="col-sm-6">
							<input [(ngModel)]="model.nationalityOther" #nationalityOther="ngModel" required name="nationalityOther" id="nationalityOther" type="text" class="form-control">
							<div class="invalid-feedback">
								Státní příslušnost je nutné vyplnit
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="nationylity.value != nationalityEnumCZE">
				<div class="col-12">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-3 col-lg">
						</div>
						<div class="col-sm-9">
							<div class="form-row align-items-center">
								<div class="col-sm-4">
									<div [class.was-validated]="typeOfDocument.dirty || typeOfDocument.touched || questionaryForm.submitted">
										<div class="custom-select custom-select--white" [class.is-invalid]="(typeOfDocument.dirty || typeOfDocument.touched || questionaryForm.submitted) && typeOfDocument.invalid">
											<div class="editing-form-control-nested-control">
												<select [(ngModel)]="model.foreignDocument.type" #typeOfDocument="ngModel" required name="typeOfDocument" id="typeOfDocument" class="DropDownField form-control ">
													<option value="" selected>Vyberte prosím</option>
													<option *ngFor="let item of typesOfDocument" [value]="item">{{item}}</option>
												</select>
											</div>
										</div>
										<small class="form-text" *ngIf="!(typeOfDocument.invalid && (typeOfDocument.dirty || typeOfDocument.touched || questionaryForm.submitted))">Druh dokladu</small>
										<div class="invalid-feedback" *ngIf="typeOfDocument.invalid && (typeOfDocument.dirty || typeOfDocument.touched || questionaryForm.submitted)">
											Druh dokladu je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div [class.was-validated]="numberOfDocument.dirty || numberOfDocument.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.foreignDocument.number" required #numberOfDocument="ngModel" name="birthAddressDistricts" type="text" class="form-control">
										<small class="form-text" *ngIf="!(numberOfDocument.invalid && (numberOfDocument.dirty || numberOfDocument.touched || questionaryForm.submitted))">Číslo dokladu</small>
										<div class="invalid-feedback" *ngIf="numberOfDocument.invalid && (numberOfDocument.dirty || numberOfDocument.touched || questionaryForm.submitted)">
											Číslo dokladu je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div [class.was-validated]="officeOfDocument.dirty || officeOfDocument.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.foreignDocument.office" required #officeOfDocument="ngModel" name="officeOfDocument" id="officeOfDocument" type="text" class="form-control">
										<small class="form-text" *ngIf="!(officeOfDocument.invalid && (officeOfDocument.dirty || officeOfDocument.touched || questionaryForm.submitted))">Úřad (příp. město), který doklad vydal</small>
										<div class="invalid-feedback" *ngIf="officeOfDocument.invalid && (officeOfDocument.dirty || officeOfDocument.touched || questionaryForm.submitted)">
											Úřad je nutné vyplnit
										</div>
									</div>
								</div>								
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="nationylity.value != nationalityEnumCZE">
				<div class="col-lg-6">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-6">
							<label class="mb-0">Jedná se o Vaše první zaměstnání v ČR?</label>
						</div>
						<div class="col-sm-6">
							<div [class.was-validated]="firstJob.dirty || firstJob.touched || questionaryForm.submitted">
								<div class="custom-checkbox" [class.is-invalid]="(firstJob.dirty || firstJob.touched || questionaryForm.submitted) && firstJob.invalid">
										<span class="radio radio-list-horizontal">
											<input [(ngModel)]="model.firstJob" required #firstJob="ngModel" id="firstJobTrue" name="firstJob" type="radio" [value]="true" />
											<label for="firstJobTrue"><span></span>Ano</label>
											<input [(ngModel)]="model.firstJob" required id="firstJobFalse" name="firstJob" type="radio" [value]="false" />
											<label for="firstJobFalse"><span></span>Ne</label>
										</span>
									</div>
								<div class="invalid-feedback">
									Údaj je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-lg-6">

				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="form-group form-row align-items-center">
						<div class="col-sm-3 col-lg">
							<label class="mb-0" for="birthAddressCity">Místo narození</label>
						</div>
						<div class="col-sm-9">
							<div class="form-row align-items-center">
								<div class="col-sm-4">
									<div [class.was-validated]="birthAddressCity.dirty || birthAddressCity.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.birthAddress.city" required #birthAddressCity="ngModel" name="birthAddressCity" id="birthAddressCity" type="text" class="form-control">
										<small class="form-text" *ngIf="!(birthAddressCity.invalid && (birthAddressCity.dirty || birthAddressCity.touched || questionaryForm.submitted))">Město</small>
										<div class="invalid-feedback" *ngIf="birthAddressCity.invalid && (birthAddressCity.dirty || birthAddressCity.touched || questionaryForm.submitted)">
											Město je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div [class.was-validated]="birthAddressDistricts.dirty || birthAddressDistricts.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.birthAddress.districts" required #birthAddressDistricts="ngModel" name="birthAddressDistricts" type="text" class="form-control">
										<small class="form-text" *ngIf="!(birthAddressDistricts.invalid && (birthAddressDistricts.dirty || birthAddressDistricts.touched || questionaryForm.submitted))">Okres</small>
										<div class="invalid-feedback" *ngIf="birthAddressDistricts.invalid && (birthAddressDistricts.dirty || birthAddressDistricts.touched || questionaryForm.submitted)">
											Okres je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-4">
									<div [class.was-validated]="birthAddressCountry.dirty || birthAddressCountry.touched || questionaryForm.submitted">
										<div class="custom-select custom-select--white" [class.is-invalid]="(birthAddressCountry.dirty || birthAddressCountry.touched || questionaryForm.submitted) && birthAddressCountry.invalid">
										    <div class="editing-form-control-nested-control">
										        <select [(ngModel)]="model.birthAddress.country" #birthAddressCountry="ngModel" required name="birthAddressCountry" id="birthAddressCountry" class="DropDownField form-control ">
										        	<option value="" selected>Vyberte prosím</option>
										            <option *ngFor="let item of stateEnum" [value]="item">{{item}}</option>
										        </select>
										    </div>
										</div>
										<small class="form-text" *ngIf="!(birthAddressCountry.invalid && (birthAddressCountry.dirty || birthAddressCountry.touched || questionaryForm.submitted))">Stát</small>
										<div class="invalid-feedback" *ngIf="birthAddressCountry.invalid && (birthAddressCountry.dirty || birthAddressCountry.touched || questionaryForm.submitted)">
											Stát je nutné vyplnit
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-md-6">
					<div class="form-group form-row align-items-center" [class.was-validated]="healthInsuraqnce.dirty || healthInsuraqnce.touched || questionaryForm.submitted">
						<div class="col-sm-6">
							<label class="mb-0" for="healthInsuraqnce">Zdravotní pojišťovna</label>
						</div>
						<div class="col-sm-6">
							<div [class.was-validated]="healthInsuraqnce.dirty || healthInsuraqnce.touched">
								<div class="custom-select custom-select--white" [class.is-invalid]="(healthInsuraqnce.dirty || healthInsuraqnce.touched || questionaryForm.submitted) && healthInsuraqnce.invalid">
								    <div class="editing-form-control-nested-control">
								        <select [(ngModel)]="model.healthInsuraqnce" #healthInsuraqnce="ngModel" required name="healthInsuraqnce" id="healthInsuraqnce" class="DropDownField form-control ">
								        	<option value="" selected>Vyberte prosím</option>
								            <option *ngFor="let item of healthInsuranceEnum" [value]="item">{{item}}</option>
								        </select>
								    </div>
								</div>
								<div class="invalid-feedback">
									Název zdravotní pojišťovny je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-md-6" *ngIf="healthInsuraqnce.value == healthInsuranceEnumOther">
					<div class="form-group form-row align-items-center" [class.was-validated]="healthInsuranceOther.dirty || healthInsuranceOther.touched || questionaryForm.submitted">
						<div class="col-sm-6">
							<label class="mb-0" for="healthInsuranceOther">Prosím upřesněte</label>
						</div>
						<div class="col-sm-6">
							<input [(ngModel)]="model.healthInsuranceOther" #healthInsuranceOther="ngModel" required name="healthInsuranceOther" id="healthInsuranceOther" type="text" class="form-control">
							<div class="invalid-feedback">
								Název zdravotní pojišťovny je nutné vyplnit
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-12">
					<div class="form-group form-row">
						<div class="col-sm-3 col-lg">
							<label class="mb-0" for="addressStreet">Trvalý pobyt</label>
							<small class="form-text text-muted">Dle občanského průkazu</small>
						</div>
						<div class="col-sm-9">
							<div class="form-row align-items-center mb-3">
								<div class="col-sm-6">
									<div [class.was-validated]="addressStreet.dirty || addressStreet.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.address.street" required #addressStreet="ngModel" name="addressStreet" id="addressStreet" type="text" class="form-control">
										<small class="form-text" *ngIf="!(addressStreet.invalid && (addressStreet.dirty || addressStreet.touched || questionaryForm.submitted))">Ulice</small>
										<div class="invalid-feedback" *ngIf="addressStreet.invalid && (addressStreet.dirty || addressStreet.touched || questionaryForm.submitted)">
											Ulici je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div [class.was-validated]="addressStreetNumber.dirty || addressStreetNumber.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.address.streetNumber" required #addressStreetNumber="ngModel" name="addressStreetNumber" type="text" class="form-control">
										<small class="form-text" *ngIf="!(addressStreetNumber.invalid && (addressStreetNumber.dirty || addressStreetNumber.touched || questionaryForm.submitted))">Č. popisné</small>
										<div class="invalid-feedback" *ngIf="addressStreetNumber.invalid && (addressStreetNumber.dirty || addressStreetNumber.touched || questionaryForm.submitted)">
											Č. popisné je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div>
										<input [(ngModel)]="model.address.orientationNumber" #addressStreetOrientationNumber="ngModel" name="addressStreetOrientationNumber" type="text" class="form-control">
										<small class="form-text">Č. orientační</small>
									</div>
								</div>
							</div>
							<div class="form-row align-items-center">
								<div class="col-sm-6">
									<div [class.was-validated]="addressCity.dirty || addressCity.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.address.city" required #addressCity="ngModel" name="addressCity" type="text" class="form-control">
										<small class="form-text" *ngIf="!(addressCity.invalid && (addressCity.dirty || addressCity.touched || questionaryForm.submitted))">Město</small>
										<div class="invalid-feedback" *ngIf="addressCity.invalid && (addressCity.dirty || addressCity.touched || questionaryForm.submitted)">
											Město je nutné vyplnit
										</div>
									</div>
								</div>
								<div class="col-sm-6">
									<div [class.was-validated]="addressZipcode.dirty || addressZipcode.touched || questionaryForm.submitted">
										<input [(ngModel)]="model.address.zipcode" required #addressZipcode="ngModel" name="addressZipcode" type="text" class="form-control">
										<small class="form-text" *ngIf="!(addressZipcode.invalid && (addressZipcode.dirty || addressZipcode.touched || questionaryForm.submitted))">PSČ</small>
										<div class="invalid-feedback" *ngIf="addressZipcode.invalid && (addressZipcode.dirty || addressZipcode.touched || questionaryForm.submitted)">
											PSČ je nutné vyplnit
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			</div>

			<div class="row">
				<div class="col-12">
					<div class="form-group form-row">
						<div class="col-sm-3 col-lg">
							<label class="mb-0" for="addressS2treet">Bydliště v ČR</label>
							<small class="form-text text-muted">Pokud jste cizinec a máte trvalý pobyt v zahraničí</small>
						</div>
						<div class="col-sm-9">
							<div class="form-row align-items-center mb-3">
								<div class="col-sm-6">
										<input [(ngModel)]="model.address2.street" #address2Street="ngModel" name="address2Street" id="address2Street" type="text" class="form-control">
										<small class="form-text">Ulice</small>
								</div>
								<div class="col-sm-3">
										<input [(ngModel)]="model.address2.streetNumber" #address2StreetNumber="ngModel" name="address2StreetNumber" type="text" class="form-control">
										<small class="form-text">Č. popisné</small>
								</div>
								<div class="col-sm-3">
									<div>
										<input [(ngModel)]="model.address2.orientationNumber" #address2StreetOrientationNumber="ngModel" name="address2StreetOrientationNumber" type="text" class="form-control">
										<small class="form-text">Č. orientační</small>
									</div>
								</div>
							</div>
							<div class="form-row align-items-center">
								<div class="col-sm-6">
										<input [(ngModel)]="model.address2.city" #address2City="ngModel" name="address2City" type="text" class="form-control">
										<small class="form-text">Město</small>
								</div>
								<div class="col-sm-6">
										<input [(ngModel)]="model.address2.zipcode" #address2Zipcode="ngModel" name="address2Zipcode" type="text" class="form-control">
										<small class="form-text">PSČ</small>
								</div>
							</div>
						</div>
					</div>
				</div>
			
			</div>

			<h2 class="h4-responsive mt-5 text-uppercase montserat-black">Potvrzení o přečtení dokumentů</h2>
			<div class="form-group mt-5">
				<label class="bold" for="radio-label-1">Potvrzuji, že jsem si přečetl(a) dokument <a href="{{codexUrl}}">Etický kodex</a>, a že s ním souhlasím.</label>
				<div class="custom-checkbox" [class.is-invalid]="(codexWasRead.dirty || codexWasRead.touched || questionaryForm.submitted) && codexWasRead.invalid">
					<span class="radio radio-list-horizontal">
						<input [(ngModel)]="model.codexWasRead" required readDocumentValidator #codexWasRead="ngModel" id="codexWasReadTrue" name="codexWasRead" type="radio" [value]="true" />
						<label for="codexWasReadTrue"><span></span>Ano</label>
						<input [(ngModel)]="model.codexWasRead" required #codexWasRead="ngModel" id="codexWasReadFalse" name="codexWasRead" type="radio" [value]="false" />
						<label for="codexWasReadFalse"><span></span>Ne</label>
					</span>
				</div>
				<div class="invalid-feedback">
					<span *ngIf="codexWasRead.errors?.required">
						Je nutné vybrat jednu možnost
					</span>
					<span *ngIf="codexWasRead.errors?.readDocumentValidator?.value">
						{{codexWasRead.errors.readDocumentValidator.value}}
					</span>
				</div>
			</div>
			<div class="form-group mt-5">
				<label class="bold" for="radio-label-1">Potvrzuji, že jsem si přečetl(a) dokument <a href="{{informationSecurityUrl}}">Informační bezpečnost</a>, a že s ním souhlasím.</label>
				<div class="custom-checkbox" [class.is-invalid]="(informationSecurityWasRead.dirty || informationSecurityWasRead.touched || questionaryForm.submitted) && informationSecurityWasRead.invalid">
					<span class="radio radio-list-horizontal">
						<input [(ngModel)]="model.informationSecurityWasRead" required readDocumentValidator #informationSecurityWasRead="ngModel" id="informationSecurityWasReadTrue" name="informationSecurityWasRead" type="radio" [value]="true" />
						<label for="informationSecurityWasReadTrue"><span></span>Ano</label>
						<input [(ngModel)]="model.informationSecurityWasRead" required #informationSecurityWasRead="ngModel" id="informationSecurityWasReadFalse" name="informationSecurityWasRead" type="radio" [value]="false" />
						<label for="informationSecurityWasReadFalse"><span></span>Ne</label>
					</span>
				</div>
				<div class="invalid-feedback">
					<span *ngIf="informationSecurityWasRead.errors?.required">
						Je nutné vybrat jednu možnost
					</span>
					<span *ngIf="informationSecurityWasRead.errors?.readDocumentValidator?.value">
						{{informationSecurityWasRead.errors.readDocumentValidator.value}}
					</span>
				</div>
			</div>
			<h2 class="h4-responsive mt-5 text-uppercase montserat-black">Doplňující údaje</h2>
			<div class="form-group mt-5">
				<label class="bold" for="radio-label-1">Je proti Vám vedeno trestní řízení nebo je Vám uložen trest zákazu činnosti vztahující se k výkonu předpokládané pracovní činnosti?</label>
				<div class="custom-checkbox" [class.is-invalid]="(criminalProceedings.dirty || criminalProceedings.touched || questionaryForm.submitted) && criminalProceedings.invalid">
					<span class="radio radio-list-horizontal">
						<input [(ngModel)]="model.criminalProceedings" required #criminalProceedings="ngModel" id="criminalProceedingsTrue" name="criminalProceedings" type="radio" [value]="true" />
						<label for="criminalProceedingsTrue"><span></span>Ano</label>
						<input [(ngModel)]="model.criminalProceedings" required #criminalProceedings="ngModel" id="criminalProceedingsFalse" name="criminalProceedings" type="radio" [value]="false" />
						<label for="criminalProceedingsFalse"><span></span>Ne</label>
					</span>
				</div>
				<div class="invalid-feedback">
					Je nutné vybrat jednu možnost
				</div>
			</div>
			<div class="form-group">
				<label class="bold" for="radio-label-1">Jste americkou osobou = máte státní občanství USA (včetně osob, které mají dvojí občanství) nebo jste držitelem zelené karty USA?</label>
				<div class="custom-checkbox" [class.is-invalid]="(americanPerson.dirty || americanPerson.touched || questionaryForm.submitted) && americanPerson.invalid">
					<span class="radio radio-list-horizontal">
						<input [(ngModel)]="model.americanPerson" required #americanPerson="ngModel" id="americanPersonTrue" name="americanPerson" type="radio" [value]="true" />
						<label for="americanPersonTrue"><span></span>Ano</label>
						<input [(ngModel)]="model.americanPerson" required #americanPerson="ngModel" id="americanPersonFalse" name="americanPerson" type="radio" [value]="false" />
						<label for="americanPersonFalse"><span></span>Ne</label>
					</span>
				</div>
				<div class="invalid-feedback">
					Je nutné vybrat jednu možnost
				</div>
			</div>
			<div class="form-group">
				<label class="bold" for="radio-label-1">Jste politicky exponovaná osoba?</label>
				<div class="custom-checkbox" [class.is-invalid]="(politician.dirty || politician.touched || questionaryForm.submitted) && politician.invalid">
					<span class="radio radio-list-horizontal">
						<input [(ngModel)]="model.politician" required #politician="ngModel" id="politicianTrue" name="politician" type="radio" [value]="true" />
						<label for="politicianTrue"><span></span>Ano</label>
						<input [(ngModel)]="model.politician" required #politician="ngModel" id="politicianFalse" name="politician" type="radio" [value]="false" />
						<label for="politicianFalse"><span></span>Ne</label>
					</span>
				</div>
				<div class="invalid-feedback">
					Je nutné vybrat jednu možnost
				</div>
				<small class="form-text text-muted">Jste nebo byl/a jste v posledních třech letech politicky exponovaná osoba tzn. vykonáváte významnou veřejnou funkci s celostátním nebo regionálním významem (prezident, předseda vlády, ministr, náměstek, asistent ministra, státní sekretář, vedoucí nebo zástupce ústředního orgánu státní správy, senátor, poslanec, hejtman, primátor, starosta, člen řídícího orgánu politické strany, hnutí nebo odborové organizace, představený církve nebo náboženské obce, člen Nejvyššího, Ústavního nebo obdobného zahraničního soudního orgánu, člen bankovní rady nebo účetního dvora, vysoký úředník bezpečnostních nebo vojenských složek, člen statutárního orgánu obchodní korporace ovládané státem, velvyslanec, vedoucí diplomatické mise, chargé d’affaires, vedoucí nebo zástupce mezinárodní organizace, nejvyšší státní zástupce, vedoucí významné neziskové organizace nebo mezinárodní asociace s politickým vlivem)?</small>
			</div>
			<div class="form-group">
				<label class="bold" for="radio-label-1">Jste nebo byl/a jste v posledních třech letech osoba mající přímý rodinný nebo obchodní vztah k politicky exponované osobě tzn. k osobě vykonávající významnou veřejnou funkci s celostátním nebo regionálním významem?</label>
				<div class="form-row align-items-center">
					<div class="col-sm-3">
						<div class="custom-checkbox" [class.is-invalid]="(politicianClosePerson.dirty || politicianClosePerson.touched || questionaryForm.submitted) && politicianClosePerson.invalid">
							<span class="radio radio-list-horizontal">
								<input [(ngModel)]="model.politicianClosePerson" required #politicianClosePerson="ngModel" id="politicianClosePersonTrue" name="politicianClosePerson" type="radio" [value]="true" />
								<label for="politicianClosePersonTrue"><span></span>Ano</label>
								<input [(ngModel)]="model.politicianClosePerson" required id="politicianClosePersonFalse" name="politicianClosePerson" type="radio" [value]="false" />
								<label for="politicianClosePersonFalse"><span></span>Ne</label>
							</span>
						</div>
						<div class="invalid-feedback">
							Je nutné vybrat jednu možnost
						</div>
					</div>
					<div class="col-sm-9" *ngIf="model.politicianClosePerson == true">
						<div class="form-row align-items-center" [class.was-validated]="politicianClosePersonDescription.dirty || politicianClosePersonDescription.touched || questionaryForm.submitted">
							<div class="col-sm-3">
								<label class="mb-0" for="politicianClosePersonDescription">Doplňte jméno a příjmení</label>
							</div>
							<div class="col-sm-9">
								<input [(ngModel)]="model.politicianClosePersonDescription" #politicianClosePersonDescription="ngModel" required id="politicianClosePersonDescription" name="politicianClosePersonDescription" type="text" class="form-control">
								<div class="invalid-feedback">
									Je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<small class="form-text text-muted">V případě že podnikáte nebo vykonáváte jinou výdělečnou činnost shodnou s předmětem podnikání Komerční banky, a.s., je nutné požádat o výjimku či tyto činnosti ukončit nebo přerušit, a to nejpozději do 3 měsíců ode dne vzniku pracovního poměru. V tomto případě kontaktujte svoji HR konzultantku.</small>
			</div>
			<div class="form-group">
				<label class="bold" for="radio-label-1">Podnikáte nebo máte jinou výdělečnou činnost, případně máte záznam v živnostenském rejstříku  (<a href="https://www.rzp.cz" target="_blank">www.rzp.cz</a>)?<br/>
					V případě, že v okamžiku vyplňování dotazníku máte jinou výdělečnou činnost pozastavenou nebo ukončenou, odpovězte „ne“. 
				</label>
				<div class="form-row align-items-center">
					<div class="col-sm-3">
						<div class="custom-checkbox" [class.is-invalid]="(gainfulActivity.dirty || gainfulActivity.touched || questionaryForm.submitted) && gainfulActivity.invalid">
							<span class="radio radio-list-horizontal">
								<input [(ngModel)]="model.gainfulActivity" required #gainfulActivity="ngModel" id="gainfulActivityTrue" name="gainfulActivity" type="radio" [value]="true" />
								<label for="gainfulActivityTrue"><span></span>Ano</label>
								<input [(ngModel)]="model.gainfulActivity" required id="gainfulActivityFalse" name="gainfulActivity" type="radio" [value]="false" />
								<label for="gainfulActivityFalse"><span></span>Ne</label>
							</span>
						</div>
						<div class="invalid-feedback">
							Je nutné vybrat jednu možnost
						</div>
					</div>
					<div class="col-sm-9" *ngIf="model.gainfulActivity == true">
						<div class="form-row align-items-center" [class.was-validated]="gainfulActivityDescription.dirty || gainfulActivityDescription.touched || questionaryForm.submitted">
							<div class="col-sm-3">
								<label class="mb-0" for="gainfulActivityDescription">Uveďte název živnosti</label>
							</div>
							<div class="col-sm-9">
								<input [(ngModel)]="model.gainfulActivityDescription" #gainfulActivityDescription="ngModel" required id="gainfulActivityDescription" name="gainfulActivityDescription" type="text" class="form-control">
								<div class="invalid-feedback">
									Je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<small class="form-text text-muted">V případě že podnikáte nebo vykonáváte jinou výdělečnou činnost shodnou s předmětem podnikání Komerční banky, a.s., je nutné požádat o výjimku či tyto činnosti ukončit nebo přerušit, a to nejpozději do 3 měsíců ode dne vzniku pracovního poměru. V tomto případě kontaktujte svoji HR konzultantku.</small>
			</div>
			<div class="form-group">
				<label class="bold" for="radio-label-1">Jste statutárním orgánem, členem statutárního orgánu nebo dozorčího orgánu jiné právnické osoby, která je podnikatelem?</label>
				<div class="form-row align-items-center">
					<div class="col-sm-3">
						<div class="custom-checkbox" [class.is-invalid]="(statutoryBody.dirty || statutoryBody.touched || questionaryForm.submitted) && statutoryBody.invalid">
							<span class="radio radio-list-horizontal">
								<input [(ngModel)]="model.statutoryBody" required #statutoryBody="ngModel" id="statutoryBodyTrue" name="statutoryBody" type="radio" [value]="true" />
								<label for="statutoryBodyTrue"><span></span>Ano</label>
								<input [(ngModel)]="model.statutoryBody" required id="statutoryBodyFalse" name="statutoryBody" type="radio" [value]="false" />
								<label for="statutoryBodyFalse"><span></span>Ne</label>
							</span>
						</div>
						<div class="invalid-feedback">
							Je nutné vybrat jednu možnost
						</div>
					</div>
					<div class="col-sm-9" *ngIf="model.statutoryBody == true">
						<div class="form-row align-items-center" [class.was-validated]="statutoryBodyDescription.dirty || statutoryBodyDescription.touched || questionaryForm.submitted">
							<div class="col-sm-3">
								<label class="mb-0" for="statutoryBodyDescription">Prosím upřesněte</label>
							</div>
							<div class="col-sm-9">
								<input [(ngModel)]="model.statutoryBodyDescription" #statutoryBodyDescription="ngModel" required id="statutoryBodyDescription" name="statutoryBodyDescription" type="text" class="form-control">
								<div class="invalid-feedback">
									Je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
				<small class="form-text text-muted">V případě že jste statuárním orgánem, členem statutárního orgánu nebo dozorčí rady jiné právnické osoby, která má shodný předmět podnikání jako Komerční banka, a.s., je nutné požádat o výjimku nebo ukončit či přerušit tuto činnost, a to nejpozději do 3 měsíců ode dne vzniku pracovního poměru.</small>
			</div>
			<div class="form-group">
				<label class="bold">Pracuje v Komerční bance osoba blízká vaší osobě, se kterou jste v jednom z níže vyjmenovaných vztahů? Pokud ano, vypište její jméno a zaškrtněte vztah k dané osobě.</label>
				<div class="form-row align-items-center">
					<div class="col-sm-3">
						<div class="custom-checkbox" [class.is-invalid]="(closePerson.dirty || closePerson.touched || questionaryForm.submitted) && closePerson.invalid">
							<span class="radio radio-list-horizontal">
								<input [(ngModel)]="model.closePerson" required #closePerson="ngModel" id="closePersonTrue" name="closePerson" type="radio" [value]="true" />
								<label for="closePersonTrue"><span></span>Ano</label>
								<input [(ngModel)]="model.closePerson" required id="closePersonFalse" name="closePerson" type="radio" [value]="false" />
								<label for="closePersonFalse"><span></span>Ne</label>
							</span>
						</div>
						<div class="invalid-feedback">
							Je nutné vybrat jednu možnost
						</div>
					</div>
					<div class="col-sm-9" *ngIf="model.closePerson == true">
						<div class="form-row align-items-center">
							<div class="col-sm-3">
								<label class="mb-0" for="closePersonType">Prosím upřesněte</label>
							</div>
							<div class="col-sm-9">
								<div [class.was-validated]="closePersonType.dirty || closePersonType.touched || questionaryForm.submitted">
									<div class="custom-select custom-select--white" [class.is-invalid]="(closePersonType.dirty || closePersonType.touched || questionaryForm.submitted) && closePersonType.invalid">
									    <div class="editing-form-control-nested-control">
									        <select [(ngModel)]="model.closePersonType" #closePersonType="ngModel" required name="closePersonType" id="closePersonType" class="DropDownField form-control ">
									        	<option value="">Vyberte prosím</option>
									            <option *ngFor="let item of closePersonEnum" [value]="item">{{item}}</option>
									        </select>
									    </div>
									</div>
									<div class="invalid-feedback">
										Je nutné vyplnit
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="form-group" *ngIf="model.closePerson == true">
				<div class="form-row align-items-center">
					<div class="col-sm-3">
					</div>
					<div class="col-sm-9">
						<div class="form-row align-items-center" [class.was-validated]="closePersonDescription.dirty || closePersonDescription.touched || questionaryForm.submitted">
							<div class="col-sm-3">
								<label class="mb-0" for="closePersonDescription">Doplňte jméno a příjmení</label>
							</div>
							<div class="col-sm-9">
								<input [(ngModel)]="model.closePersonDescription" #closePersonDescription="ngModel" required id="closePersonDescription" name="closePersonDescription" type="text" class="form-control">
								<div class="invalid-feedback">
									Je nutné vyplnit
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-group">
				<label class="bold">Budete mít kratší pracovní úvazek v rozmezí mezi 8 až 30 hodinami týdně?</label>
				<div class="form-row align-items-center">
					<div class="col-sm-3">
						<div class="custom-checkbox" [class.is-invalid]="(partTime.dirty || partTime.touched || questionaryForm.submitted) && partTime.invalid">
							<span class="radio radio-list-horizontal">
								<input [(ngModel)]="model.partTime" required #partTime="ngModel" id="partTimeTrue" name="partTime" type="radio" [value]="true" />
								<label for="partTimeTrue"><span></span>Ano</label>
								<input [(ngModel)]="model.partTime" required id="partTimeFalse" name="partTime" type="radio" [value]="false" />
								<label for="partTimeFalse"><span></span>Ne</label>
							</span>
						</div>
						<div class="invalid-feedback">
							Je nutné vybrat jednu možnost
						</div>
					</div>
				</div>
			</div>

			<div class="form-group" *ngIf="model.partTime == true">
				<div class="form-group">
					<label class="bold">Pečujete o dítě mladší  10 let, jehož jste rodičem, nebo které máte v péči nahrazující péči rodičů na základě rozhodnutí příslušného orgánu?</label>
					<div class="form-row align-items-center">
						<div class="col-sm-3">
							<div class="custom-checkbox" [class.is-invalid]="(child10.dirty || child10.touched || questionaryForm.submitted) && child10.invalid">
								<span class="radio radio-list-horizontal">
									<input [(ngModel)]="model.child10" required #child10="ngModel" id="child10True" name="child10" type="radio" [value]="true" />
									<label for="child10True"><span></span>Ano</label>
									<input [(ngModel)]="model.child10" required id="child10False" name="child10" type="radio" [value]="false" />
									<label for="child10False"><span></span>Ne</label>
								</span>
							</div>
							<div class="invalid-feedback">
								Je nutné vybrat jednu možnost
							</div>
						</div>
	
						<div class="col-sm-9" *ngIf="model.child10 == true">
							<div class="form-row align-items-center">
								<div class="col-sm-9">
									Nahrajte do sekce „Dokumenty od Vás - DOKUMENTY KE KRATŠÍM PRACOVNÍM ÚVAZKŮM“ kopii rodného listu dítěte/dětí, případně Rozhodnutí o svěření dítěte do péče.
								</div>
							</div>
						</div>
	
					</div>
				</div>
	
				<div class="form-group">
					<label class="bold">Jste student (máte status studenta)?</label>
					<div class="form-row align-items-center">
						<div class="col-sm-3">
							<div class="custom-checkbox" [class.is-invalid]="(student.dirty || student.touched || questionaryForm.submitted) && student.invalid">
								<span class="radio radio-list-horizontal">
									<input [(ngModel)]="model.student" required #student="ngModel" id="studentTrue" name="student" type="radio" [value]="true" />
									<label for="studentTrue"><span></span>Ano</label>
									<input [(ngModel)]="model.student" required id="studentFalse" name="student" type="radio" [value]="false" />
									<label for="studentFalse"><span></span>Ne</label>
								</span>
							</div>
							<div class="invalid-feedback">
								Je nutné vybrat jednu možnost
							</div>
						</div>
	
						<div class="col-sm-9" *ngIf="model.student == true">
							<div class="form-row align-items-center">
								<div class="col-sm-9">
									Nahrajte do sekce „Dokumenty od vás - DOKUMENTY KE KRATŠÍM PRACOVNÍM ÚVAZKŮM“ kopii potvrzení o studiu na aktuální školní rok.
								</div>
							</div>
						</div>
	
					</div>
				</div>
	
				<div class="form-group">
					<label class="bold">Jste osobou se zdravotním postižením/ znevýhodněním?</label>
					<div class="form-row align-items-center">
						<div class="col-sm-3">
							<div class="custom-checkbox" [class.is-invalid]="(handicap.dirty || handicap.touched || questionaryForm.submitted) && handicap.invalid">
								<span class="radio radio-list-horizontal">
									<input [(ngModel)]="model.handicap" required #handicap="ngModel" id="handicapTrue" name="handicap" type="radio" [value]="true" />
									<label for="handicapTrue"><span></span>Ano</label>
									<input [(ngModel)]="model.handicap" required id="handicapFalse" name="handicap" type="radio" [value]="false" />
									<label for="handicapFalse"><span></span>Ne</label>
								</span>
							</div>
							<div class="invalid-feedback">
								Je nutné vybrat jednu možnost
							</div>
						</div>
	
						<div class="col-sm-9" *ngIf="model.handicap == true">
							<div class="form-row align-items-center">
								<div class="col-sm-9">
									Nahrajte do sekce „Dokumenty od vás - DOKUMENTY KE KRATŠÍM PRACOVNÍM ÚVAZKŮM“ kopii Potvrzení/Rozhodnutí ze Správy soc. zabezpečení.
								</div>
							</div>
						</div>
	
					</div>
				</div>
			</div>



		</div>
		<div class="my-5">
			<p *ngIf="questionaryForm.submitted && questionaryForm.invalid" class="text-danger">Formulář se nepodařilo odeslat. Ve formuláři jsou označena chybně vyplněná pole. Opravte chyby a zkuste to prosím znovu.</p>
			<button type="submit" class="btn btn-primary">Odeslat</button>
		</div>
	</form>
</div>
