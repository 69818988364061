<div class="container my-5">
	<ob-breadcrumb pageName="Dokumenty od Vás"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase mt-3">POŠLETE NÁM VŠE VČAS</h1>
	<p class="h6-responsive my-3 mb-6"><b>Před nástupem od Vás potřebujeme vyplnit, dodat a nebo naskenovat (ve formě skenu nebo kvalitní/čitelné fotografie z mobilu) několik dokumentů.</b></p>
	<ob-process-point [number]="getPoint('questionaire')" [processed]="isQuestionaryFilled">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">OSOBNÍ DOTAZNÍK</h2>
		<p><b>Vyplňte, prosím, osobní dotazník. Potřebujeme ho, abychom pro Vás připravili včas dokumenty k podpisu.</b></p>
		<button *ngIf="!isQuestionaryFilled" routerLink="/onboarding-info/questionary" class="btn btn-outline-secondary">Vyplnit dotazník</button>
	</ob-process-point>
  <ob-process-point [number]="getPoint('cafeterie')" [processed]="isSetCafeterie()" *ngIf="isShown('cafeterie')">
    <h2 class="text-uppercase montserat-black h4-responsive mb-2">VOLBA FIXNÍHO BENEFITU</h2>
    <p><b>Pro přípravu pracovních dokumentů potřebujeme znát Váš zájem/nezájem o získání většího počtu bodů do Cafeterie formou volby fixního benefitu.</b></p>
    <button routerLink="/cafeterie" class="btn btn-outline-secondary">Vybrat</button>
  </ob-process-point>
	<ob-process-point [processed]="isUploaded('personalPhoto')" [number]="getPoint('personalPhoto')" *ngIf="isShown('personalPhoto')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">PRŮKAZOVÁ FOTOGRAFIE</h2>
		<p><b>Zkuste vybrat takovou, kde Vás poznáme. Bude použita na Vaši zaměstnaneckou kartu.</b></p>
		<p><b>Požadovanou formou je barevná fotografie pasového formátu v JPG, JPEG nebo GIF. Ideální je, pokud máte portrétovou fotografii od fotografa již v digitální podobě. Pokud ne, fotografii můžete  naskenovat nebo se vyfotit a poslat ji v elektronické podobě.</b></p>
		<h6>Jaké náležitosti musí mít?</h6>
		<ul>
			<li>Rohy fotografie jsou rovné, ne zakulacené.</li>
			<li>Na fotce je vidět nejen Vaše hlava, ale i část ramen.</li>
			<li>Jste zobrazen/a v čelním pohledu a Váš pohled směřuje do objektivu.</li>
			<li>Na fotce nemůžete mít tmavé brýle (pokud nejste nevidomí) nebo pokrývku hlavy (kromě zdravotních či náboženských důvodů).</li>
			<li>Pozadí fotografie je jednolité, ne členité či se vzorem (doporučujeme bílé nebo světle modré). A nemůže splývat s Vámi (tzn. je lepší fotit světlovlasé osoby na modrém pozadí).</li>
		</ul>
		<app-file-upload documentType="personalPhoto" allowMultiple="false" allowExtensions="jpg,jpeg,gif"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('diploma')" *ngIf="isShown('diploma')" [number]="getPoint('diploma')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">VYSVĚDČENÍ A DIPLOMY</h2>
		<p><b>Vložte sken nebo fotografii Vašeho maturitního vysvědčení nebo vysokoškolského diplomu. Použijte doklad o nejvyšším dosaženém vzdělání.</b></p>
		<app-file-upload documentType="diploma" allowMultiple="true"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="(!isRequired('medicalExamination') || isUploaded('medicalExamination')) && (!isRequired('medicalExaminationPayment') || isUploaded('medicalExaminationPayment'))"
			*ngIf="isShown('medical')" [number]="getPoint('medical')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">Lékařská prohlídka</h2>
		<p><b>Formulář o absolvování povinné lékařské prohlídky před nástupem do práce a doklad o úhradě poplatku u Vašeho praktického lékaře nahrajte zde. Aby vám to šlo snadno, připravili jsme Vám návod.</b></p>
		<button routerLink="/medical-examination" class="btn btn-outline-secondary">Zobrazit návod</button>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('criminalRecord')" [number]="getPoint('criminalRecord')" *ngIf="isShown('criminalRecord')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">VÝPIS Z REJSTŘÍKU TRESTŮ</h2>
			<p><b>Kopie výpisu z Vašeho rejstříku trestů ne starší než 3 měsíce.</b></p>
			<app-file-upload documentType="criminalRecord" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<h2 class="h3-responsive montserat-black text-uppercase title-underlined mb-8 mt-10"
			*ngIf="isShown('certificate') || isShown('pension') || isShown('disadvantage')">MŮŽE SE VÁS TAKÉ TÝKAT</h2>
	<ob-process-point [processed]="isUploaded('certificate')" [number]="getPoint('certificate')" *ngIf="isShown('certificate')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">CERTIFIKÁTY ČNB</h2>
		<p>Pokud máte následující osvědčení o absolvování zkoušek, tak nám zde nahrajte jejich kopie nebo fotografie: Finanční poradce od ČNB, Odborný kurz o rozpoznávání bankovek a mincí podezřelých z padělání, Zkouška pojišťovacího zprostředkovatele, Doplňkové penzijní spoření, Spotřebitelské úvěry na bydlení a jiné než na bydlení, Kapitálové trhy I. a II. (včetně makléřských zkoušek).</p>
		<app-file-upload documentType="certificate" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('pension')" [number]="getPoint('pension')" *ngIf="isShown('pension')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">ROZHODNUTÍ O PŘÍPADNÉM INVALIDNÍM ČI STAROBNÍM DŮCHODU</h2>
		<p><b>Pokud Vám byl přiznán invalidní nebo starobní důchod, nahrajte kopii rozhodnutí.</b></p>
		<app-file-upload documentType="pension" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('disadvantage')" [number]="getPoint('disadvantage')" *ngIf="isShown('disadvantage')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">ROZHODNUTÍ O OSOBĚ ZDRAVOTNĚ ZNEVÝHODNĚNÉ</h2>
		<p><b>Pokud máte rozhodnutí o uznání za osobu zdravotně znevýhodněnou, nahrajte kopii tohoto rozhodnutí.</b></p>
		<app-file-upload documentType="disadvantage" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('oDoc4')" [number]="getPoint('oDoc4')" *ngIf="isShown('oDoc4')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">{{getDescription('oDoc4')}}</h2>
		<p><b></b></p>
		<app-file-upload documentType="oDoc4" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('oDoc5')" [number]="getPoint('oDoc5')" *ngIf="isShown('oDoc5')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">{{getDescription('oDoc5')}}</h2>
		<p><b></b></p>
		<app-file-upload documentType="oDoc54" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('permitRecord')" [number]="getPoint('permitRecord')" *ngIf="isShown('permitRecord')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">{{getDescription('permitRecord')}}</h2>
		<p><b></b></p>
		<app-file-upload documentType="permitRecord" allowMultiple="false"></app-file-upload>
	</ob-process-point>
	<ob-process-point [processed]="isUploaded('partTime')" [number]="getPoint('partTime')" *ngIf="isShown('partTime')">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">{{getDescription('partTime')}}</h2>
		<p><b></b></p>
		<app-file-upload documentType="partTime" allowMultiple="false"></app-file-upload>
	</ob-process-point>
</div>
