import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpModule }  from '@angular/http';
import { MatDialogModule } from '@angular/material';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CollapseModule, BsDropdownModule } from 'ngx-bootstrap';

import { MockApiModule } from '@cz.kb.fe.lib/lib-mock-api-ng2';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { LanguageModule } from '@cz.kb.fe.lib/lib-i18n-ng2';
import { HttpHelpersModule } from '@cz.kb.fe.lib/lib-http-helpers-ng2';

import { CONFIGURED_LOGGING_PROVIDERS } from './logging';
import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { CoreModule } from './core';
import { SharedModule } from './shared';

import { NavbarModule } from './navbar';
import { FooterModule } from './footer';
import { LoginModule } from './login';

import { OnboardingInfoModule } from './pages/onboarding-info';
import { YourDocumentsModule } from './pages/your-documents';
import { CafeterieModule } from './pages/cafeterie';
import { OnboardingDocumentsModule } from './pages/onboarding-documents';
import { WhatAwaitsModule } from './pages/what-awaits';
import { LifeInKbModule } from './pages/life-in-kb';
import { PeopleModule } from './pages/life-in-kb/people';
import { CharityModule } from './pages/life-in-kb/charity';
import { FunModule } from './pages/life-in-kb/fun';

import { BenefitsModule } from './pages/benefits';
import { KbInNumbersModule } from './pages/kb-in-numbers';
import { QuestionaryModule } from './pages/questionary';
import { SatisfactionQuestionaryModule } from './pages/satisfaction-questionary';
import { MedicalExaminationModule } from './pages/medical-examination';

import { AuthGuard } from './auth.guard';
import { PageDeactivateGuard } from './pagedeactivate.guard';
import { VersionModule } from './pages/version';
import { InformationdialogComponent } from './dialogs/informationdialog/informationdialog.component';
import { CompileDirective } from './compile.directive';

@NgModule({
  imports: [
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    BrowserModule,
    routing,
    CoreModule,
    SharedModule,
    HttpModule,
    LanguageModule,
    HttpHelpersModule,
    MockApiModule.forRoot(),
    NavbarModule,
    FooterModule,
    LoginModule,
    OnboardingInfoModule,
    YourDocumentsModule,
    CafeterieModule,
    OnboardingDocumentsModule,
    WhatAwaitsModule,
    LifeInKbModule,
    BenefitsModule,
    KbInNumbersModule,
    PeopleModule,
    CharityModule,
    FunModule,
    QuestionaryModule,
    SatisfactionQuestionaryModule,
    MedicalExaminationModule,
    VersionModule,
    MatDialogModule,
    BrowserAnimationsModule,
    AngularFontAwesomeModule
  ],
  declarations: [AppComponent, InformationdialogComponent, CompileDirective],
  entryComponents: [
    InformationdialogComponent
  ],
  exports: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    CONFIGURED_LOGGING_PROVIDERS,
    AuthGuard,
    PageDeactivateGuard,
    { provide: LOCALE_ID, useValue: 'cs' },
  ]
})
export class AppModule {
  constructor() {
  }
}
