<div class="fullwidth-image kb-building"></div>
<div class="container pull-up">
	<div class="mb-md-7">
		<h1 class="h3-responsive montserat-black text-uppercase">Těšíme se na vás</h1>
		<div class="row mt-2">
			<div class="col-6 col-lg-3 mb-5">
				<a routerLink="/onboarding-info" routerLinkActive="active" class="link-box">
					<div class="link-box__content">
						<!-- marker-office -->
						<svg class="icon icon--xxxl icon--marker-office icon--white" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path d="M14.17 35.05q0 7.36 3.92 16.96a115.32 115.32 0 0 0 8.6 17.2q4.72 7.6 10.87 15.62 6.15 8.04 8.5 10.83 2.36 2.8 3.93 4.35l8.96-10.27q8.95-10.5 17.9-26.78 8.97-16.3 8.97-27.9 0-14.5-10.52-24.78A34.96 34.96 0 0 0 50 0Q35.22 0 24.7 10.28 14.17 20.54 14.17 35.05z"/><path d="M60.7 38.18c.17-.6-.47-1.52-1.9-1.52H55v2.74h2.85c1.58 0 2.7-.63 2.85-1.22zm-1.9 2.43H55v2.43c0 .32.3.64.63.64h3.17c3.33 0 2.38-3.38 0-3.06zm-8.88-23.7l-22.08 19h4.43v16.88c0 1.2.95 2.1 2.24 2.1h30.97c1.26 0 2.2-.9 2.2-2.1V35.9h4.5zm.3 28.12H45.8l-.63-.6-5.4-4.1v3.64c0 .45.3.76.63.76h.3v.15h-4.75v-.32h.3c.5 0 .64-.3.8-.76v-7.43c0-.47-.3-.76-.62-.76h-.8v-.16h4.78v.3h-.3c-.3 0-.63.3-.63.77v3.05l6.03-3.35c.47-.3.32-.6 0-.6v-.16h4.44v.17c-.47 0-.8.15-1.1.3l-6.35 3.34 6.98 5.17c.32.15.5.32.8.32zm9.2 0h-8.1v-.3c.64 0 .96-.17.96-.76V36.5c0-.45-.32-.75-.95-.75v-.3h8.9c2.07 0 3.34.75 3.34 2.12s-.95 1.98-2.7 2.28c1.9.15 3.33.6 3.33 2.42 0 1.7-1.26 2.75-4.77 2.75z" fill="#fff"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Informace k nástupu</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-3 mb-5">
				<a routerLink="/your-documents" routerLinkActive="active" class="link-box">
					<div class="link-box__content">
						<!-- clip -->
						<svg class="icon icon--xxxl icon--white" xmlns="http://www.w3.org/2000/svg" width="792" height="792" viewBox="0 0 792 792"><path d="M552.06 229.118c-13.684 0-24.722 10.595-24.722 24.29v358.91c0 36.647-13.242 66.663-38.85 92.272s-56.068 38.85-93.147 38.85c-36.203 0-67.104-12.8-92.712-38.407-25.608-25.607-38.408-56.51-38.408-92.713V138.617c0-24.28 8.938-45.358 26.05-62.688 17.33-17.546 38.4-26.927 63.574-26.927 25.166 0 46.244 9.38 63.563 26.927 17.113 17.33 26.052 38.408 26.052 62.688v403.074c0 25.156-19.422 44.586-44.587 44.586-24.28 0-44.153-20.306-44.153-44.586V331.984c0-13.685-10.596-24.28-24.28-24.28s-24.28 10.595-24.28 24.28v209.708c0 26.04 8.834 48.117 26.927 66.22 18.102 18.102 39.736 26.927 65.787 26.927 26.04 0 47.9-9.052 66.22-26.928 17.885-17.452 26.927-39.293 26.927-66.22V138.618c0-37.965-13.797-70.524-40.61-97.564C424.367 13.788 391.81 0 353.843 0 315.87 0 283.31 13.788 256.27 41.054c-26.812 27.04-40.61 59.6-40.61 97.564V612.317c0 49.89 17.66 92.27 52.535 127.146S345.452 792 395.34 792s93.147-16.773 128.466-52.093c35.318-35.318 52.535-78.576 52.535-127.59v-358.91c0-13.694-10.595-24.29-24.28-24.29z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Dokumenty od Vás</div>
						<div class="link-box__tip">Nahrajte</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-3 mb-5">
				<a routerLink="/onboarding-documents" class="link-box">
					<div class="link-box__content">
						<!-- document -->
						<svg class="icon icon--xxxl icon--white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M89.6 80.6c4.3-4.8 6.8-11 6.8-17.6 0-9.5-5.1-17.9-12.8-22.4h.4V1.2H0v97.6h44.6v-7.7H7.7V8.9h68.7v28.8c-2-.5-4-.8-6.1-.8C55.8 36.9 44 48.6 44 63s11.7 26.2 26.2 26.2c4.8 0 9.4-1.3 13.4-3.7L94 98.6l6-4.8-10.4-13.2zm-7.5-3.4c-3.3 2.8-7.5 4.3-11.9 4.3-10.2 0-18.5-8.3-18.5-18.5S60 44.5 70.2 44.5 88.7 52.8 88.7 63c0 5.5-2.4 10.7-6.6 14.2zm-16-57.4H17.5v7.7h48.6v-7.7zM17.5 47.9h24.6v-7.7H17.5v7.7z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Nástupní dokumenty</div>
						<div class="link-box__tip">Přečtěte si</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-3 mb-5">
				<a routerLink="/satisfaction-questionary" class="link-box">
					<div class="link-box__content">
						<!-- exchange-star -->
						<svg class="icon icon--xxxl icon--white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path d="M.5 7.9V6.7h6.2L9 0l1.1.4-2.6 7.5h-7zm2 .6l4.2 2.2L5 15.4l4.6-2.8 4.6 2.8-1.7-4.7 4.2-2.2.5 1.1-3.3 1.7 2.5 6.9L9.6 14l-6.8 4.2 2.4-6.9-3.3-1.7.6-1.1zm8.3-.6L9.6 3.2l1.1-.3 1 3.8h7v1.2h-7.9z"/></svg>
						<svg class="icon icon--xxxl icon--white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path d="M.5 7.9V6.7h6.2L9 0l1.1.4-2.6 7.5h-7zm2 .6l4.2 2.2L5 15.4l4.6-2.8 4.6 2.8-1.7-4.7 4.2-2.2.5 1.1-3.3 1.7 2.5 6.9L9.6 14l-6.8 4.2 2.4-6.9-3.3-1.7.6-1.1zm8.3-.6L9.6 3.2l1.1-.3 1 3.8h7v1.2h-7.9z"/></svg>
						<svg class="icon icon--xxxl icon--white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path d="M.5 7.9V6.7h6.2L9 0l1.1.4-2.6 7.5h-7zm2 .6l4.2 2.2L5 15.4l4.6-2.8 4.6 2.8-1.7-4.7 4.2-2.2.5 1.1-3.3 1.7 2.5 6.9L9.6 14l-6.8 4.2 2.4-6.9-3.3-1.7.6-1.1zm8.3-.6L9.6 3.2l1.1-.3 1 3.8h7v1.2h-7.9z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Dotazník spokojenosti</div>
					</div>
				</a>
			</div>
		</div>
	</div>
	<div class="my-md-8" *ngIf="showElement">
		<h2 class="h5-responsive montserat-black text-uppercase">DALŠÍ INFORMACE K NÁSTUPU, ANEB JAK SE ŽIJE V {{company}}</h2>
		<div class="row mt-4">
			<div class="col-6 col-lg-4 mb-5"  *ngIf="showElement">
				<a routerLink="/what-awaits" routerLinkActive="active" class="link-box link-box--white-red link-box--3col">
					<div class="link-box__content">
						<!-- date -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M99.7 31.6V10.8h-19V0h-21v10.8H39.5V0h-21v10.8H.3V100h99.4V43.3h-6.9v49.8H7.2V17.7h11.2v14.9h21V17.7h20.3v14.9h21V17.7h12.1v13.9h6.9zm-67.2-6h-7.2V6.9h7.2v18.7zm41.3 0h-7.2V6.9h7.2v18.7zM32.5 55.5H18.1v6.9h14.4v-6.9zm23.8 0H41.8v6.9h14.4v-6.9zm9.3 0v6.9H80v-6.9H65.6zm-21 19.4H18.3v6.9h26.4v-6.9zm35.6 0H53.7v6.9h26.5v-6.9z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Co Vás čeká a nemine</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-4 mb-5"  *ngIf="showElement">
				<a routerLink="/life-in-kb" class="link-box link-box--white-red link-box--3col">
					<div class="link-box__content">
						<!-- domluvite-se-sami -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M86.7 12.4a33.8 33.8 0 0 0-35.6.4 30.4 30.4 0 0 0-21.8-8.1C21 4.9 4.4 10.2.8 28.1a31 31 0 0 0 5.6 26.5l5.7-4.5c-3-3.8-6.2-10.5-4.2-20.5C11.3 12.9 28.8 12 29.6 11.9c.1 0 9.5-.4 16 5.6L25.3 37.8l3.4 2.5c9.9 7.5 18.8 3.3 22.1.1l8.5-8.5 25.3 24.8c.1.7 0 1.5-.2 2.2-1.4 1-1.9 1.3-3.2 1.2l-19.3-19-5.1 5.2 18.6 18.2c.4.6 1.1 2.2-.1 3.2-1.7 1.5-2.9 1.2-3 1.2l-.7-.8-17.9-17.8-5.1 5.1L66.3 73c.4.5 1.7 2.1.3 3.4-.5.5-1.2.9-1.9 1.1a8.9 8.9 0 0 0-4-5.8 10 10 0 0 0-6-1.8c-.6-2.2-2-4.1-3.9-5.2a11 11 0 0 0-4.2-1.7 8.6 8.6 0 0 0-4.1-6.6c-1.3-.9-2.8-1.5-4.3-1.8a8.4 8.4 0 0 0-4.1-6.7 9.6 9.6 0 0 0-11.9.4l-7 6.8-.4.4c-1.9 2.5-4.3 8.5.6 13.1a9.3 9.3 0 0 0 5.6 2.7 8.9 8.9 0 0 0 2.8 5.7 9.6 9.6 0 0 0 5.5 2.7 8.1 8.1 0 0 0 2.9 5.6 9.5 9.5 0 0 0 6.7 2.8l.6-.1a8 8 0 0 0 2.6 4.3 9.5 9.5 0 0 0 6.7 2.8c2 0 4-.6 5.7-1.8l8.3-8.8 1.5.1c2.8 0 5.4-1.1 7.4-3a8.7 8.7 0 0 0 3-5.8c2.1-.4 4-1.4 5.6-2.8 1.8-1.5 3-3.7 3.1-6 2-.4 3.8-1.2 5.4-2.5a8.4 8.4 0 0 0 3.2-6.2c2.7-2.5 5.2-7.7 5.8-8.9 3.2-8.2 5.5-27.1-11.1-37zM20.3 60.1l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.2-1.1-.4-2.6.1-3.3zm8.4 8.5l6.7-6.6s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-5.3 5.6-2.1 2.1-.3.4-.1.1c-.9.4-2 .2-2.7-.6-1.1-1.1-.3-2.7.1-3.3zM37 80.2c-.7-.9-.8-2.1-.1-3l1.4-1.5 5.5-5.4s1-.9 2.9.4c.5.3 1.2.8.8 1.9l-7.6 8.1c-.9.6-2.2.3-2.9-.5zm20.4-.5l-7.6 8.1c-1 .5-2.2.3-2.9-.5-1.1-1.1-.3-2.6.1-3.3l6.7-6.6s1-.9 2.9.4c.4.4 1.2.9.8 1.9zm33.5-32.8c-.6 1.3-1.3 2.6-2.1 3.8l-29.6-29-13.5 13.6a8.7 8.7 0 0 1-8.9 1.4l16.5-16.5c1.3-1 14.4-10.6 29.6-1.5 15.4 9.1 8.2 27.6 8 28.2z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Život v {{company}}</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-4 mb-5" *ngIf="showKBOnly">
				<a routerLink="/benefits" class="link-box link-box--white-red link-box--3col">
					<div class="link-box__content">
						<!-- ls-startup -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M85.2 1.8L84.3 0h-2c-.4 0-29.8.6-42.8 33.5-18 .7-28.4 29.9-28.8 31.2l-1.7 5 24.5-2.5v4l6.7 3.6-5.8 10.9 5.9 3.1 5.8-10.9 7.1 3.8 1.6-1c.1 0 .7-.5 1.8-1.2L66.8 100l3.2-3c7.5-6.9 19-24.1 10.4-41.5C90 41.8 96.1 23.1 85.2 1.8zM33.9 60.5L19 62c3.5-7.9 10.2-19 18.2-21.4-1.8 6.5-2.9 13.2-3.3 19.9zm19 13.4L49.2 72l6.2-11.7-5.9-3.1-6.2 11.7-3.2-1.7c0-1.2.1-2.3.1-3.4l4.7-26c3.5-9.8 8.5-16.6 13.7-21.2l18.7 9.9 3.1-5.9-15.8-8.5c4.8-2.9 10.1-4.7 15.6-5.4 7.2 15.8 4.9 31.8-7 47.7-5.7 7.5-12.6 14.1-20.3 19.5zm15.8 14.9l-6.8-13.5c5.1-4.1 9.7-8.6 13.9-13.6 3.8 11.5-2.9 22-7.1 27.1zM73.5 44c3.1-5.8.9-13.1-4.9-16.2s-13.1-.9-16.2 4.9c-3.1 5.8-.9 13.1 4.9 16.2 1.7.9 3.6 1.4 5.6 1.4 4.5 0 8.5-2.4 10.6-6.3zm-5.9-3.1c-1.4 2.6-4.6 3.6-7.2 2.2-2.6-1.4-3.6-4.6-2.2-7.2s4.6-3.6 7.2-2.2c1.2.7 2.2 1.8 2.6 3.2.4 1.3.3 2.8-.4 4z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">Benefity {{company}}</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-4 mb-5" *ngIf="showElement">
				<a routerLink="/kb-in-numbers" class="link-box link-box--white-red link-box--3col">
					<div class="link-box__content">
						<!-- ls-investovani -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M32.9 50.2l15.3 10.2 25.4-25.7v3.5h6.2V27.7L75.4 24c-3.8.5-7.7 1.2-11.4 2.1l1.4 5.7c.3-.1 2.6-.4 3-.5l-21 21.1L32 42.1 15.2 59.6l4.5 4.3 13.2-13.7zM50.8 8.4V1.5h-6.2v6.9H0v6.2h3.7V78h24.9l-9.8 20.4h6.8l9.9-20.5h28.8l2.5 4.9h-29V89h32l4.7 9.4 6.9-.1L71.1 78h24.2V14.6h4.6V8.4H50.8zm38.4 63.4H9.9V14.6h79.3v57.2z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">KB Group v číslech</div>
					</div>
				</a>
			</div>
			<div class="col-6 col-lg-4 mb-5" style="display: none;">
				<a href="{{getJournalUrl()}}" class="link-box link-box--white-red link-box--3col">
					<div class="link-box__content">
						<!-- newsletter -->
						<!-- TODO: ikona chybí v ikonsetu, možná je v novější verzi KB online  -->
						<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path d="M56.5 17.6C38.8 16 29.2 21 24.4 34c-3.9 10.7 1.9 23.6 7.1 35a156 156 0 0 1 4.7 10.9l.7 2h25.6l.8-1.7c.1-.1 6.9-14.3 11.9-26.1 3.8-9 3.5-18.6-.9-25.8a22.1 22.1 0 0 0-17.8-10.7zm-5 58.2h-3V50.5l-.1-1.6 3.3-.1c-.3 5.4-.6 14-.2 27zm18.1-24.1c-3.9 9.2-9 20-10.9 24.1h-3.2c-.3-13.2 0-21.7.3-27H59c2.1-.4 4-1.5 5.2-3.2.9-1.4 1.1-3 .7-4.6-.3-1.6-1.3-3-2.8-3.9a7.8 7.8 0 0 0-5.8-.2c-.4.2-4.2 2-4.2 5.2l-.1 2-.1.8-4.2.1c-.8-3.2-2.6-6.5-6.4-6.5-3.5 0-5.2 2.3-5.4 4.6-.1 2.5 1.6 5.5 4.5 5.8l3.9.2.1 1.5v25.2h-3.3c-1.2-3-2.6-6.1-4.1-9.3-4.9-10.8-10-22-7-30.4 3.9-10.4 10.9-13.8 26-12.5 5.8.5 10.4 3.2 13.1 7.8 3.3 5.5 3.5 13.1.5 20.3zM56 44.8v-.4l.1-2.3c0-.3.8-1.1 1.7-1.5l1.3-.2 1 .2c.1.1.5.3.8 1.2.2.5.1 1-.2 1.5-.6.7-1.5 1.3-2.5 1.5H56zm-12.5.2l-2.6-.1c-.4 0-1-.9-.9-1.6 0-.3 0-.8 1.4-.8.9-.1 1.7 1 2.1 2.5zm-5.7 45.6h24.6v-6H37.8v6zm0 9.4h24.6v-6H37.8v6zM51.9 0h-6v12.7h6V0zm29.7 38.9v6h14.2v-6H81.6zM4.3 45h14.1v-6H4.3v6zm24-26.8L17.7 9l-4 4.5 10.5 9.3 4.1-4.6zm57.3-6l-4-4.5-10.8 9.5 4 4.5 10.8-9.5z"/></svg>
						<div class="mt-2 mt-md-3 link-box__caption">KB journal</div>
					</div>
				</a>
			</div>
		</div>
	</div>
</div>
