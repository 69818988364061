import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { UserService, User } from '../../../user/user.service';

@Component({
  selector: 'ob-communication-list',
  templateUrl: 'communication-list.component.html',
})
export class CommunicationListComponent {
	@Output() selectedPerson = new EventEmitter;


  protected user : User;

  constructor (protected userService: UserService){}

  // mock data
  communicationList = [
    {
      id: 3345,
      name: 'Helena Dvořáková',
      position: 'Recruiter',
      text: 'Na svého recruitera se neváhej obrátit s dotazy všeho druhu. Ať už se jedná o informace ze smlouvy, podrobnosti k nástupu, čerpané benefity.',
      btnText: 'Napsat recruiterovi',
      email: ''
    },
    {
      id: 6654,
      name: 'Radim Charvát',
      position: 'Manažer',
      text: 'Na svého budoucího nadřízeného můžeš směřovat konkrétní dotazy k tvojí práci.',
      btnText: 'Napsat Manažerovi',
      email: ''
    },
  ];

  ngOnInit(){

    this.user = this.userService.getUserStorage();
    if(!this.user) console.log('WARN: user not loaded');
    //console.log('this.user in communication component', this.user, this.user.name, this.user.userDetail);

    this.communicationList[1].name = this.user.userDetail.personalManager.name;
    this.communicationList[1].email = this.user.userDetail.personalManager.email;
    this.communicationList[0].name = this.user.userDetail.recruiter.name;
    this.communicationList[0].email = this.user.userDetail.recruiter.email;
  };

  selectPerson(person: object, event?: any) {
  	event.preventDefault();
    this.selectedPerson.emit(person);
  }

}
