import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { LoginService, httpOptions } from '../login/login.service'
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { Content } from './content.model';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  protected url: string;
    private httpOptions;

    constructor (protected http: HttpClient,
                 protected loginService: LoginService
                ) {
        this.url = Configuration.get('d60.apiUrl', true);
        this.httpOptions = httpOptions;
    }
 
    getContent(uid: string): Observable<any> {
      return this.http
        .get<Content>(this.url+"/content/content/" + uid,  this.httpOptions );
    }
}
