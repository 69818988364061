import { Pipe, PipeTransform } from '@angular/core';

/**
 * Map message type to bootstrap classes.
 */
@Pipe({
  name: "pfMessageTypeAlias"
})
export class MessageTypeAliasPipe implements PipeTransform {
  transform(messageType: string): string {
    // TODO: Move aliases to service
    if (messageType == 'error') return 'danger';
    return messageType
  }
}
