import { Component } from '@angular/core';
import { UserService } from '../../user/user.service';
import { Content } from '../../shared/content.model';
import { ContentService } from '../../shared/content.service';

@Component({
  selector: 'ob-what-awaits',
  templateUrl: 'what-awaits.component.html',
})
export class WhatAwaitsComponent {
  activeSlideIndex:number = 0;
  vertCarousel1Index:number = 0;
  vertCarousel2Index:number = 0;
  vertCarousel3Index:number = 0;
  firstDateInWork: Date;

  content_kuba: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_training: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_workshop: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_unipass: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_pc: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};
  content_phone: Content = {uid: "",name : "", description : "", author : "", content : "", date : new Date};


  content_kuba_uuid: string = "fa77b307-6d06-4a23-bd6f-f9b319b49335";
  content_training_uuid: string = "8c2d1207-df63-4c6d-9789-a4ea6a49961c";
  content_workshop_uuid: string = "44bc1196-6ced-42d8-80b3-e4c42ea1d3fe";
  content_unipass_uuid: string = "22f306c8-9bc0-4db8-8b90-3b1615deaf58";
  content_pc_uuid: string = "d501f806-d199-40d2-8bef-538e6ee7ef2e";
  content_phone_uuid:string = "ffc6ba49-1d58-4374-bd37-71b5180d57ad";

  company:string = '';
  showKBOnly: boolean = true;

  constructor(
    protected userService: UserService,
    private contentService: ContentService
   ) {}

  ngOnInit() {
  	let user = this.userService.getUserStorage();
    // console.log(this.user)
    if(!user) console.log('WARN: user not loaded');

    this.firstDateInWork = new Date(user.userDetail.firstDateInWork);

    this.contentService.getContent(this.content_kuba_uuid).subscribe(res => {
      this.content_kuba = res;    
    });
    this.contentService.getContent(this.content_training_uuid).subscribe(res => {
      this.content_training = res;    
    });
    this.contentService.getContent(this.content_workshop_uuid).subscribe(res => {
      this.content_workshop = res;    
    });
    this.contentService.getContent(this.content_unipass_uuid).subscribe(res => {
      this.content_unipass = res;    
    });
    this.contentService.getContent(this.content_pc_uuid).subscribe(res => {
      this.content_pc = res;    
    });
    this.contentService.getContent(this.content_phone_uuid).subscribe(res => {
      this.content_phone = res;    
    });

    this.company = this.userService.getUserStorage().userDetail.company;
    if('KB'===this.company){
      this.showKBOnly = true;
    }else{
      this.showKBOnly = false;
      this.activeSlideIndex = 1;
      this.vertCarousel1Index = 1;
    }
  }

  changeActiveSlide(index, event) {
  	event.preventDefault();
  	this.activeSlideIndex = index;
  }
}
