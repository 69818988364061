<div class="login">
	<div *ngIf="showEnv" style="font-weight: bold;padding: 5px;position: absolute;left: 0px;top: 0px;color:azure" [ngStyle]="{'background-color': envColor}">
		Prostředí: {{envName}}
	</div>
	<div class="login__form p-3 mt-6 login__form--error">
		<div class="mb-4 d-flex align-items-center border-bottom pb-3">
			<img class="icon icon--logo" id="logo-kb" src="../../assets/images/KB-Group.png"/>
			
			<div class="logo-motto ml-3">Portál pro budoucí zaměstnance</div>
		</div>
		<h1 class="h6-responsive montserat-black">Přihlášení</h1>
		<div class="mt-3">
     	<form (keydown)="keyDownFunction($event)" [formGroup]="form">
				<div class="form-group">
					<label for="">E-mail</label>
					<input type="text" class="form-control" [formControl]="email" [ngClass]="{ 'is-invalid': submitted && email.errors }">
					<div *ngIf="submitted && email.errors" class="invalid-feedback">
						<div *ngIf="email.errors.required">Zadejte email</div>
						<div *ngIf="email.errors.email">Email nemá správný formát</div>
					</div>
				</div>
				<div class="form-group">
					<label for="">Heslo</label>
					<input type="password" class="form-control" [formControl]="password" [ngClass]="{ 'is-invalid': submitted && password.errors }">
					<div *ngIf="submitted && password.errors" class="invalid-feedback">
						<div *ngIf="password.errors.required">Zadejte heslo</div>
					</div>
				</div>
		</form>
		</div>
		<div *ngIf="!loginSuccess" class="my-3">
			<p class="text-danger">Nesprávná kombinace jména a hesla</p>
		</div>
		<div class="text-right mt-3">
			<button class="btn btn-primary" (click)="onSubmit()">Přihlásit</button>
		</div>
		<div class="login__preloader" *ngIf="isSubmitting">
			<div class="loader__container">
                <div class="loader loader--white">
                    <div class="loader__backdrop">
                        <div class="loader__spinner loader__spinner--lg"></div>
                    </div>
                </div>
            </div>
		</div>
	</div>
	<a routerLink="/login-reset" routerLinkActive="active" class="mt-3 mb-6 link--underlined">Zapomenuté heslo</a>
</div>
<!-- version 30 09 2022 -->
