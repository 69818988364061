import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QuestionaireType } from '../shared/questionary.model';
import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { AppConstants } from '../app.constants';

export const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'Accept': 'application/json',
   }),
  withCredentials: true
};

export interface User{
    uid? : string;
    name: string;
    firstname: string;
    surname: string;
    email: string;
    rolesInDB: string;
    userDetail: UserDetail;
    userDocument: UserDocument[];
    userFiles: StoredFile[];

}

export interface Employee {
  email: string
  name: string
  rolesInDB: string
  uid: string
}

export interface UserDetail{
    startDate: string
    firstDateInWork: string
    startAddress: string
    personalManager: Manager
    vignette: Vignette
    signDate?: string
    signAddress?: string
    arm: string
    recruiter?: Employee
    singAddress: string
    hrContact?: Employee
    personalQuestionnaire: boolean
    typeDocuments: UserTypeDocument[]
    isPersonalQuestionaireFilled: boolean | undefined
    isSatisfactionQuestionaireFilled: boolean | undefined
    showOptimalization: boolean
    amount: number
    company: string
}

export interface UserTypeDocument {
    uid: string
    userUID: string
    typeDocument: TypeDocument
    delivery: string;
}

export interface UserDocument{
    uid: string
    file: StoredFile
}

export interface StoredFile{
    uid: string
    name: string
    mime: string
    documentType: TypeDocument
    deleted: boolean
}

export interface Manager {
    uid: string
    name: string
    photo: {
      mime: string
      name: string
      uid: string
    }
    email: string
    oscis: string
    position: string
    description: string
    model: StoredFile
    deleted: boolean;
}

export interface Vignette {
  uid: string
  name: string
  photo: {
    mime: string
    name: string
    uid: string
  }
  oscis: string
  position: string
  model: StoredFile
  deleted: boolean;
  question1: string;
  question2: string;
  question3: string;
}

export interface TypeDocument{
    type: string
    name: string
    mime: string
    documentType: string
    description: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {

  userName: string = '';

  constructor(
    protected http: HttpClient
  ){};

  public getUserName() {
    if(!this.userName) {
      this.userName = this.getUserStorage().name;
    }
    return this.userName;
  }

  public getUserStorage(): User {
    // debugger;
      let localStorageItem = JSON.parse(localStorage.getItem('User'));
      return localStorageItem == null ? []: localStorageItem.user;
  }

  public setUserStorage(user: User): void {
      this.userName = user.name = `${user.firstname} ${user.surname}`.trim();
      localStorage.setItem('User', JSON.stringify({ user: user }));
      localStorage.removeItem(AppConstants.INFODIALOG_AUTOLOGOUT);
  }

  public clearStorages(): void {
      this.userName = '';
      localStorage.clear();
  }

  public getDocInfo(docType: string): UserDocument[] {
    let docArray: UserDocument[] = this.getUserStorage().userDocument;
    return docArray.filter(doc => { doc.file.documentType.documentType == docType});
  }

  public isRequired(docType: string): boolean {
    return this.getUserStorage().userDetail.typeDocuments.some(doc => (doc.delivery != AppConstants.DOCUMENTDELIVERY_NONE) && (doc.typeDocument.documentType == docType));
  }

  public typeExist(docType: string):boolean{
    return this.getUserStorage().userDetail.typeDocuments.some(doc => (doc.typeDocument.documentType == docType));
  }

  public getFileDescription(docType: string): string {
    return this.getUserStorage().userDetail.typeDocuments.filter(doc => (doc.typeDocument.documentType == docType))[0].typeDocument.description;
  }

  public getUserDocuments(docType: string): UserDocument[] {
    // debugger;
    return this.getUserStorage().userDocument.filter(doc => doc.file.documentType.documentType == docType);
  }

  public getUserFiles(docType: string): StoredFile[] {
    // debugger;
    return this.getUserStorage().userFiles.filter(doc => doc.documentType.documentType == docType);
  }

  public isUploaded(docType: string): boolean {
    // debugger;
    return this.getUserStorage().userDocument.some(doc => doc.file.documentType.documentType == docType);
  }

  public getUid(): string {
      let user = this.getUserStorage();
      let uid : string = '';
      if(user.uid)
          uid = user.uid;
      else
          console.log('WARN: No uid found in local storage!');
      return uid;
  }

  public getRecruiter(): Employee {
    return this.getUserStorage().userDetail.recruiter;
  }

  public isFilled(questionaryType: QuestionaireType): boolean | undefined {
    let userDetail = this.getUserStorage().userDetail;
    switch(questionaryType) {
      case QuestionaireType.PERSONAL:
        return userDetail.isPersonalQuestionaireFilled;
      case QuestionaireType.SATISFACTION:
        return userDetail.isSatisfactionQuestionaireFilled;
      default:
        console.log('Unknown questionary type');
        return false;
    }
  }

  public isShowSalaryOptiomalization() {
      return this.getUserStorage().userDetail.showOptimalization;
  }

  public setAmount(amount: number) {
    let url = Configuration.get('d60.apiUrl', true);
    let user = this.getUserStorage();
    return this.http.put<any>(url + '/users/' + user.uid + '/amount', { amount: amount }, { ...httpOptions });
  }

}
