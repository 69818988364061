<div class="communication__message communication__cont" ngClass="wrapperClasses">
	<h6 *ngIf="selectedPerson" class="communication__message__caption"><span class="montserat-black">Nová zpráva pro {{selectedPerson.position}}</span> ({{selectedPerson.name}})</h6>
	<div class="form-group form-group--textarea">
		<label class="" for="textarea-id">Text zprávy</label>
		<textarea rows="8" cols="20" id="textarea-id" class="form-control" [formControl]="message"> </textarea>
	</div>
	<div class="text-left">
		<button class="btn btn-primary mr-1" [disabled]="!message.value" (click)="sendMessage()">Odeslat</button><button class="btn btn-outline-secondary" (click)="unselectPerson()">Zrušit</button>
	</div>
	<div class="loader__container loader__container--full-cont" *ngIf="isSubmitting" >
        <div class="loader loader--transparent">
            <div class="loader__backdrop">
                <div class="loader__spinner loader__spinner--lg"></div>
            </div>
        </div>
    </div>
</div>