import { Component, OnInit } from '@angular/core';
import { Subscription }   from 'rxjs/Subscription';

import { Message } from './message.interface';
import { MessageService } from './message.service';

@Component({
  selector: 'kb-message',
  templateUrl: 'message.component.html',
  styleUrls: ['message.component.scss'],
})
export class MessageComponent {
  messages: Message[] = [];
  allowTimeoutClean: boolean = true;
  subscriptions: Subscription [];

  constructor(private messageService: MessageService) {
    this.subscriptions = [];
    this.subscriptions.push(messageService.all$.subscribe(msg => {
      this.messages.push(msg);
      if (this.allowTimeoutClean) {
        setTimeout(() => { if (!msg["keep"]) this.messageService.removeMessage(msg, this.messages)}, msg["timeout"] || 10000)
      }
    }));
  }

  closeMsg(message: Message) {
    this.messageService.removeMessage(message, this.messages);
  }

  keepMsg(message: Message) {
    message.keep = true;
  }

  ngOnDestroy() {
    // prevent memory leak when component destroyed
    for (let subs of this.subscriptions) {
      subs.unsubscribe();
    }
  }
}
