import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, ValidatorFn, AbstractControl } from '@angular/forms';

import { rodnecislo } from 'rodnecislo';

@Directive({
  selector: '[birthNumberValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: birthNumberValidatorDirective, multi: true}]
})
export class birthNumberValidatorDirective implements Validator {
  validate(control: AbstractControl): {[key: string]: any} | null {
    let length: number = 0;
  	if(control.value) {
  		length = control.value.length;
  	} else {
  		length = 0;
  	}
    return length > 0 ? birthNumberValidate()(control) : null;
  }
}

/** A hero's name can't match the given regular expression */
export function birthNumberValidate(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const rc = rodnecislo(control.value);
    return rc.isValid() ? null : { 'birthNumberValidator': {value: 'Rodné číslo není zadáno správně'} };
  };
}