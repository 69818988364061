import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { LoginComponent } from './login.component';
import { LoginNewPassComponent } from './login-new-pass.component';
import { LoginNewPassInfoComponent } from './login-new-pass-info.component';

import { LoginResetComponent } from './login-reset.component';
import { LoginFirstTimeComponent } from './login-first-time.component';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';  

@NgModule({
  imports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,    
  ],
  declarations: [
    LoginComponent,
    LoginNewPassComponent,
    LoginResetComponent,
    LoginFirstTimeComponent,
    LoginNewPassInfoComponent
  ],
  exports: [
    LoginComponent,
  ]
})
export class LoginModule { }
