<a href="." class="{{linkClass}}"
  ng-disabled="!documents || documents.length == 0" (click)="open(downloadsModal, $event)" >
  <div class="{{contentClass}}">
    <ng-content></ng-content>
    <div class="{{captionClass}}">{{caption}}</div>
  </div>
</a>
<ng-template #downloadsModal>
  <div class="navbar-dropdown__content box-shadow--lg navbar-dropdown__content--lg">
    <div class="navbar-dropdown__header">
      <div class="row">
        <div class="col-6 order-2 text-right">
          <button type="button" class="border-0 bg-transparent navbar-dropdown__close" (click)="closeModal()">
            <span class="navbar-dropdown__close__text bold">Zavřít</span>
            <svg class="icon icon--grey-dark icon--baseline icon--lg" viewBox="0 0 32.526 32.526" xmlns="http://www.w3.org/2000/svg">
              <path d="M32.53 2.83L29.7 0 16.26 13.44 2.83 0 0 2.83l13.44 13.43L0 29.7l2.83 2.83L16.26 19.1 29.7 32.52l2.83-2.83L19.1 16.26z"></path>
            </svg>
          </button>
        </div>
        <div class="col-6 order-1">
          <span class="u-text--red bold">{{caption}}</span>
        </div>
      </div>
    </div>
    <div class="modal-body">
      <div *ngFor="let doc of documents">
          <a *ngIf="doc.deleted != true" href="{{computeUrl(doc.uid)}}">{{doc.name}}</a>
          <span *ngIf="doc.deleted == true">{{doc.name}} <small>(soubor byl odstraněn)</small> </span>
      </div>
    </div>
  </div>
</ng-template>
