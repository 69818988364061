import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export enum DialogType {
  ERROR = "ERROR",
  WARNING = "WARNING",
  INFORMATION = "INFORMATION",
  QUESTION = "QUESTION"
}

export class DialogData {
  public title: string;
  public message: string;
  public question: string;
  public dialogType: DialogType;
}

@Component({
  selector: 'app-informationdialog',
  templateUrl: './informationdialog.component.html',
  styleUrls: ['./informationdialog.component.scss']
})
export class InformationdialogComponent implements OnInit {

  get getDialogType() { return DialogType; }
  hasQuestion() : boolean { return this.data.question != undefined && this.data.question.length > 0; }

  constructor(
    public dialogRef: MatDialogRef<InformationdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
  }

  onClick(retValue: boolean) {
    this.dialogRef.close(retValue);
  }
}
