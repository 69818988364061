<div class="messages messages--top">
    <div *ngFor="let message of messages"
         class="message"
         role="alert"
         (mouseenter)="keepMsg(message)">
         <div class="alert alert-{{ (message.type | pfMessageTypeAlias) || 'info' }} alert-dismissible shadow-3">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="closeMsg(message)">
                <span aria-hidden="true">&times;</span>
            </button>
            <span *ngIf="message.title">{{ message.title }}</span>
            <p *ngIf="message.description && !message.forSelection">{{ message.description }}</p>
            <p *ngIf="message.description && message.forSelection">
                <br />
                <input type="text" onmouseover="this.select();" [value]="message.description" class="form-control">
            </p>
        </div>
    </div>
</div>
