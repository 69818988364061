import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { anyRequiredValidator } from '../shared/validators';
import { LoginService } from './login.service';


@Component({
  selector: 'ob-login-first-time',
  templateUrl: 'login-first-time.component.html',
})
export class LoginFirstTimeComponent {

  email =  new FormControl(undefined ,[Validators.required]);
  requestSuccess: boolean = true;

  constructor (
    protected loginService: LoginService,
    private router: Router
  ) {}

  ngOnInit(){
    this.loginService.logout();
    this.email.setValue('');
  };

  keyDownFunction(event) {
    if(event.keyCode == 13) {
       this.onSubmit()
    }
  }

  onSubmit() {
    // debugger;
    this.loginService.resetPasswordRequest(this.email.value)
      .subscribe(
        res => {
          if(res == null || 204 == res.status) {
            this.router.navigate(['/login-new-pass-info']);
          } else {
            this.requestSuccess = false;
          }
        },
        err => {
          this.requestSuccess = false;
        });
  }

}
