import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LoginService, httpOptions } from '../login/login.service'

import { Configuration } from '@cz.kb.fe.lib/lib-configuration';
import { QuestionaryResponse } from './questionary.model';

@Injectable({
  providedIn: 'root'
})
export class QuestionaryService {
    protected url: string;
    private httpOptions;

    constructor (protected http: HttpClient,
                 protected loginService: LoginService
                ) {
        this.url = Configuration.get('d60.apiUrl', true);
        this.httpOptions = httpOptions;
    }

    getQuestionaryType(): Observable<any> {
      return this.http
        .get(this.url + "/enumerations/questionaireType", this.httpOptions );
    }

    getQuestionary(userUid: string): Observable<any> {
      return this.http.get<QuestionaryResponse[]>(this.url + '/users/' + userUid + '/questionaire', this.httpOptions);
    }

    postQuestionary(uid: string, json: string): Observable<any> {
      return this.http
        .post<any>(this.url + "/users/" + uid + "/questionaire", json, this.httpOptions ).pipe(
          tap(_ => this.loginService.updateLocalStorage().subscribe() )
        );
    }

}
