import { Component } from '@angular/core';

@Component({
  selector: 'ob-show-answer',
  templateUrl: 'show-answer.component.html',
})
export class ShowAnswerComponent {
	answerShown: boolean = false;

	showAnswer(event?:any) {
		event.preventDefault();
		this.answerShown = true;
	}
  
}
