import { DEFAULT_LOGGING_PROVIDERS, ERROR_HANDLER_PROVIDERS, Logger, ConsoleLogger, LoggerOptions, LogLevels } from '@cz.kb.fe.lib/lib-logging';

import { Configuration } from "@cz.kb.fe.lib/lib-configuration";

export const loggerProviderFactory = () => {
    // You can replace the logger with a different one
    // You can also pass here your custom log formatter
    return new ConsoleLogger(new LoggerOptions({
        name: 'app-hr-onboarding',
        allowConsoleCatch: Configuration.get('logging.allowConsoleCatch'),
        level: LogLevels[Configuration.get('logging.level')] || LogLevels.LOG,
        logBrowserData: Configuration.get('logging.logBrowserData')
    }));
}

export const CONFIGURED_LOGGING_PROVIDERS = [
    ...ERROR_HANDLER_PROVIDERS,
    {
        provide: Logger,
        useFactory: loggerProviderFactory
    }
];
