import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProcessPointComponent } from './process-point.component';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ProcessPointComponent,
  ],
  exports: [
    ProcessPointComponent,
  ],
  providers: [
  ]
})
export class ProcessPointModule { }
