import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared';

import { ModalModule } from 'ngx-bootstrap/modal';

import { PeopleComponent } from './people.component';
import { SafeHtmlPipe } from '../../../core/saveHtmlPipe';

@NgModule({
  imports: [
  	RouterModule,
  	SharedModule,
    ModalModule.forRoot(),
    CommonModule
  ],
  declarations: [
    PeopleComponent,
    SafeHtmlPipe
  ],
  exports: [
    PeopleComponent,
    SafeHtmlPipe
  ]
})
export class PeopleModule { }
