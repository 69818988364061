<div class="container my-5">
	<ob-breadcrumb pageName="INFORMACE K NÁSTUPU"></ob-breadcrumb>
	<h1 class="h1-responsive montserat-black text-uppercase mb-3">JAK TO BUDE S NÁSTUPEM</h1>
	<div class="bg-white p-3 px-lg-10 py-md-4 py-lg-6 lifted-shadow-wrap mb-8">
		<div class="row flex-md-row text-center text-md-left align-items-center justify-content-center">
			<div class="col-12 col-md text-md-right">
				<span class="h5-responsive montserat-black">DO VAŠEHO NÁSTUPU ZBÝVÁ</span>
			</div>
			<div class="col-12 col-md-auto d-none d-md-block">
				<!-- arrow-right -->
				<!-- <svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" width="55" height="130" viewBox="0 0 55 130"><path d="M5.14 130l-1.55-1.23 48.85-62.7L.94 1.24 2.47 0l52.48 66.06z"/></svg> -->
				<!-- arrow-right-bold -->
				<svg class="icon icon--xxxxl icon--red" xmlns="http://www.w3.org/2000/svg" width="25" height="40" viewBox="0 0 25 40"><path d="M1.35 35.45c-.48.47-.48.95 0 1.42l2.69 2.76c.47.48.95.48 1.43 0l18.27-18.96a.96.96 0 0 0 .27-.7c0-.3-.09-.54-.27-.72L5.47.3c-.42-.41-.9-.38-1.43.1L1.35 3.04c-.48.48-.48.95 0 1.43l14.96 15.48z"/></svg>
			</div>
			<div class="col-12 col-md mt-3 mt-md-0">
				<div class="h1-responsive montserat-black">{{daysToStart}} <span *ngIf="daysToStart == 1">DEN</span><span *ngIf="daysToStart > 1 && daysToStart < 5">DNY</span><span *ngIf="daysToStart > 5">DNŮ</span></div>
				<div class="montserat-black text-muted">Váš první pracovní den je {{firstDateInWork | date:'d.&nbsp;M.&nbsp;y'}}</div>
			</div>
		</div>
		<div class="lifted-shadow"></div>
	</div>
	<!-- TODO: trida active pokud uz je vyplneno -->
	<ob-process-point number="1" [processed]="isPersonalQuestionaryFilled">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">OSOBNÍ DOTAZNÍK</h2>
		<p><b>Svůj osobní dotazník vyplňte co nejdříve. Potřebujeme jej, abychom Vám připravili nástupní dokumenty k podpisu.</b></p>
		<button *ngIf="!this.isPersonalQuestionaryFilled && this.isPersonalQuestionaryLoaded" routerLink="/onboarding-info/questionary" class="btn btn-outline-secondary">Vyplnit dotazník</button>
	</ob-process-point>
	<ob-process-point number="2" [processed]="isDocumentsUploaded">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">VAŠE DOKUMENTY</h2>
		<p><b>Pošlete nám své dokumenty potřebné k nástupu.</b></p>
		<button routerLink="/your-documents" class="btn btn-outline-secondary">Zobrazit seznam dokumentů</button>
	</ob-process-point>
	<ob-process-point number="3" *ngIf="signDate">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">PODPIS SMLOUVY</h2>
		<p><b>{{signDate | date:'d.&nbsp;MMMM'}} (za {{daysToSign}} <span *ngIf="daysToSign == 1">den</span><span *ngIf="daysToSign > 1 && daysToSign < 5">dny</span><span *ngIf="daysToSign >= 5">dnů</span>) přijďte podepsat nástupní dokumenty.</b></p>
		<p>Vše s Vámi podepíše <b class="u-text--red">{{getSignedName()}}</b> a potkáte se spolu na níže uvedené adrese. Dokumenty si můžete přečíst předem.</p>
		<p>Schůzka je <b class="u-text--red">{{signDate | date:'d.&nbsp;MMMM&nbsp;y'}} v {{signDate | date:'H:mm'}}</b></p>
		<p><b class="u-text--red">{{user.userDetail.signAddress}}</b></p>
		<p *ngIf="mapURI">
			<svg class="icon icon--baseline icon--red mr-1" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><path d="M14.17 35.05q0 7.36 3.92 16.96a115.32 115.32 0 0 0 8.6 17.2q4.72 7.6 10.87 15.62 6.15 8.04 8.5 10.83 2.36 2.8 3.93 4.35l8.96-10.27q8.95-10.5 17.9-26.78 8.97-16.3 8.97-27.9 0-14.5-10.52-24.78A34.96 34.96 0 0 0 50 0Q35.22 0 24.7 10.28 14.17 20.54 14.17 35.05z"/><path d="M60.7 38.18c.17-.6-.47-1.52-1.9-1.52H55v2.74h2.85c1.58 0 2.7-.63 2.85-1.22zm-1.9 2.43H55v2.43c0 .32.3.64.63.64h3.17c3.33 0 2.38-3.38 0-3.06zm-8.88-23.7l-22.08 19h4.43v16.88c0 1.2.95 2.1 2.24 2.1h30.97c1.26 0 2.2-.9 2.2-2.1V35.9h4.5zm.3 28.12H45.8l-.63-.6-5.4-4.1v3.64c0 .45.3.76.63.76h.3v.15h-4.75v-.32h.3c.5 0 .64-.3.8-.76v-7.43c0-.47-.3-.76-.62-.76h-.8v-.16h4.78v.3h-.3c-.3 0-.63.3-.63.77v3.05l6.03-3.35c.47-.3.32-.6 0-.6v-.16h4.44v.17c-.47 0-.8.15-1.1.3l-6.35 3.34 6.98 5.17c.32.15.5.32.8.32zm9.2 0h-8.1v-.3c.64 0 .96-.17.96-.76V36.5c0-.45-.32-.75-.95-.75v-.3h8.9c2.07 0 3.34.75 3.34 2.12s-.95 1.98-2.7 2.28c1.9.15 3.33.6 3.33 2.42 0 1.7-1.26 2.75-4.77 2.75z" fill="#fff"/></svg>
			<a target="_blank" href="https://www.google.com/maps/search/?api=1&query={{mapURI}}">viz. mapa</a>
		</p>
		<button routerLink="/onboarding-documents" class="btn btn-outline-secondary">Přečíst dokumenty</button>
	</ob-process-point>
	<ob-process-point number="4">
		<h2 class="text-uppercase montserat-black h4-responsive mb-2">PRVNÍ PRACOVNÍ DEN</h2>
		<p><b class="u-text--red">{{firstDateInWork | date:'d.&nbsp;MMMM&nbsp;y'}}</b> Vás čeká první pracovní den. Je to za <b class="u-text--red">{{daysToStart}} <span *ngIf="daysToStart == 1">den</span><span *ngIf="daysToStart > 1 && daysToStart < 5">dny</span><span *ngIf="daysToStart > 5">dnů</span></b>, Vaši kolegové už se na vás těší.</p>
		<button routerLink="/what-awaits" class="btn btn-outline-secondary">Co Vás čeká v prvních dnech</button>
	</ob-process-point>
</div>
