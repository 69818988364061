import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { ProcessPointModule } from '../../shared/process-point';

import { MedicalExaminationComponent } from './medical-examination.component';

@NgModule({
  imports: [
  	SharedModule,
    ProcessPointModule
  ],
  declarations: [
    MedicalExaminationComponent,
  ],
  exports: [
    MedicalExaminationComponent,
  ]
})
export class MedicalExaminationModule { }
